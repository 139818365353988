import * as utils from "utils/utils";
import AccountAlert from "components/alerts/AccountAlert";
import CloseModalButton from "components/CloseModalButton";
import { Button, InfoPopover, Loader, NavItem, roleUtils } from "uafrica-ui-framework";
import { IAccountAlert } from "interfaces/accountAlert.interface";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useStore } from "uafrica-ui-framework";
import { IResult } from "interfaces/result.interface";
import { IStore } from "interfaces/store.interface";

function AlertNavItem(props: {
  item: any;
  isNavbarCollapsed: boolean;
  unreadAlertsCount?: number;
}) {
  let { item, isNavbarCollapsed } = props;
  const history: any = useHistory();
  const store: IStore = useStore();

  let [showPopover, setShowPopover] = useState<boolean>(false);
  let [unreadAlerts, setunreadAlerts] = useState<IAccountAlert[]>([]);

  let [unreadCount, setUnreadCount] = useState<number>(0);
  let [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    setunreadAlerts([]);
    setUnreadCount(props.unreadAlertsCount ?? 0);
  }, [props.unreadAlertsCount]);

  async function getAlerts() {
    setIsLoading(true);
    let activeUser = utils.activeUser({ store });
    if (activeUser?.account_id && roleUtils.hasPermission(activeUser, "API_/accounts/alerts:GET")) {
      let result: IResult = await utils.signedRequest(store, "/accounts/alerts", "GET", {
        read: false
      });

      if (result.ok) {
        if (result.data.account_alerts) {
          setunreadAlerts(result.data.account_alerts ?? []);
          setIsLoading(false);
        }
      }
    }
  }

  function renderPopoverContent() {
    return (
      <div>
        <div className="text-lg flex justify-between items-center font-bold mb-4">
          {unreadAlerts && (
            <div>
              {unreadCount} unread alert{unreadCount === 1 ? "" : "s"}
            </div>
          )}
          <div>
            <CloseModalButton
              id="close"
              onClick={() => {
                setShowPopover(false);
              }}
            />
          </div>
        </div>
        <div>{isLoading && <Loader.Inline />}</div>
        <div className="overflow-auto" style={{ maxHeight: "300px" }}>
          {unreadAlerts &&
            unreadAlerts.map((alert: IAccountAlert) => {
              return <AccountAlert alert={alert} key={alert.id} />;
            })}
        </div>
        <div className="text-center mb-4 mt-8">
          <Button.Secondary
            title="View all alerts"
            onClick={() => {
              history.push("/alerts");
            }}
          />
        </div>
      </div>
    );
  }

  return (
    <div
      className="cursor-pointer"
      onClick={() => {
        if (unreadCount > 0) {
          if (!showPopover) {
            getAlerts();
          }
          setShowPopover(!showPopover);
        } else {
          history.push("/alerts");
        }
      }}
    >
      {item.hasAccess && item.displayCondition && (
        <InfoPopover
          width="w-full md:w-2/3"
          showPopover={showPopover}
          popoverContent={renderPopoverContent()}
          onPopoverDismiss={() => {
            setShowPopover(false);
          }}
        >
          {unreadAlerts && unreadCount > 0 && (
            <div className="relative">
              <div
                className={`absolute top-175 left-4 ${unreadCount >= 10 ? " -ml-075 " : " ml-0 "}`}
              >
                <div
                  className={`flex items-center bg-red text-white font-bold rounded-full justify-center h-3 ${
                    unreadCount >= 100 ? " w-5 " : unreadCount >= 10 ? " w-45 " : " w-3 "
                  }`}
                  style={{ fontSize: "5pt" }}
                >
                  {unreadCount < 100 ? unreadCount : "99+"}
                </div>
              </div>
            </div>
          )}
          <div className={"whitespace-pre-wrap"}>
            <NavItem item={item} isNavbarCollapsed={isNavbarCollapsed} />
          </div>
        </InfoPopover>
      )}
    </div>
  );
}

export default AlertNavItem;
