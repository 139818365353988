import React from "react";
import ScrollToTop from "components/ScrollToTop";
import "react-table-6/react-table.css";
import * as ReactDOMClient from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "App";
import Amplify from "@aws-amplify/core";
import config from "config";
import "uafrica-ui-framework/build/index.css";
import { CompatRouter } from "react-router-dom-v5-compat";

if ("serviceWorker" in navigator) {
  navigator.serviceWorker.ready.then(registration => {
    registration.unregister();
  });
}
require("styles/index.scss");

// eslint-disable-next-line no-extend-native
String.prototype.replaceAll = function (search, replacement) {
  var target = this;
  return target.replace(new RegExp(search, "g"), replacement);
};

// eslint-disable-next-line no-extend-native
String.prototype.contains = function contains(needle) {
  return this.indexOf(needle) >= 0;
};

Amplify.configure({
  Auth: {
    mandatorySignIn: false,
    region: config.cognito.REGION,
    userPoolId: config.cognito.USER_POOL_ID,
    identityPoolId: config.cognito.IDENTITY_POOL_ID,
    userPoolWebClientId: config.cognito.APP_CLIENT_ID
  }
});

const container = document.getElementById("uafrica-frontend");

// Create a root.
const root = ReactDOMClient.createRoot(container);

// Initial render: Render an element to the root.
root.render(
  <BrowserRouter>
    <CompatRouter>
      <ScrollToTop />
      <App />
    </CompatRouter>
  </BrowserRouter>
);
