import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IAnnouncement } from "interfaces/announcement.interface";
import { useState } from "react";
import { Card } from "uafrica-ui-framework";

function Announcement(props: { announcement: IAnnouncement; placement: "dashboard" | "header" }) {
  let [showMore, setShowMore] = useState<boolean>(false);
  let { announcement, placement } = props;

  function renderDashboardAnnouncement() {
    return (
      <Card className={announcement.type === "urgent" ? "border-danger-2 text-danger" : ""}>
        <div className="mb-4 text-center">
          <div className="uppercase font-bold">{announcement.heading}</div>
          {announcement.subheading && <div className="font-bold">{announcement.subheading}</div>}
        </div>
        <div dangerouslySetInnerHTML={{ __html: announcement.content }}></div>
        {announcement.image_path && (
          <div className={"flex flex-row items-center justify-center"}>
            <img
              className={"announcement_image"}
              src={announcement.image_path}
              alt={"announcement image"}
            />
          </div>
        )}
      </Card>
    );
  }

  function renderHeaderAnnouncement() {
    return (
      <div
        className={
          " p-4 items-center  sticky top-0  -mt-4 mb-4 -mx-4 z-40 " +
          (announcement.type === "urgent" ? "bg-red-100 text-red" : "bg-green-100 text-green")
        }
      >
        <div className="flex flex-row justify-between">
          <div className="uppercase font-bold">
            <FontAwesomeIcon className="mr-5" icon={"microphone"} />
            {announcement.heading}
          </div>
          <div
            className="cursor-pointer hover:underline"
            onClick={() => {
              setShowMore(!showMore);
            }}
          >
            Show {showMore ? "less" : "more"}
          </div>
        </div>
        {showMore && (
          <div className="mt-4">
            {announcement.subheading && <div className="font-bold">{announcement.subheading}</div>}
            <div dangerouslySetInnerHTML={{ __html: announcement.content }}></div>
          </div>
        )}
      </div>
    );
  }

  if (announcement.placements.indexOf(placement) >= 0) {
    if (placement === "dashboard") {
      return renderDashboardAnnouncement();
    } else if (placement === "header") {
      return renderHeaderAnnouncement();
    }
  }
  return null;
}

export default Announcement;
