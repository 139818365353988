import {
  Modal,
  Card,
  SectionHeading,
  Button,
  Radio,
  Loader,
  numberUtils,
  generalUtils,
  withError,
  Input
} from "uafrica-ui-framework";
import * as utils from "utils/utils";
import { IResult } from "interfaces/result.interface";
import { IStore } from "interfaces/store.interface";
import { useStore } from "uafrica-ui-framework";
import { useEffect, useState, useRef } from "react";
import { IBillingInformation } from "interfaces/billingInformation.interface";
import { useForm } from "react-hook-form";
import PaymentStatusModal from "pages/paymentConfirmation/PaymentStatusModal";
import RenderCreditCardDetails from "components/shared/CreditCard";
import { useInterval } from "hooks/useInterval";
import { IAccount } from "interfaces/account.interface";
import ManageSubscription from "components/shared/ManageSubscription";

// Either paymentItems, makeAccountCall, or isTopUp is required
type ConditionalProps =
  | {
      onHide: Function;
      paymentItems: {
        is_upgrade?: boolean;
        description?: string;
        amount?: number;
        plan_id?: number;
        plan_cost?: number;
        new_plan?: {
          name: string;
        };
      };
      planUpgrade?: any;
      title?: "Make payment";
      onComplete?: Function;
      displayPaymentStatus: boolean;
      accountId?: number;
      makeAccountCall?: boolean;
      fromManageSubscription?: boolean;
      isTopUp?: boolean;
    }
  | {
      onHide: Function;
      paymentItems?: {
        is_upgrade?: boolean;
        description?: string;
        amount?: number;
        plan_id?: number;
        plan_cost?: number;
        new_plan?: {
          name: string;
        };
      };
      planUpgrade?: any;
      title?: string;
      onComplete?: Function;
      displayPaymentStatus: boolean;
      accountId?: number;
      makeAccountCall: boolean;
      fromManageSubscription?: boolean;
      isTopUp?: boolean;
    }
  | {
      onHide: Function;
      paymentItems?: {
        is_upgrade?: boolean;
        description?: string;
        amount?: number;
        plan_id?: number;
        plan_cost?: number;
        new_plan?: {
          name?: string;
        };
      };
      planUpgrade?: any;
      title?: "Top up account";
      onComplete?: Function;
      displayPaymentStatus: boolean;
      accountId?: number;
      makeAccountCall?: boolean;
      fromManageSubscription?: boolean;
      isTopUp: boolean;
    };

function MakePaymentsModal({
  onHide,
  paymentItems,
  planUpgrade,
  title,
  onComplete,
  displayPaymentStatus,
  accountId,
  makeAccountCall,
  fromManageSubscription,
  isTopUp
}: ConditionalProps) {
  const store: IStore = useStore();
  const { handleSubmit, register, errors, control } = useForm();
  const [paymentMethods, setPaymentMethods] = useState<any[]>([]);
  const [paymentStatusModal, setPaymentStatusModal] = useState<any>();
  const [paymentStatus, setPaymentStatus] = useState<string | null>(null);
  const [paymentId, setPaymentId] = useState<number>();
  const [allPaymentMethods, setAllPaymentMethods] = useState<any[]>([]);
  const [retryCount, setRetryCount] = useState<number>(0);
  const [billingInfo, setBillingInfo] = useState<IBillingInformation>();
  const [isLoading, setIsLoading] = useState<{ paymentProviders: boolean }>({
    paymentProviders: true
  });
  const [makingPayment, setMakingPayment] = useState<boolean>(false);
  const [error, setError] = useState<{
    paymentProviders: any;
    billingError: any;
    paymentError: any;
    accountError: any;
  }>({
    paymentProviders: null,
    billingError: null,
    paymentError: null,
    accountError: null
  });
  const [selectedMakePaymentType, setSelectedMakePaymentType] = useState<{
    admin_only: boolean;
    display_name: string;
    id: number;
    status: string;
    type: string;
  }>();
  const [payableObject, setPayableObject] = useState<{
    payableItems: {
      description: string;
      amount: number;
      name: string;
      plan_cost?: number | null;
    }[];
  }>({ payableItems: [] });
  const [paymentObject, setPaymentObject] = useState<any>();

  const [payfastArray, setPayfastArray] = useState<any[]>([]);
  const [payfastAction, setPayfastAction] = useState<any>(null);
  const payfastRef: any = useRef(null);

  const [selectedAmountOption, setSelectedAmountOption] = useState<{
    label: string;
    value: number | undefined;
  }>({
    label: "R 200",
    value: 200
  });

  // Manage subscription
  const [showAccountPlans, setShowAccountPlans] = useState<boolean>(false);
  const [proRataSubscription, setProRataSubscription] = useState<{
    description: string;
    amount: number;
    name: string;
    plan_id?: number;
    plan_cost?: number;
  }>();

  let activeUser = utils.activeUser({ store });

  /* --------------------------------*/
  /* EFFECTS */
  /* --------------------------------*/

  useEffect(() => {
    if (!makeAccountCall && !fromManageSubscription) {
      if (
        store.account &&
        store.account.hasOwnProperty("has_valid_subscription") &&
        !store.account.has_valid_subscription
      ) {
        setShowAccountPlans(true);
      }
    }
  }, []);

  useEffect(() => {
    const initialiseData = async () => {
      utils
        .getAccount(store, undefined, true, true, true, false, true)
        .then(({ account, error }: { account: IAccount; error: any }) => {
          if (error) {
            setError({ ...error, accountError: error });
          } else {
            let reaching_credit_limit: boolean = false;
            let credit_limit_reached: boolean = false;

            if (account) {
              let absCL: number = Math.abs(account.credit_limit);
              let absBal: number = Math.abs(account.balance);
              if (account.balance < 0 && absBal / absCL > 0.8) {
                reaching_credit_limit = true;
              }
              if (absBal > absCL) {
                credit_limit_reached = true;
              }
              account["reaching_credit_limit"] = reaching_credit_limit;
              account["credit_limit_reached"] = credit_limit_reached;
              if (account.balance < 0) {
                account["remaining_balance"] = account.balance + account.credit_limit;
              } else {
                account["remaining_balance"] = Math.abs(account.balance - account.credit_limit);
              }
            }
            store.set("account", account);
            if (
              account &&
              account.hasOwnProperty("has_valid_subscription") &&
              !account.has_valid_subscription
            ) {
              setShowAccountPlans(true);
            }
            if (account.balances) {
              let payableArray: any[] = [];
              Object.keys(account.balances).forEach((key: string) => {
                let obj: {
                  description: string;
                  amount: number;
                  name: string;
                } = {
                  description: "",
                  amount: 0,
                  name: ""
                };

                if (
                  key === "overdue_invoices_amount" &&
                  account.balances &&
                  account.balances[key]
                ) {
                  if (
                    account.balances.overdue_invoices_count &&
                    account.balances.overdue_invoices_count > 1
                  ) {
                    obj = {
                      name: "Overdue invoices balance",
                      amount: account.balances[key] ?? 0,
                      description: `Pay for all overdue invoices`
                    };
                  } else {
                    obj = {
                      name: "Overdue invoice balance",
                      amount: account.balances[key] ?? 0,
                      description: `Pay invoice: ${account.balances.overdue_invoice_number ?? ""}`
                    };
                  }
                } else if (
                  key === "settle_account_amount" &&
                  account.balances &&
                  account.balances[key]
                ) {
                  obj = {
                    name: "Settle account",
                    amount: account.balances[key] ?? 0,
                    description: `Pay outstanding balance`
                  };
                  setPaymentObject(obj);
                } else {
                  return;
                }

                payableArray = payableArray.concat(obj);
              });
              setPayableObject({ payableItems: payableArray });
            }
            getPaymentProviders();
          }
        });
    };
    if (makeAccountCall) {
      initialiseData();
    } else if (isTopUp) {
      let desc: string = "";
      let amt: number = selectedAmountOption.value ?? 200;
      let name: string = "Account topup";

      setPayableObject({
        payableItems: [{ description: desc, amount: amt, name: name }]
      });

      setPaymentObject({ description: desc, amount: amt, name: name });

      getPaymentProviders();
    } else {
      let desc: string = "";
      let amt: number = 0;
      let name: string = "";
      let plan_cost: number | null = null;

      if (paymentItems?.description) {
        desc = paymentItems.description;
      }
      if (paymentItems?.amount) {
        amt = paymentItems.amount;
      }
      if (paymentItems?.new_plan && paymentItems.new_plan.name) {
        name = paymentItems.new_plan.name;
      }

      if (paymentItems?.plan_cost) {
        plan_cost = paymentItems.plan_cost;
        setPayableObject({
          payableItems: [{ description: desc, amount: amt, name: name, plan_cost: plan_cost }]
        });
      } else {
        setPayableObject({
          payableItems: [{ description: desc, amount: amt, name: name }]
        });
      }

      setPaymentObject({ description: desc, amount: amt, name: name });

      getPaymentProviders();
    }
  }, [makeAccountCall]);

  useEffect(() => {
    if (payableObject.payableItems.length === 1) {
      setPaymentObject(payableObject.payableItems[0]);
    }
  }, [payableObject.payableItems]);

  useEffect(() => {
    if (payfastRef.current) {
      payfastRef.current.click();
    }
  }, [payfastAction]);

  useInterval(
    () => {
      pollPayment(paymentId);
    },
    // give it like 10 tries. Will increase if not enough
    paymentStatus && paymentStatus === "pending" && !error.paymentError && retryCount < 11
      ? 1000
      : null
  );

  /* --------------------------------*/
  /* FUNCTIONS */
  /* --------------------------------*/

  const getPaymentProviders = async () => {
    setIsLoading({ ...isLoading, paymentProviders: true });
    let { billingInfo, billingError } = await utils.getBillingInfo(store, accountId);
    if (billingError) {
      setError({ ...error, billingError: billingError });
    } else {
      setBillingInfo(billingInfo);
    }
    let clientOnlyPaymentProviders: any[] = [];
    let allPaymentMethods: any = [];
    let res: IResult = await utils.signedRequest(store, "/payment-providers", "GET");
    if (res.ok && res.data) {
      clientOnlyPaymentProviders = res.data.payment_providers
        .filter(
          (provider: {
            admin_only: boolean;
            display_name: string;
            id: number;
            status: string;
            type: string;
            instant_pay: boolean;
          }) => !provider.admin_only
        )
        .filter(
          (provider: {
            admin_only: boolean;
            display_name: string;
            id: number;
            status: string;
            type: string;
            instant_pay: boolean;
          }) => provider.instant_pay
        );
      allPaymentMethods = res.data.payment_providers.filter(
        (provider: any) => provider.status === "active"
      );
      if (
        billingInfo &&
        billingInfo.payment_method_id &&
        allPaymentMethods.find((method: any) => method.id === billingInfo?.payment_method_id)
          ?.instant_pay
      ) {
        let array: any[] = [];
        let newPaymentMethod: {
          admin_only: boolean;
          display_name: string;
          id: number;
          status: string;
          type: string;
          instant_pay: boolean;
        } = {
          admin_only: false,
          display_name: "Saved payment method",
          id: 0,
          status: "active",
          type: "saved",
          instant_pay: false
        };

        array = [newPaymentMethod, ...clientOnlyPaymentProviders];
        setPaymentMethods(array);
        setAllPaymentMethods(allPaymentMethods);
        setSelectedMakePaymentType(array[0]);
      } else {
        setPaymentMethods(clientOnlyPaymentProviders);
        setSelectedMakePaymentType(clientOnlyPaymentProviders[0]);
      }
    }
    if (!res.ok) {
      setError({ ...error, paymentProviders: generalUtils.getError(res, true) });
    }
    setIsLoading({ ...isLoading, paymentProviders: false });
  };

  const onSave = async (data: any) => {
    if (
      store.account &&
      store.account.hasOwnProperty("has_valid_subscription") &&
      !store.account.has_valid_subscription &&
      !proRataSubscription &&
      makeAccountCall
    ) {
      setShowAccountPlans(true);
      return;
    }

    setMakingPayment(true);
    setError({ ...error, paymentError: null });
    if (planUpgrade) {
      if (selectedMakePaymentType?.type === "saved") {
        data = {
          payment_provider_id: billingInfo?.payment_method_id,
          amount: parseFloat(paymentObject?.amount.toFixed(2)),
          description: paymentObject?.description,
          plan_id: paymentItems?.plan_id,
          credit_card_details: null
        };
      } else {
        data = {
          payment_provider_id: selectedMakePaymentType?.id,
          amount: parseFloat(paymentObject?.amount.toFixed(2)),
          description: paymentObject?.description,
          plan_id: paymentItems?.plan_id,
          ...data
        };
      }
      if (paymentObject.plan_cost) {
        data["plan_cost"] = parseFloat(paymentObject.plan_cost.toFixed(2));
      }
    } else if (isTopUp) {
      data = {
        ...data,
        payment_provider_id:
          selectedMakePaymentType?.id === 0
            ? billingInfo?.payment_method_id
            : selectedMakePaymentType?.id,
        amount: parseFloat(paymentObject?.amount.toFixed(2)),
        description: "Account topup",
        is_account_topup: true
      };
    } else {
      data = {
        payment_provider_id:
          selectedMakePaymentType?.id === 0
            ? billingInfo?.payment_method_id
            : selectedMakePaymentType?.id,
        amount: proRataSubscription
          ? parseFloat((proRataSubscription.amount + paymentObject?.amount).toFixed(2))
          : parseFloat(paymentObject?.amount.toFixed(2)),
        description: paymentObject?.description,
        ...data
      };

      // Will only add if there are pro-rata payment
      if (proRataSubscription) {
        data = {
          ...data,
          plan_cost: proRataSubscription.plan_cost,
          plan_id: proRataSubscription.plan_id,
          description: "Pay outstanding balance and subscription"
        };
      }
    }

    if (accountId) {
      data.account_id = accountId;
    }

    try {
      let result: IResult = await utils.signedRequest(store, "/payments", "POST", data);
      if (result.ok) {
        if (result.data.payment_provider.type === "payfast") {
          getPayfastPaymentInfo(result.data.id);
          return;
        }
        if (!displayPaymentStatus) {
          onComplete && onComplete();
        }
        if (displayPaymentStatus) {
          if (result.data.status === "pending") {
            setPaymentId(result.data.id);
            setPaymentStatus(result.data.status);
          } else {
            setPaymentStatusModal(result.data);
            setMakingPayment(false);
          }
        }
      }
      if (!result.ok) {
        setError({ ...error, paymentError: generalUtils.getError(result, true) });
        setMakingPayment(false);
      }
    } catch (e) {
      setError({ ...error, paymentError: generalUtils.getError(e, true) });
      setMakingPayment(false);
    }
  };

  const pollPayment = async (id?: number) => {
    if (retryCount === 10) {
      setMakingPayment(false);
    }
    try {
      const res: IResult = await utils.signedRequest(store, "/payments", "GET", { id: id });

      if (res.ok) {
        setPaymentStatus(res.data.payments[0].status);
        if (retryCount === 10) {
          setMakingPayment(false);
          setPaymentStatusModal(res.data.payments[0]);
        }
        if (res.data.payments[0].status === "success" || res.data.payments[0].status === "failed") {
          setMakingPayment(false);
          setPaymentStatusModal(res.data.payments[0]);
        }
      }

      if (!res.ok) {
        setError({ ...error, paymentError: generalUtils.getError(res, true) });
        setMakingPayment(false);
      }
    } catch (e) {
      setError({ ...error, paymentError: generalUtils.getError(e, true) });
      setMakingPayment(false);
    }
    setRetryCount(retryCount + 1);
  };

  const getPayfastPaymentInfo = async (id: number) => {
    try {
      let res: IResult = await utils.signedRequest(store, "/payment-providers/payfast", "GET", {
        payment_id: id
      });

      if (res.ok) {
        let keys: string[] = Object.keys(res.data.form_data);
        let action: string = res.data.action;
        let arr: any[] = keys.map(item => ({
          name: item,
          value: res.data.form_data[item]
        }));
        setPayfastArray(arr);
        setPayfastAction(action);
      }
      if (!res.ok) {
        setError({ ...error, paymentError: generalUtils.getError(res, true) });
      }
    } catch (e) {
      setError({ ...error, paymentError: generalUtils.getError(e, true) });
    }
  };

  /* --------------------------------*/
  /* RENDER METHODS */
  /* --------------------------------*/
  const renderPayfastForm = (arr: { value: any; name: any }[], action: string) => {
    return (
      <form action={action} method={"post"}>
        {arr.map((item, i: number) => (
          <input key={i} type={"hidden"} name={item.name} value={item.value} />
        ))}
        <input type={"submit"} ref={payfastRef} style={{ display: "none" }} />
      </form>
    );
  };

  const renderEFTDetails = () => {
    return (
      <div className={"mt-6"}>This is the EFT method. We do not know what to display here yet</div>
    );
  };

  const renderSavedMethod = (billingInfo: IBillingInformation) => {
    if (
      !allPaymentMethods.find(method => method.id === billingInfo.payment_method_id).instant_pay
    ) {
      return null;
    }
    return (
      <div className={"flex items-center flex-col mt-6"}>
        <strong>
          Saved payment method:{" "}
          {
            allPaymentMethods.find(method => method.id === billingInfo.payment_method_id)
              ?.display_name
          }
        </strong>
        {allPaymentMethods.find(method => method.id === billingInfo.payment_method_id)?.type ===
          "credit-card" && (
          <div className={"flex gap-2"}>
            <strong>{billingInfo.card_association ?? ""}</strong>
            <span>ending with</span>
            <strong>
              {billingInfo.card_pan?.length > 4
                ? billingInfo.card_pan.slice(-4)
                : billingInfo.card_pan ?? ""}
            </strong>
          </div>
        )}
      </div>
    );
  };

  const renderCreditCardDetails = (paymentMethod: any) => {
    return (
      <RenderCreditCardDetails
        paymentMethod={paymentMethod}
        register={register}
        errors={errors}
        control={control}
        setAsDefault={true}
      />
    );
  };

  const renderTopup = () => {
    const topupOptions = [
      { label: "R 200", value: 200 },
      { label: "R 500", value: 500 },
      { label: "R 1 000", value: 1000 },
      { label: "R 5 000", value: 5000 },
      { label: "Own amount", value: undefined }
    ];

    if (!paymentMethods.some(method => method.instant_pay === true)) {
      return null;
    }

    return (
      <Card className="space-y-4">
        <SectionHeading>Topup amount</SectionHeading>
        <div>
          <div className="inline-flex space-x-4">
            {topupOptions.map((topupOption: any, i: number) => {
              return (
                <div
                  data-test={utils.ifDev(`Make-payments-modal__top-up-amount-${i + 1}`)}
                  onClick={() => {
                    setSelectedAmountOption(topupOption);
                    setPayableObject({
                      payableItems: [
                        {
                          ...payableObject.payableItems[0],
                          amount: !isNaN(parseFloat(topupOption.value)) ? topupOption.value : 0
                        }
                      ]
                    });
                  }}
                  className={`border rounded-full w-auto border-primary font-bold px-4 py-2 cursor-pointer  ${
                    selectedAmountOption.label === topupOption.label
                      ? " bg-primary text-white "
                      : " text-primary hover:bg-primary-100"
                  }`}
                  key={i}
                >
                  {topupOption.label}
                </div>
              );
            })}
          </div>
          {selectedAmountOption.label === "Own amount" && (
            <div>
              <Input
                dataTest={utils.ifDev("Make-payments-modal__top-up-amount-input")}
                prependText="R"
                containerClassName={"mt-6 w-14"}
                type="number"
                placeholder="0.00"
                step={0.01}
                min={0}
                disableNumericInputScroll
                noArrows
                label="Topup amount"
                value={selectedAmountOption.value}
                onChange={(e: any) => {
                  setSelectedAmountOption({
                    ...selectedAmountOption,
                    value: !isNaN(parseFloat(e.target.value))
                      ? parseFloat(e.target.value)
                      : undefined
                  });
                  setPayableObject({
                    payableItems: [
                      {
                        ...payableObject.payableItems[0],
                        amount: !isNaN(parseFloat(e.target.value)) ? parseFloat(e.target.value) : 0
                      }
                    ]
                  });
                }}
              />
            </div>
          )}
        </div>
      </Card>
    );
  };

  const render = () => {
    return (
      <>
        {!paymentStatusModal ? (
          <Modal.Large
            closeButton={true}
            disableClickOutsideToClose={makingPayment}
            disablePressEscToClose={makingPayment}
            show={true}
            onHide={() => {
              if (makingPayment) return;
              else onHide();
            }}
            title={title ?? "Make payment"}
          >
            {error && error.paymentError && generalUtils.showError(error.paymentError)}
            {error && error.billingError && generalUtils.showError(error.billingError)}
            {error && error.paymentProviders && generalUtils.showError(error.paymentProviders)}
            {Object.values(isLoading).some(item => item) ? (
              <Loader.Modal />
            ) : (
              <>
                <div
                  className={"flex flex-col justify-between gap-5 md:flex-row"}
                  id={utils.ifDev("Make-payments-modal__complete-modal")}
                >
                  <div className={"w-full lg:w-8/12 md:w-2/3"}>
                    {isTopUp && renderTopup()}
                    <Card className="space-y-4">
                      <SectionHeading>How would you like to pay?</SectionHeading>
                      {paymentMethods.map(
                        (method: {
                          admin_only: boolean;
                          display_name: string;
                          id: number;
                          status: string;
                          type: string;
                        }) => (
                          <div
                            key={method.id}
                            className={"border p-4 cursor-pointer rounded"}
                            onClick={() => {
                              setSelectedMakePaymentType(method);
                            }}
                          >
                            <div
                              className={"flex justify-between"}
                              id={utils.ifDev(
                                `Make-payments-modal__${method.display_name}`.replace(/ /g, "-")
                              )}
                            >
                              <Radio.Button
                                label={method.display_name}
                                name={"make_payment_type"}
                                checked={selectedMakePaymentType?.id === method.id}
                                labelRight={true}
                                labelClassName="font-bold"
                                onChange={() => {}}
                              />
                              {method.type === "payfast" && (
                                <img
                                  src={process.env.PUBLIC_URL + "/payment/payfast-logo.png"}
                                  alt="Payfast"
                                  style={{ height: "21px" }}
                                />
                              )}
                            </div>{" "}
                            {selectedMakePaymentType?.id === method.id &&
                              selectedMakePaymentType?.type === "credit-card" &&
                              renderCreditCardDetails(selectedMakePaymentType)}
                            {selectedMakePaymentType?.id === method.id &&
                              selectedMakePaymentType?.type === "manual" &&
                              renderEFTDetails()}
                            {selectedMakePaymentType?.id === method.id &&
                              selectedMakePaymentType?.type === "saved" &&
                              billingInfo &&
                              renderSavedMethod(billingInfo)}
                          </div>
                        )
                      )}
                    </Card>
                  </div>
                  <Card className={"w-full lg:w-4/12 md:w-1/3"}>
                    <SectionHeading>Cart summary</SectionHeading>
                    <div className={"border-b border-gray-200"} />
                    {proRataSubscription && (
                      <div className={`mb-2 mt-4`}>
                        <div
                          className={`flex justify-between ${
                            payableObject.payableItems.length === 1 ? "" : "px-4"
                          }`}
                        >
                          <span className={"text-lg font-bold"}>{proRataSubscription.name}</span>
                          <span>
                            {numberUtils.formatNumberWithCurrency(
                              proRataSubscription.amount,
                              false,
                              true
                            )}{" "}
                          </span>
                        </div>

                        {proRataSubscription.description
                          ?.split("\n")
                          .map((itemDesc: string, index: number) => (
                            <p
                              className={`text-sm text-muted ${
                                payableObject.payableItems.length === 1 ? "" : "ml-4"
                              }`}
                              key={index}
                            >
                              {itemDesc}
                            </p>
                          ))}
                        <div className={"border-b border-gray-200 mt-4"} />
                      </div>
                    )}
                    {payableObject.payableItems.map((item, index) => {
                      if (payableObject.payableItems.length === 1) {
                        return (
                          <div className={"mb-2 mt-4"} key={index}>
                            <div className={"flex justify-between"}>
                              <span className={"text-lg font-bold"}>{item.name}</span>
                              <span>
                                {numberUtils.formatNumberWithCurrency(item.amount, false, true)}{" "}
                              </span>
                            </div>

                            {item.description
                              ?.split("\n")
                              .map((itemDesc: string, index: number) => (
                                <p className={"text-sm text-muted"} key={index}>
                                  {itemDesc}
                                </p>
                              ))}
                            <div className={"border-b border-gray-200 mt-4"} />
                          </div>
                        );
                      }
                      return (
                        <div
                          className={
                            "mb-2 mt-4 border flex justify-between p-4 cursor-pointer rounded"
                          }
                          onClick={() => setPaymentObject(item)}
                          key={index}
                        >
                          <div className={"flex flex-col"}>
                            <div className={"flex flex-row gap-2 items-center"}>
                              <Radio.Button
                                label={""}
                                name={"payment_option"}
                                checked={paymentObject?.name === item.name}
                                onChange={() => {}}
                              />
                              <span className={"text-lg font-bold"}>{item.name}</span>
                            </div>

                            {item.description
                              ?.split("\n")
                              .map((itemDesc: string, index: number) => (
                                <p className={"text-sm text-muted ml-8"} key={index}>
                                  {itemDesc}
                                </p>
                              ))}
                          </div>
                          <div>
                            <span>
                              {numberUtils.formatNumberWithCurrency(item.amount, false, true)}{" "}
                            </span>
                          </div>
                        </div>
                      );
                    })}

                    <div className={"mb-2 mt-4 flex justify-end gap-5"}>
                      <span className={"text-lg font-bold uppercase text-right"}>total</span>
                      <span
                        className={"text-lg font-bold"}
                        id={utils.ifDev("Make-payments-modal__total")}
                      >
                        {numberUtils.formatNumberWithCurrency(
                          proRataSubscription
                            ? paymentObject?.amount + proRataSubscription.amount
                            : paymentObject?.amount,
                          false,
                          true
                        )}
                      </span>
                    </div>
                    <Button.ButtonsPanel>
                      <Button.Primary
                        id="Make-payments-modal__make-payment"
                        onClick={handleSubmit(onSave)}
                        title="Make payment"
                        isLoading={makingPayment}
                        disabled={
                          makingPayment ||
                          allPaymentMethods.find(
                            method => method.id === billingInfo?.payment_method_id
                          )?.type === "manual" ||
                          paymentObject?.amount === 0 ||
                          activeUser?.status === "closed"
                        }
                        loadingTitle={"Making payment"}
                        className="w-full"
                      />
                    </Button.ButtonsPanel>
                  </Card>
                </div>
              </>
            )}
            {payfastArray.length > 0 && <>{renderPayfastForm(payfastArray, payfastAction)}</>}
          </Modal.Large>
        ) : (
          <PaymentStatusModal
            status={paymentStatusModal?.status}
            onHide={() => {
              onComplete && onComplete();
            }}
            error={paymentStatusModal?.error_message}
          />
        )}
        {showAccountPlans && (
          <ManageSubscription
            onComplete={(res: any) => {
              setProRataSubscription({
                description: res.description,
                name: res.new_plan?.name ?? store.account.plan.name,
                amount: res.amount,
                plan_id: res.plan_id,
                plan_cost: res.plan_cost
              });
              setShowAccountPlans(false);
            }}
            // close everything if no plan is selected and the user
            onHide={() => {
              setShowAccountPlans(false);
              onHide();
            }}
            title={"Renew subscription"}
            headerText={
              "In order to continue using our services, you need to renew your subscription. You may keep or change your current plan to any of those listed below."
            }
            // Add so that we do not have to create a new manage subscription component
            fromValidSubscriptionCheck={true}
          />
        )}
      </>
    );
  };

  return render();
}

export default withError(MakePaymentsModal);
