import { Card, PageHeading, SectionHeading } from "uafrica-ui-framework";
import "pages/auth/MaintenancePage.scss";

export default function MaintenancePage(props: any) {
  return (
    <div className="maintenance">
      <Card>
        <PageHeading center>
          {props.error ? props.error : "Hi there, We’re busy with some scheduled maintenance"}
        </PageHeading>
        <div className={"text-center my-4"}>
          Have an urgent query? Contact support at{" "}
          <span className={"text-primary"}>suppert@uafrica.com</span> or{" "}
          <span className={"font-bold"}>+27 012 940 1060</span>{" "}
        </div>
        <div className="flex justify-center">
          <img src="/maintenance.png" alt="Maintenance mode" />
        </div>
        <SectionHeading center>Operations will continue again shortly</SectionHeading>
      </Card>
    </div>
  );
}
