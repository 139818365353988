import "pages/auth/AccountClosedPage.scss";
import { useStore, withError } from "uafrica-ui-framework";
import { IStore } from "interfaces/store.interface";
import { useEffect } from "react";

function AccountClosedPage() {
  const store: IStore = useStore();

  useEffect(() => {
    localStorage.setItem("accountClosed", "true");
    store.emitter.emit("logOut");
  }, []);

  return <></>;
}

export default withError(AccountClosedPage);
