import Alert from "components/alerts/AccountAlert";
import { IAccountAlert } from "interfaces/accountAlert.interface";
import { IResult } from "interfaces/result.interface";
import { useCallback, useEffect, useState } from "react";
import { FiltersPanel, generalUtils, withError, withStore } from "uafrica-ui-framework";
import { Button, Card, Loader, PageHeading, Switch } from "uafrica-ui-framework";
import * as utils from "utils/utils";
import { IStore } from "interfaces/store.interface";

function AccountAlertsPage(props: { store: IStore }) {
  let { store } = props;
  let [alerts, setAlerts] = useState<IAccountAlert[]>([]);
  let [totalCount, setTotalCount] = useState<number>(0);
  let [isLoading, setIsLoading] = useState<boolean>(false);
  let [error, setError] = useState<any>();
  let [showUnreadOnly, setShowUnreadOnly] = useState<boolean>(false);

  useEffect(() => {
    fetchMoreData(0, showUnreadOnly);
  }, []);

  const fetchMoreData = useCallback(
    async (offset: number, unread?: boolean) => {
      setIsLoading(true);

      const params: any = {
        limit: 10,
        offset
      };

      let newDataArray = [];
      let alertsArray = [];
      let totalCount: number = 0;

      if (unread) {
        params.read = false;
      }

      try {
        const result: IResult = await utils.signedRequest(store, "/accounts/alerts", "GET", params);

        if (result.ok) {
          alertsArray = result.data.account_alerts;
          totalCount = result.data.count;

          if (offset === 0) {
            newDataArray = alertsArray;
          } else {
            newDataArray = alerts.concat(alertsArray);
          }
        }
        if (!result.ok) {
          setError(generalUtils.getError(result.error, true));
        }
      } catch (e) {
        setError(generalUtils.getError(e));
        return;
      } finally {
        setIsLoading(false);
      }

      setAlerts(newDataArray);
      setTotalCount(totalCount);
    },
    [store, alerts]
  );

  return (
    <div>
      <div className="flex justify-between">
        <PageHeading icon="bell">Alerts</PageHeading>
        <FiltersPanel>
          <Switch
            label="Only show unread"
            checked={showUnreadOnly}
            onChange={() => {
              setShowUnreadOnly(!showUnreadOnly);
              fetchMoreData(0, !showUnreadOnly);
            }}
          />
        </FiltersPanel>
      </div>

      <Card>
        {!isLoading && totalCount === 0 && (
          <div style={{ marginTop: "10px", textAlign: "center" }}>No alerts found.</div>
        )}

        {isLoading && <Loader.Inline />}
        {error && generalUtils.showError(error)}

        {alerts?.map((alert, index) => (
          <Alert
            alert={alert}
            key={index}
            setAlerts={(selectedAlert: IAccountAlert) => {
              if (showUnreadOnly) {
                const selectedAlertIndex = alerts.findIndex(alert => alert.id === selectedAlert.id);
                alerts.splice(selectedAlertIndex, 1);
              }
            }}
            showMarkAsRead
          />
        ))}

        {alerts?.length < totalCount && (
          <div className="mt-4 flex justify-center">
            <Button.Secondary
              title="Load more"
              onClick={() => {
                fetchMoreData(alerts.length, showUnreadOnly);
              }}
            />
          </div>
        )}
      </Card>
    </div>
  );
}

export default withStore(withError(AccountAlertsPage));
