import * as utils from "utils/utils";
import { IUser } from "interfaces/user.interface";

import { IStore } from "interfaces/store.interface";

function shouldDisplayOnboardingInfoPopover(
  store: IStore,
  displayItem:
    | "showOrdersOnboarding"
    | "showRatesAtCheckoutOnboarding"
    | "showDeclaredValueOnboarding"
) {
  const onboardingSettings = store.get("user_settings")?.onboarding;

  if (!onboardingSettings) {
    return false;
  }

  if (!store.account_settings) {
    return false;
  }

  if (!store.user_settings.do_not_show_onboarding_nav) {
    return false;
  }

  if (store.onboarding_entered) {
    return false;
  }

  let settingsKeys = Object.keys(onboardingSettings);

  let sortedArr = settingsKeys.sort();

  let sortedValues: any[] = [];

  sortedArr.forEach(item => {
    sortedValues = sortedValues.concat(onboardingSettings[item]);
  });

  let displayItemAvailable = sortedValues.find(item => item.closed === "false")?.setting;

  return displayItem === displayItemAvailable;
}

function removeDisplayOnboardingPopover(
  store: IStore,
  displayItem:
    | "showOrdersOnboarding"
    | "showRatesAtCheckoutOnboarding"
    | "showDeclaredValueOnboarding"
) {
  let onboardingSettings = store.get("user_settings")?.onboarding;

  if (!onboardingSettings) {
    return;
  }

  let itemToUpdate: any =
    Object.values(onboardingSettings)?.find((item: any) => item.setting === displayItem) ?? {};

  itemToUpdate["closed"] = "true";

  onboardingSettings[itemToUpdate.identifier] = itemToUpdate;

  utils.setUserSetting(store, "onboarding", JSON.stringify(onboardingSettings));
}

function showInfoPopover(store: any, settingName: string) {
  let activeUser: IUser | undefined = utils.activeUser({ store });
  let userId = activeUser?.id ? activeUser?.id : false;

  let userSettings = store.get("user_settings");
  let session_settings: any = sessionStorage.getItem("session_settings");
  session_settings = JSON.parse(session_settings);

  if (
    (session_settings &&
      userId &&
      session_settings[userId] &&
      session_settings[userId][settingName] === false) ||
    userSettings[settingName] === false
  ) {
    return false;
  }

  return true;
}

function doNotShowAgain(store: any, settingName: string) {
  let userSettings = store.get("user_settings");

  if (!userSettings) {
    userSettings = {};
  }
  userSettings[settingName] = false;
  store.set("user_settings", userSettings);
  utils.setUserSetting(store, settingName, JSON.stringify(false));
}

// @ts-ignore
function hideInfoPopover(store: any, settingName: string) {
  let activeUser = utils.activeUser({ store });
  let username = activeUser?.username ? activeUser?.username : false;

  let session_settings: any = sessionStorage.getItem("session_settings");
  session_settings = JSON.parse(session_settings);
  if (username) {
    if (!session_settings) {
      session_settings = {};
    }
    if (!session_settings[username]) {
      session_settings[username] = {};
    }
    session_settings[username][settingName] = false;
    sessionStorage.setItem("session_settings", JSON.stringify(session_settings));
    store.set("user_session_settings", JSON.stringify(session_settings));
  }
}

function renderNavDescriptionPopover(
  // @ts-ignore
  { placement, scheduleUpdate, arrowProps, outOfBoundaries, show, ...props },
  isNavbarCollapsed: boolean,
  item: INavItem
) {
  return isNavbarCollapsed ? (
    <div className="nav-descripion-overlay" {...props}>
      <strong style={{ paddingLeft: "10px", paddingRight: "10px" }}>{item.displayName}</strong>
    </div>
  ) : (
    <div {...props}></div>
  );
}

function checkIfUserHasAccessToNavSection(section: INavItem[]) {
  if (section) {
    let hasAccessToSection = false;
    section.map(item => {
      if (item.displayCondition && item.hasAccess) {
        hasAccessToSection = true;
      }
    });

    return hasAccessToSection;
  } else {
    return false;
  }
}

export {
  renderNavDescriptionPopover,
  checkIfUserHasAccessToNavSection,
  showInfoPopover,
  hideInfoPopover,
  doNotShowAgain,
  shouldDisplayOnboardingInfoPopover,
  removeDisplayOnboardingPopover
};

interface INavItem {
  displayCondition: boolean;
  displayName: string;
  hasAccess: boolean;
  icon: string;
  path: string;
}
