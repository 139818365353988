import { Modal, ModalActionsPanel, PageHeading } from "uafrica-ui-framework";
import { IStore } from "interfaces/store.interface";
import { useStore, roleUtils } from "uafrica-ui-framework";
import MakePaymentsModal from "components/accounts/MakePaymentsModal";
import * as utils from "utils/utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function AccountFrozen() {
  const store: IStore = useStore();

  if (!store || !store.account) {
    return null;
  }

  if (store && store.account?.plan_status !== "frozen") {
    return null;
  }

  if (
    store &&
    store.account?.plan_status === "frozen" &&
    store.account?.plan_frozen_reason === "plan-expired"
  ) {
    return null;
  }

  if (
    store.frozen_reason === "unpaid-invoice" &&
    store.frozen &&
    roleUtils.hasPermission(utils.activeUser({ store }), "API_/payments:POST") &&
    store.account.balance < 0
  ) {
    return (
      <>
        <div className={"banner-red"}>
          <div className={"flex items-center"}>
            <FontAwesomeIcon icon="exclamation-circle" className="mr-4" />
            Your account is frozen. Please pay your{" "}
            <div
              className={
                roleUtils.hasPermission(utils.activeUser({ store }), "API_/payments:POST")
                  ? "font-bold underline"
                  : ""
              }
              onClick={() => {
                if (roleUtils.hasPermission(utils.activeUser({ store }), "API_/payments:POST")) {
                  store.set("frozen_reason", "unpaid-invoice");
                }
              }}
            >
              outstanding balance
            </div>
          </div>
        </div>
        <MakePaymentsModal
          onHide={() => {
            store.set("frozen", false);
            store.set("frozen_reason", null);
          }}
          makeAccountCall
          title={"Make payment"}
          displayPaymentStatus
          onComplete={async () => {
            let { account } = await utils.getAccount(store, undefined, true, true, true, false);
            store.set("account", account);
            store.set("frozen", false);
            store.set("frozen_reason", null);
          }}
        />
      </>
    );
  }
  if (store.frozen) {
    return (
      <>
        <div className={"banner-red"}>
          <div>
            <FontAwesomeIcon icon="exclamation-circle" className="mr-4" />
            Your account is frozen. Please contact uAfrica support for assistance at +27 12 940 1060
            or <a href={"mailto:" + "support@uafrica.com"}>support@uafrica.com</a>
          </div>
        </div>
        <Modal.Medium show={true} closeButton={false}>
          <ModalActionsPanel>
            <FontAwesomeIcon
              className={"cursor-pointer"}
              icon="times"
              id="create_order_modal"
              onClick={() => store.remove("frozen")}
            />
          </ModalActionsPanel>
          <PageHeading center={true}>your account has been frozen</PageHeading>
          <div className={"text-center my-8"}>
            Contact support at{" "}
            <a className={"link font-bold"} href={"mailto:" + "support@uafrica.com"}>
              support@uafrica.com
            </a>{" "}
            or phone <strong>(+27) 12 940 1060</strong>
          </div>

          <div className={"flex justify-center my-8"}>
            <img
              src={process.env.PUBLIC_URL + "/assets/account-inactive.svg"}
              alt={"Account inactive"}
            />
          </div>
        </Modal.Medium>
      </>
    );
  }
  return (
    <div className={"banner-red"}>
      <div>
        {store.account.plan_frozen_reason === "unpaid-invoice" &&
        store.account.balance < 0 &&
        roleUtils.hasPermission(utils.activeUser({ store }), "API_/payments:POST") ? (
          <div className={"flex items-center"}>
            <FontAwesomeIcon icon="exclamation-circle" className="mr-4" />
            Your account is frozen. Please pay your{" "}
            <div
              className={
                roleUtils.hasPermission(utils.activeUser({ store }), "API_/payments:POST")
                  ? "font-bold text-black underline ml-2 cursor-pointer"
                  : "ml-2"
              }
              onClick={() => {
                if (roleUtils.hasPermission(utils.activeUser({ store }), "API_/payments:POST")) {
                  store.set("frozen", true);
                  store.set("frozen_reason", "unpaid-invoice");
                }
              }}
            >
              outstanding balance
            </div>
          </div>
        ) : (
          <>
            <FontAwesomeIcon icon="exclamation-circle" className="mr-4" />
            Your account is frozen. Please contact uAfrica support for assistance at +27 12 940 1060
            or <a href={"mailto:" + "support@uafrica.com"}>support@uafrica.com</a>
          </>
        )}
      </div>
    </div>
  );
}

export default AccountFrozen;
