import { generalUtils, useStore, withError } from "uafrica-ui-framework";
import { IStore } from "interfaces/store.interface";

interface IProps {
  displayMinorTerms: boolean;
  systemConfig?: any;
}

function TermsComponent({ displayMinorTerms, systemConfig }: IProps) {
  const store: IStore = useStore();
  const urlNavigate = systemConfig?.terms_url.value;

  if (displayMinorTerms) {
    return (
      <div className={"paragraph-terms"}>
        <span>
          uAfrica is now trading as Bob Group (Pty) Ltd with registration number: 2000/010005/07 and
          VAT number: 4490188267.
        </span>
        <br />
        <br />
        <span>Please accept our updated</span>
        <span
          className={"link w-fit-content"}
          onClick={() => {
            const win = window.open(systemConfig?.terms_url.value, "_blank");
            win && win.focus();
          }}
        >
          terms and conditions
        </span>
        <span className={"-mt-5"}>in order to continue using our services.</span>
      </div>
    );
  }
  return (
    <>
      <div className={"paragraph-terms"}>
        <span>Please read and agree to the</span>
        <span
          className={"link w-fit-content"}
          onClick={() => generalUtils.openInNewTab(urlNavigate, store)}
        >
          terms and conditions
        </span>
        <span> in order to use the services.</span>
      </div>
    </>
  );
}

export default withError(TermsComponent);
