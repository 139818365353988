import aws4 from "aws4";
import moment from "moment";
import config from "config";
import * as localRoleUtils from "utils/roleUtils";
import _ from "lodash";
import { Auth } from "@aws-amplify/auth";
import { Link } from "react-router-dom";
import { IUser } from "interfaces/user.interface";
import { IShipment } from "interfaces/shipment.interface";
import { IObjectKeys } from "interfaces/object.interface";
import { IResult } from "interfaces/result.interface";
import { IAddress } from "interfaces/address.interface";
import { IAccount } from "interfaces/account.interface";
import { IParcel } from "interfaces/parcel.interface";
import { ReactElement } from "react";
import { addressUtils, generalUtils, InfoButton, roleUtils } from "uafrica-ui-framework";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IStore } from "interfaces/store.interface";
import { IOrder } from "interfaces/order.interface";
import { IProvider } from "interfaces/provider.interface";
import { IServiceLevel } from "interfaces/serviceLevel.interface";
import { v4 as uuidv4 } from "uuid";
import { Moment } from "moment";
import * as constants from "constantsAndDefaults";
const { detect } = require("detect-browser");
import { IAddressBookItem } from "interfaces/addressBookItem.interface";
import { IBillingTransaction } from "interfaces/billing.interface";

// Used to check current app version
import packageJson from "../../package.json";
import initialUserOnboarding from "components/onboarding/userOnboarding.json";

let activeRequests: any = {};
let requestIdCounter = 0;

// Ensure that the response from the latest request is used:
// If two identical requests are made in the order A then B, and A returns slower than B does, A will be killed and the results from B will be used.
// If a returns before B, the results from A will be used until B returns a result

async function killDuplicateRequests(activeRequest: {
  body: any;
  method: string;
  requestId: number;
  url: string;
  requestTimeStamp: Date;
}) {
  let requestIds = Object.keys(activeRequests[activeRequest.url]);

  for (const requestId of requestIds) {
    let req = activeRequests[activeRequest.url][requestId];
    if (req.requestId === activeRequest.requestId) {
      // remove request from active requests
      delete activeRequests[activeRequest.url][requestId];
    } else if (
      activeRequest.url === req.url &&
      activeRequest.method === req.method &&
      JSON.stringify(activeRequest.body) === JSON.stringify(req.body)
    ) {
      // // kill all other requests with the same parameters & remove from active requests
      if (activeRequest.requestTimeStamp < req.requestTimeStamp) {
        // abort only if request was made before the active request and has not yet returned a response
        await req.abortController.abort();
        console.warn("Aborted duplicate request", `${req.method}: ${req.url}`);
        delete activeRequests[activeRequest.url][requestId];
      }
    }
    if (Object.keys(activeRequests[activeRequest.url]).length === 0) {
      delete activeRequests[activeRequest.url];
    }
  }
}

// Signed request call an API Gateway endpoint and signs the request signature version 4 with the given credentials
// store - the state store
// endpoint - the stage and name of the endpoint e.g. /Prod/shipments
// method - the http method GET, POST etc.s
// args - the query arguments (for GET) or the body arguments of the request
// headers - the http headers for the request e.g. {"Content-Type":"application/x-www-form-urlencoded"}
async function signedRequest(
  store: IStore,
  endpoint: string,
  method: string,
  args?: any,
  headers?: any,
  disallowDuplicateCancel?: boolean,
  retryCounter?: number
): Promise<any> {
  const maxRetries = 3;
  const _retryCounter: number = retryCounter ?? 0;
  if (!args) {
    args = {};
  }

  if (!headers) {
    headers = { "Content-Type": "application/json" };
  }

  headers["client-version"] = "web-" + packageJson.version;

  method = method.toUpperCase();

  try {
    let body = null;
    let path = endpoint;

    // Build query arguments from args for get
    if (method === "GET") {
      if (store && store.impersonated_user_id) {
        args.request_id = store.impersonated_user_id;
      }

      path += generalUtils.serialize(args);
      // Else use the args for the request body
    } else {
      body = JSON.stringify(args);

      if (store && store.impersonated_user_id) {
        path += generalUtils.serialize({
          request_id: store.impersonated_user_id
        });
      }
    }

    let url = `https://${config.api}${path}`;
    if (config.api.indexOf("localhost") >= 0) {
      url = `http://${config.api}${path}`;
    }

    // Options for the signature version 4 signing
    const opts: any = {
      method: method,
      path: path,
      headers: headers,
      hostname: config.api,
      url: url,
      region: config.region,
      service: "execute-api"
    };

    if (body !== null) {
      opts.body = body;
    }

    const credentials = await Auth.currentCredentials();
    // AWS credentials
    const { accessKeyId, secretAccessKey, sessionToken } = credentials;

    // create the signed request
    const _signedRequest: any = aws4.sign(opts, {
      accessKeyId,
      secretAccessKey,
      sessionToken
    });

    let request: any;

    let init: { method: string; headers: any; body: any; signal?: any } = {
      method: method,
      headers: _signedRequest.headers,
      body: body
    };

    if (method === "GET" && !disallowDuplicateCancel) {
      let requestId = requestIdCounter;
      requestIdCounter++;
      let abortController = new AbortController();
      request = {
        url: _signedRequest.url,
        method,
        abortController,
        body,
        requestId,
        requestTimeStamp: new Date()
      };
      if (!activeRequests[url]) {
        activeRequests[url] = {};
      }
      activeRequests[url][requestId] = request;
      init.signal = abortController.signal;
    }

    let response: any;

    response = await fetch(_signedRequest.url, init);

    if (method === "GET" && !disallowDuplicateCancel) {
      killDuplicateRequests(request);
    }

    if (!response) {
      return { ok: false, status: 400, error: "No response found" };
    }

    if (!response.ok) {
      generalUtils.checkMaintenanceMode(store, response);
      generalUtils.checkTokenExpired(store, response);
      generalUtils.checkAccountClosed(store, response);

      // Error message is in response body as text
      let errorMsg = await response.text();

      if (errorMsg.toLowerCase().indexOf("service unavailable") > -1) {
        if (_retryCounter < maxRetries) {
          return new Promise(async resolve => {
            setTimeout(async () => {
              let res = await signedRequest(
                store,
                endpoint,
                method,
                args,
                headers,
                true,
                _retryCounter + 1
              );
              resolve(res);
            }, 1000 * (_retryCounter + 1)); // progressively longer timeouts as retries progress
          });
        } else {
          console.log("Service unavailable, maximum retries reached.");
        }
      }

      // Don't change this method without changing the backend
      if (errorMsg.indexOf("You're not logged in. Please log in and try again.") !== -1) {
        // redirect back to login page if not logged in
        window.location.href = "/login";
        return;
      }

      return { ok: false, error: new Error(errorMsg), code: response.status };
    }

    let data = "";
    try {
      data = await response.text();
      data = JSON.parse(data);
    } catch (e) {
      // assume it is text
    }

    return { ok: true, data, code: response.status };
  } catch (e) {
    return { ok: false, error: generalUtils.getError(e, true) };
  }
}

async function getGeneralData(store: IStore): Promise<any> {
  let error = null;
  let data: IObjectKeys = {};
  try {
    let result: IResult = await signedRequest(store, "/general-data", "GET");

    if (result.ok) {
      data = result.data;
    } else {
      stopImpersonating(store);
      error = generalUtils.getError(result);
    }
  } catch (e) {
    error = "Could not load general data.";
    stopImpersonating(store);
  }

  if (data["account_settings"]) {
    let settings: any = {};

    for (let settingsObject of data.account_settings) {
      try {
        settingsObject.value = JSON.parse(settingsObject.value);
      } catch (_) {}
      settings[settingsObject.setting] = settingsObject;
    }

    data["account_settings"] = settings;
  }

  let packageSettings: any = {};

  if (data["packages"]) {
    let parcelTypes: any[] = [];

    data["packages"].forEach((el: any) => {
      let parcelType = {
        submitted_height_cm: el.height_cm,
        submitted_length_cm: el.length_cm,
        submitted_width_cm: el.width_cm,
        description: el.name,
        is_default: el.is_default
      };

      parcelTypes = parcelTypes.concat(parcelType);
    });
    packageSettings["packages"] = { setting: "packages", value: data.packages };
    packageSettings["parcel_types"] = { setting: "parcel_types", value: parcelTypes };
    data["package_settings"] = packageSettings;
  }

  let reaching_credit_limit: boolean = false;

  if (data["account"]) {
    if (
      Math.abs(data["account"].credit_limit - data["account"].balance) <= 50 &&
      data["account"].balance < 0
    ) {
      reaching_credit_limit = true;
    }
    data["account"]["reaching_credit_limit"] = reaching_credit_limit;
    if (data["account"].balance < 0) {
      data["account"]["remaining_balance"] = data["account"].balance + data["account"].credit_limit;
    } else {
      data["account"]["remaining_balance"] = Math.abs(
        data["account"].balance - data["account"].credit_limit
      );
    }
  }

  if (data["user"]) {
    roleUtils.fillRole(data.user, data.roles, store);
  }

  return { data, error };
}

function formatShippingStatus(str: string, na?: boolean) {
  if (!str && !na) {
    return "–";
  }

  if (!str && na) {
    return "Not available";
  }

  let string = str.charAt(0).toUpperCase() + str.slice(1);
  return string.replaceAll("-", " ");
}

const validateChannel = async (store: IStore, identifier: string): Promise<string> => {
  let response: string = "";

  try {
    let res = await signedRequest(store, "/channels/validate", "POST", {
      identifier: identifier
    });
    if (res.ok) {
      response = "success";
    } else {
      response = generalUtils.getError(res, true);
    }
  } catch (e) {
    response = "failed";
  }

  return response;
};

async function getSystemConfig(store: IStore, passedArgs?: any): Promise<any> {
  let systemConfig: any = {};
  let error = null;
  let args: any = {};
  if (passedArgs) {
    args = passedArgs;
  }

  try {
    let result: IResult = await signedRequest(store, "/config", "GET", { ...args }, {});

    if (result.ok) {
      if (result.data.config) {
        for (let configObject of result.data.config) {
          try {
            configObject.value = JSON.parse(configObject.value);
          } catch (_) {}
          systemConfig[configObject.key] = configObject;
        }
      }
    } else {
      error = generalUtils.getError(result, null);
    }
  } catch (e) {
    error = generalUtils.getError(e, null);
  }

  return { systemConfig, error };
}

async function getExceptionItems(
  store: IStore,
  limit: number,
  offset: number,
  invoice_id: number,
  status: string,
  invoiceItemId?: string | null
) {
  let error = null;
  let data: IObjectKeys = {};

  let args: any = {
    limit: limit,
    offset: offset,
    invoice_id: invoice_id,
    status: status
  };

  if (invoiceItemId) {
    args.invoice_item_id = parseInt(invoiceItemId);
  }

  try {
    let result: IResult = await signedRequest(store, "/billing/provider-invoices/items", "GET", {
      ...args
    });
    if (result.ok) {
      data = result.data;
    }
    if (!result.ok) {
      error = result;
    }
  } catch (e) {
    console.error(e);
  }
  return { data, error };
}

async function updateInvoiceItem(store: IStore, body: any) {
  let error = null;
  let data: IObjectKeys = {};
  try {
    let result: IResult = await signedRequest(
      store,
      "/billing/provider-invoices/items",
      "PATCH",
      body
    );
    if (result.ok) {
      store.emitter.emit("showToast", {
        text: `${generalUtils.keyToHumanReadable(body.action)} successfully executed`,
        variant: "success",
        autoHide: 3000
      });
      data = result.data;
      document.body.click();
    }
    if (!result.ok) {
      store.emitter.emit("showToast", {
        text: `${generalUtils.getError(result, true)}`,
        variant: "error",
        autoHide: 3000
      });
      error = result;
    }
  } catch (e) {
    console.error(e);
  }
  return { data, error };
}

function getAccountSetting(store: IStore, key: string) {
  let keyValue: any = null;
  if (!store.account?.id) {
    keyValue = null;

    return { keyValue };
  }

  keyValue = store.account_settings?.[key] ?? null;

  return { keyValue };
}

//@ts-ignore
async function getAccountSettings(store: IStore, accountId?: number): Promise<any> {
  let settings: any = {};
  let error = null;
  let args: any = {};

  if (accountId) {
    args.account_id = accountId;
  }
  try {
    let result: IResult = await signedRequest(store, "/accounts/settings", "GET", { ...args }, {});

    if (result.ok) {
      if (result.data.account_settings) {
        for (let settingsObject of result.data.account_settings) {
          try {
            settingsObject.value = JSON.parse(settingsObject.value);
          } catch (_) {}
          settings[settingsObject.setting] = settingsObject;
        }
      } else {
        error = generalUtils.getError(result, null);
      }
    }
  } catch (e) {
    error = generalUtils.getError(e, null);
  }

  return { settings, error };
}

async function updateAccountSetting(store: any, value: any, accountId?: number) {
  let accountSettings = generalUtils.clone(store.account_settings ?? {});
  let changeSetting = value.setting;
  let resData: any = null;

  let error = null;

  let formData = value;

  if (accountId) {
    formData.account_id = accountId;
  }

  try {
    let res: IResult = await signedRequest(store, "/accounts/settings", "POST", formData);

    if (res.ok) {
      if (res?.data) {
        resData = res.data;
        try {
          accountSettings[changeSetting].value = JSON.parse(res.data.value);
        } catch (e) {
          accountSettings[value.setting.value] = res.data.value;
        }
      }
    } else {
      error = generalUtils.getError(res, true);
    }
  } catch (e) {
    error = generalUtils.getError(e, true);
  }

  store.set("account_settings", accountSettings);

  return { resData, accountSettings, error };
}

async function getOrder(
  store: IStore,
  orderId: number,
  include_items?: boolean,
  include_fulfillments?: boolean,
  include_relations?: boolean
): Promise<any> {
  let newOrder: any = {};
  let newOrderError: any = null;
  let args: any = {
    id: orderId
  };
  if (include_relations) {
    args.include_relations = include_relations;
  }
  if (include_items) {
    args.include_items = include_items;
  }
  if (include_fulfillments) {
    args.include_fulfillments = include_fulfillments;
  }

  try {
    let res: IResult = await signedRequest(store, "/orders", "GET", args);

    if (res.ok) {
      newOrder = res.data.orders[0];
      newOrderError = null;
    } else {
      newOrder = {};
      newOrderError = generalUtils.getError(res, true);
    }
  } catch (e) {
    newOrderError = generalUtils.getError(e, true);
  }

  return { newOrder, newOrderError };
}

async function getProviders(store: IStore, accountId?: number, params?: any): Promise<any> {
  let providers: any = [];
  let providersCount: number = 0;
  let providersError: any = null;
  let serviceLevels: IServiceLevel[] = [];
  let args: any = {};

  if (params) {
    args = params;
  }

  if (accountId) {
    args.account_id = accountId;
  }

  try {
    let result: IResult = await signedRequest(store, "/providers", "GET", args, {});

    if (result.ok) {
      if (result.data.providers) {
        providers = result.data.providers;
        providersCount = result.data.count;
      }
      if (result.data.providers) {
        result.data.providers.forEach((el: IProvider) => {
          let consolidatedServiceLevels = el.service_levels;
          serviceLevels = serviceLevels.concat(consolidatedServiceLevels);
        });
      }
    } else {
      providersError = generalUtils.getError(result, false);
    }
  } catch (e) {
    console.error(e);
  }

  return { providers, providersCount, providersError, serviceLevels };
}

async function getAccountPlan(store: IStore, account_id?: number, plan_id?: number) {
  // setIsLoading(true);
  let plans: any = {};
  let args: any = {};
  let planError: any = null;

  if (account_id) {
    args.account_id = account_id;
  }
  if (plan_id) {
    args.id = plan_id;
  }
  try {
    const res: IResult = await signedRequest(store, "/plans", "GET", { ...args });

    if (!res.ok) {
      plans = {};
      planError = generalUtils.getError(res, false);
    } else {
      planError = null;
      if (plan_id) {
        plans = res.data.plans[0];
      } else {
        plans = res.data.plans;
      }
    }
  } catch (e) {
    console.error(e);
  }
  return { plans, planError };
}

async function getAccountProviders(store: IStore, accountId?: number): Promise<any> {
  let accountProviders: any = {};
  let accountProviderError: any = null;
  let args: any = {};

  if (accountId) {
    args.account_id = accountId;
  }

  try {
    const result: IResult = await signedRequest(
      store,
      "/accounts/providers",
      "GET",
      {
        ...args
      },
      {}
    );
    if (result.ok) {
      if (result.data.providers) {
        accountProviders = result.data.providers.map((provider: IProvider) => ({
          ...provider,
          provider_id: provider.id
        }));
      }
    } else {
      accountProviderError = generalUtils.getError(result, false);
    }
  } catch (e) {
    accountProviderError = generalUtils.getError(e, false);
  }
  return { accountProviders, accountProviderError };
}

async function getBillingInfo(store: IStore, accountId?: number): Promise<any> {
  let billingInfo: any = {};
  let billingError: any = null;
  let args: any = {};

  if (accountId) {
    args.account_id = accountId;
  }

  try {
    const res: IResult = await signedRequest(store, "/accounts/billing-info", "GET", args, {});

    if (res.ok) {
      billingInfo = res.data;
    } else {
      billingError = generalUtils.getError(res, false);
    }
  } catch (e) {
    console.error(e);
  }

  return { billingInfo, billingError };
}

async function getChannels(store: IStore, accountId?: number): Promise<any> {
  let channels: any;
  let channelsError = null;
  let count: number = 0;
  let args: any = {};

  if (accountId) {
    args.account_id = accountId;
  }

  try {
    let result: IResult = await signedRequest(store, "/channels", "GET", args, {});

    if (result.ok) {
      if (result.data.channels) {
        channels = result.data.channels;
        count = result.data.count - 1;
      }
    } else {
      channelsError = generalUtils.getError(result, false);
    }
  } catch (e) {
    console.error(e);
  }
  return { channels, count, channelsError };
}

async function getAccountPackages(store: IStore, accountId?: number): Promise<any> {
  let packageSettings: any = {};
  let packageError = null;
  let args: any = {};

  if (accountId) {
    args.account_id = accountId;
  }

  try {
    let result: IResult = await signedRequest(store, "/packages", "GET", { ...args }, {});

    if (result.ok) {
      if (result.data.packages) {
        let parcelTypes: any[] = [];

        result.data.packages.forEach((el: any) => {
          let parcelType = {
            submitted_height_cm: el.height_cm,
            submitted_length_cm: el.length_cm,
            submitted_width_cm: el.width_cm,
            description: el.name,
            is_default: el.is_default
          };

          parcelTypes = parcelTypes.concat(parcelType);
        });
        packageSettings["packages"] = { setting: "packages", value: result.data.packages };
        packageSettings["parcel_types"] = { setting: "parcel_types", value: parcelTypes };
      }
    } else {
      packageError = generalUtils.getError(result, false);
    }
  } catch (e) {
    packageError = generalUtils.getError(e, false);
  }
  return { packageSettings, packageError };
}

function generateQuickFulfillPayload(order: IOrder) {
  let formData: any = {};

  formData = {
    account_id: order.account_id,
    order_id: order.id,
    method: order.order_fulfillment_presets.method,
    type: "original",
    source: "quick",
    order_fulfillment_presets: {
      collection_address_book_id: order.order_fulfillment_presets.collection_address_book_id,
      parcels: order.order_fulfillment_presets.parcels,
      provider_slug: order.order_fulfillment_presets.provider_slug,
      service_level_code: order.order_fulfillment_presets.service_level_code,
      instructions_delivery: order.order_fulfillment_presets.instructions_delivery ?? "",
      instructions_collection: order.order_fulfillment_presets.instructions_collection ?? ""
    }
  };

  if (order.order_items) {
    formData = {
      ...formData,
      items: order.order_items
        .filter(item => item.qty - item.fulfilled_qty !== 0)
        .map(item => ({
          order_item_id: item.id,
          qty: item.qty - item.fulfilled_qty
        }))
    };
  }

  return { formData };
}

function formatPhoneNumber(number: string | undefined): String {
  if (!number || number.length === 0) {
    return "–";
  }
  let cleaned;
  let match;

  cleaned = ("" + number).replace(/\D/g, "");
  match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

  if (match) {
    return "(" + match[1] + ") " + match[2] + "-" + match[3];
  }

  return number;
}

function generateParcelDimensions(parcel: IParcel, key?: string): string {
  let checkKey: any = key ?? "submitted";

  if (
    //@ts-ignore
    !parcel[`${checkKey}_length_cm`] &&
    //@ts-ignore
    !parcel[`${checkKey}_width_cm`] &&
    //@ts-ignore
    !parcel[`${checkKey}_height_cm`]
  ) {
    return "Not available";
  }

  // @ts-ignore
  const l: any = parcel[`${checkKey}_length_cm`]
    ? // @ts-ignore
      `${parcel[`${checkKey}_length_cm`].toFixed(0)} cm x `
    : `0 cm x `;
  // @ts-ignore
  const w = parcel[`${checkKey}_width_cm`]
    ? // @ts-ignore
      `${parcel[`${checkKey}_width_cm`].toFixed(0)} cm x `
    : `0 cm x `;
  // @ts-ignore
  const h = parcel[`${checkKey}_height_cm`]
    ? // @ts-ignore
      `${parcel[`${checkKey}_height_cm`].toFixed(0)} cm – `
    : `0 cm - `;
  // @ts-ignore
  const weight = parcel[`${checkKey}_weight_kg`]
    ? // @ts-ignore
      `${parcel[`${checkKey}_weight_kg`].toFixed(2)} kg`
    : `0 kg`;
  return l + w + h + weight;
}

function generateFormattedAddress(address?: IAddress | any) {
  if (!address) {
    return "–";
  }

  let formattedAddress: any = {};

  if (address.company) {
    formattedAddress["company"] = address.company;
  }
  if (address.address) {
    formattedAddress["address"] = address.address;
  }
  if (address.street_address) {
    formattedAddress["street_address"] = address.street_address;
  }
  if (address.local_area) {
    formattedAddress["local_area"] = address.local_area;
  }
  if (address.city) {
    formattedAddress["city"] = address.city;
  }
  if (address.zone) {
    formattedAddress["zone"] = addressUtils.provinceAbbreviationToName(address.zone);
  }
  if (address.country) {
    formattedAddress["country"] = address.country === "ZA" ? "South Africa" : address.country;
  }
  if (address.code) {
    formattedAddress["code"] = address.code;
  }
  // @ts-ignore
  if (address.postal_code) {
    // @ts-ignore
    formattedAddress["postal_code"] = address.postal_code;
  }
  return Object.values(formattedAddress).join(", ");
}

function generateAddressPayload(address: IAddress) {
  let payloadAddress: any = {};

  if (!address) {
    return payloadAddress;
  }

  if (address.id) {
    payloadAddress["id"] = address.id;
  }

  if (address.company) {
    payloadAddress["company"] = address.company;
  }
  if (address.street_address) {
    payloadAddress["street_address"] = address.street_address;
  }
  if (address.city) {
    payloadAddress["city"] = address.city;
  }
  if (address.local_area) {
    payloadAddress["local_area"] = address.local_area;
  }
  if (address.zone) {
    payloadAddress["zone"] = address.zone;
  }
  if (address.country) {
    payloadAddress["country"] = address.country;
  }
  if (address.code) {
    payloadAddress["code"] = address.code;
  }
  if (address.lat) {
    payloadAddress["lat"] = address.lat;
  }
  if (address.lng) {
    payloadAddress["lng"] = address.lng;
  }
  if (address.type) {
    payloadAddress["type"] = address.type;
  }

  return payloadAddress;
}

function getUserSetting(store: IStore, key: string): any {
  let keyValue = store.user_settings?.[key];

  return { keyValue };
}

async function getUserSettings(store: IStore, settingName: string | undefined): Promise<any> {
  let activeUser = activeUserFromStore(store);
  if (!activeUser) return;
  let error = null;
  let queryParams: { limit: number; user_id: number; setting?: string } = {
    limit: 9999,
    user_id: activeUser.id
  };
  let settings: any = {};

  if (settingName) {
    queryParams["setting"] = settingName;
  }

  try {
    let result: IResult = await signedRequest(store, "/users/settings", "GET", queryParams, {});

    if (result.ok) {
      if (result.data.user_settings) {
        for (let s of result.data.user_settings) {
          try {
            settings[s.setting] = JSON.parse(s.value);
          } catch (_) {
            settings[s.setting] = s.value;
          }
        }
      }
    } else {
      console.log(result.error);
      error = "Could not load user settings.";
    }
  } catch (e) {
    console.log(e);
    error = "Could not load user settings.";
  }

  return { settings, error };
}

async function getUserOnboardingSettings(store: any, settingName: "onboarding") {
  let user_settings = store.get("user_settings");

  if (!user_settings) {
    user_settings = {};
  }

  if (!user_settings[settingName]) {
    await setUserSetting(store, settingName, JSON.stringify(initialUserOnboarding));
  }
}

async function setUserSetting(store: IStore, key: any, value: any): Promise<any> {
  let result: IResult = await signedRequest(
    store,
    "/users/settings",
    "POST",
    {
      setting: key,
      value: value
    },
    null
  );

  if (result.ok) {
    let userSettings = store.get("user_settings");
    if (!userSettings) {
      userSettings = {};
    }
    if (result?.data?.setting) {
      try {
        userSettings[result.data.setting] = JSON.parse(result.data.value);
      } catch (e) {
        userSettings[result.data.setting] = result.data.value;
      }
    }
    store.set("user_settings", userSettings);
    return undefined;
  } else {
    return generalUtils.getError(result, null);
  }
}

function keyToHumanReadableLower(key: string | undefined): string {
  if (!key) return "";

  var keyHumanReadable = key.replaceAll("_", " ");
  keyHumanReadable = keyHumanReadable.replaceAll("sender", "collection");
  keyHumanReadable = keyHumanReadable.replaceAll("receiver", "delivery");
  keyHumanReadable = keyHumanReadable.replaceAll("-", " ");

  // camelcase to sentence case
  keyHumanReadable = keyHumanReadable.replace(/([A-Z])/g, " $1").trim();

  return keyHumanReadable;
}

function getCommunicationLogStatusClassNames(status: string): string {
  if (status === "success") {
    return "status-badge green-status";
  } else if (status === "failed" || status === "bounced" || status === "complaint") {
    return "status-badge red-status";
  }

  return "status-badge blue-status";
}

function getCreditAllocationClassName(value: number): string {
  if (value < 0) {
    return "status-badge red-status";
  }
  return "status-badge green-status";
}

function getWebhookStatusClassNames(status: string): string {
  let className: string;
  switch (status) {
    case "inactive":
      className = "status-badge red-status";
      break;
    case "active":
      className = "status-badge green-status";
      break;
    default:
      className = "status-badge green-status";
      break;
  }

  return className;
}

// TODO rework to switch statements
function getStatusClassNames(status: string): string {
  if (["failed-will-retry", "delivered", "closed"].indexOf(status) >= 0) {
    return "status-badge inactive-status";
  } else if (["pending"].indexOf(status) >= 0) {
    return "status-badge yellow-status";
  } else if (["finalised"].indexOf(status) >= 0) {
    return "status-badge gray-status";
  } else if (
    [
      "failed-indefinitely",
      "cancelled",
      "payment-failed",
      "tracking-failed",
      "collection-exception",
      "delivery-exception",
      "cancelled",
      "delivery-failed-attempt",
      "collection-failed-attempt",
      "collection-unassigned",
      "delivery-unassigned",
      "failed",
      "pending-payment",
      "collection-rejected",
      "delivery-rejected",
      "exception",
      "no-rates",
      "cancelled-by-courier",
      "cancelled-by-provider"
    ].indexOf(status) >= 0
  ) {
    return "status-badge red-status";
  } else if (
    [
      "success",
      "done",
      "at-destination-hub",
      "returned-to-hub",
      "delivery-assigned",
      "out-for-delivery",
      "in-locker"
    ].indexOf(status) >= 0
  ) {
    return "status-badge green-status";
  } else if (["completed"].indexOf(status) >= 0) {
    return "status-badge primary-status";
  }
  return "status-badge blue-status";
}

function getResponseCodeClassNames(code: number): string {
  if (code >= 200 && code < 300) {
    return "status-badge green-status";
  }
  if (code >= 400) {
    return "status-badge red-status";
  }
  return "status-badge gray-status";
}

function getHttpMethodClassNames(method: string): string {
  if (method.toUpperCase() === "GET") {
    return "status-badge green-status";
  }
  if (method.toUpperCase() === "DELETE") {
    return "status-badge gray-status";
  }
  if (method.toUpperCase() === "POST") {
    return "status-badge yellow-status";
  }
  if (method.toUpperCase() === "PATCH") {
    return "status-badge gray-status";
  }
  return "status-badge primary-status";
}

function getStatusAssociatedColor(status: string): string {
  if (["pending", "failed-will-retry"].indexOf(status) >= 0) {
    return "grey";
  } else if (["failed-indefinitely"].indexOf(status) >= 0) {
    return "red";
  } else if (["success", "done"].indexOf(status) >= 0) {
    return "green";
  }
  return "blue";
}

function getApiTypeClassNames(status: string): string {
  if (status.includes("outgoing")) {
    return "status-badge gray-status";
  }
  if (!status) {
    return "";
  }
  return "status-badge primary-status";
}

function getInvoiceStatusClassNames(status: string): string {
  if (status.toLowerCase() === "unpaid") {
    return "status-badge red-status";
  }
  if (status.toLowerCase() === "partially-paid") {
    return "status-badge yellow-status";
  }
  return "status-badge green-status";
}

function getPaymentStatusClassNames(status: string): string {
  if (status.toLowerCase() === "success") {
    return "status-badge green-status";
  }
  if (status.toLowerCase() === "pending") {
    return "status-badge blue-status";
  }
  return "status-badge red-status";
}

function getUnmatchedWaybillStatusClassName(status: string): string {
  if (status.toLowerCase() === "unresolved") {
    return "status-badge pink-status";
  }

  if (status.toLowerCase() === "resolved") {
    return "status-badge green-status";
  }

  return "status-badge primary-status";
}

function getOrderPaymentStatusClassNames(status: string): string {
  if (status.toLowerCase() === "unpaid" || status.toLowerCase().replaceAll(" ", "-") === "voided") {
    return "status-badge-small pink-status";
  }
  if (status.toLowerCase().replaceAll(" ", "-") === "partially-paid") {
    return "status-badge-small yellow-status";
  }
  return "status-badge-small green-status";
}

function getOrderStatusClassNames(status: string): string {
  if (status.toLowerCase().replaceAll(" ", "-") === "completed") {
    return "status-badge-small primary-status";
  }
  if (status.toLowerCase().replaceAll(" ", "-") === "cancelled") {
    return "status-badge-small pink-status";
  }
  return "status-badge-small yellow-status";
}

function getFulfillmentStatusClassNames(status: string): string {
  if (status.toLowerCase().replaceAll(" ", "-") === "fulfilled") {
    return "status-badge-small blue-status";
  }
  if (status.toLowerCase().replaceAll(" ", "-") === "unfulfilled") {
    return "status-badge-small pink-status";
  }
  return "status-badge-small yellow-status";
}

function getFulfillmentStatusSmallClassNames(status: string): string {
  if (status.toLowerCase().replaceAll(" ", "-") === "fulfilled") {
    return "status-badge-small green-status";
  }
  if (status.toLowerCase().replaceAll(" ", "-") === "unfulfilled") {
    return "status-badge-small pink-status";
  }
  return "status-badge-small yellow-status";
}

function getPaymentMethodSmallClassNames(status: string): string {
  if (status.toLowerCase().replaceAll(" ", "-") === "partially-paid") {
    return "status-badge-small yellow-status";
  }
  if (status.toLowerCase().replaceAll(" ", "-") === "unpaid") {
    return "status-badge-small pink-status";
  }
  if (status.toLowerCase().replaceAll(" ", "-") === "paid") {
    return "status-badge-small green-status";
  }
  return "status-badge-small primary-status";
}

function getChannelStatusClassNames(status: string): string {
  if (status.toLowerCase() === "active") {
    return "status-badge green-status";
  }
  return "status-badge red-status";
}

function getAccountNoteTypeClassNames(type: string): string {
  if (type === "sales") {
    return "notes-badge green-note";
  }
  if (type === "private") {
    return "notes-badge fuschia-note";
  }
  return "notes-badge iris-note";
}

function getAccountActivityTypeClassNames(type: string): string {
  if (type === "channels") {
    return "account-activity-badge green-account-activity";
  }
  if (type === "account-providers") {
    return "account-activity-badge blue-account-activity";
  }
  if (type === "users") {
    return "account-activity-badge yellow-account-activity";
  }
  if (type === "account-settings") {
    return "account-activity-badge red-account-activity";
  }
  if (type === "address-books") {
    return "account-activity-badge fuschia-account-activity";
  }
  if (type === "account-plan") {
    return "account-activity-badge turquoise-account-activity";
  }
  return "account-activity-badge orange-account-activity";
}

function getProviderCreditNotesClasses(status: string): string {
  if (status.toLowerCase() === "new") {
    return "status-badge-small green-status";
  }
  if (status.toLowerCase() === "unmatched" || status.toLowerCase() === "different-account") {
    return "status-badge-small red-status";
  }
  if (status.toLowerCase() === "credit-applied") {
    return "status-badge-small yellow-status";
  }
  if (status.toLowerCase() === "resolved") {
    return "status-badge-small gray-status";
  }
  return "";
}

async function searchForShipments(
  referenceSearch: string,
  store: IStore,
  isExactSearch?: boolean
): Promise<any> {
  let shipmentReferenceSearch = referenceSearch;
  const args: any = {
    tracking_reference: shipmentReferenceSearch,
    limit: 1
  };

  if (isExactSearch) {
    args.global = true;
  }

  // remove slashes for shipment search
  if (shipmentReferenceSearch.indexOf("/") >= 0) {
    shipmentReferenceSearch = shipmentReferenceSearch.substr(
      0,
      shipmentReferenceSearch.indexOf("/")
    );
  }

  // look for shipment reference
  var result: IResult = await signedRequest(store, "/shipments", "GET", args, null);

  if (result.ok && result.data) {
    let { shipments } = result.data;
    if (shipments && shipments.length > 0) {
      return shipments;
    }
  }

  return undefined;
}

function addressObjFromGoogleResult(place: any): {
  company: any;
  street_address: any;
  local_area: any;
  city: any;
  zone: any;
  country: any;
  code: any;
  lat: any;
  lng: any;
} {
  var googleAddressObj: any = {};
  googleAddressObj.lat_lng = {
    lat: place.geometry.location.lat(),
    lng: place.geometry.location.lng()
  };

  for (let i = 0; i < place.address_components.length; i++) {
    let addressType = place.address_components[i].types[0];
    // get the long/short version of the place address component base on componentForm
    if (constants.googleComponentForm[addressType]) {
      googleAddressObj[addressType] =
        place.address_components[i][constants.googleComponentForm[addressType]];
    }
  }

  // Map to names expected by address form
  let streetAddress = googleAddressObj.street_number
    ? googleAddressObj.street_number + " " + googleAddressObj.route
    : googleAddressObj.route;
  let company = place.types.includes("establishment") ? place.name : "";

  let localArea = [];

  if (googleAddressObj.sublocality_level_1) {
    localArea.push(googleAddressObj.sublocality_level_1);
  }

  if (googleAddressObj.sublocality_level_2) {
    localArea.push(googleAddressObj.sublocality_level_2);
  }

  let city: any;

  if (googleAddressObj.locality) {
    city = googleAddressObj.locality;
  }

  if (!localArea || localArea.length === 0) {
    localArea.push(googleAddressObj.locality);
    city = null;
  }

  if (!city) {
    city = googleAddressObj.administrative_area_level_2;
  }

  const addressObj = {
    company,
    street_address: streetAddress,
    local_area: localArea.join(", "),
    city,
    code: googleAddressObj.postal_code,
    zone: googleAddressObj.administrative_area_level_1,
    country: googleAddressObj.country,
    lat: parseFloat(googleAddressObj.lat_lng.lat.toFixed(6)),
    lng: parseFloat(googleAddressObj.lat_lng.lng.toFixed(6)),
    entered_address: ""
  };

  // @ts-ignore
  addressObj.entered_address = addressUtils.generateEnteredAddress(addressObj);

  return addressObj;
}

function activeUser(props: { store: IStore }): IUser | undefined {
  if (props && props.store) {
    return activeUserFromStore(props.store);
  }

  return undefined;
}

function IsJsonString(str: any) {
  try {
    JSON.parse(str);
    return true;
  } catch (e) {
    return false;
  }
}

function activeUserFromStore(store: IStore): IUser {
  return store.logged_in_user;
}

function pgFormatDate(date: string | Date | Moment | undefined): string {
  if (!date) return "";
  let momentDate: Moment = moment(date);
  return momentDate.format("YYYY-MM-DD HH:mm:ss");
}

function addFiltersToArgs(
  filters: any,
  args: any,
  wildCardedColumns?: string[],
  dontChangeDates?: boolean
) {
  if (!wildCardedColumns) wildCardedColumns = [];

  Object.keys(filters).forEach(key => {
    if (filters[key] && (!Array.isArray(filters[key]) || filters[key].length > 0)) {
      var val = filters[key];

      if (
        key === "start_date" ||
        key === "date" ||
        key === "from_invoice_date" ||
        key === "fulfillment_start_date"
      ) {
        if (dontChangeDates) {
          val = pgFormatDate(moment(val));
        } else {
          val = pgFormatDate(moment(val).startOf("day"));
        }
      }

      if (key === "end_date" || key === "to_invoice_date" || key === "fulfillment_end_date") {
        if (dontChangeDates) {
          val = pgFormatDate(moment(val));
        } else {
          val = pgFormatDate(moment(val).endOf("day"));
        }
      }

      if (Array.isArray(filters[key])) {
        args[key] = JSON.stringify(val);
        // @ts-ignore
      } else if (wildCardedColumns.indexOf(key) === -1) {
        args[key] = val;
      } else {
        // Encode with % wildcard (postgres) at the begining and end of the argument
        // The encoding is need because args are put into the query URL
        args[key] = "%" + val + "%";
      }
    }
  });
}

function addFiltersToArgsCheck(
  filters: any,
  args: any,
  wildCardedColumns?: string[],
  dontChangeDates?: boolean
) {
  if (!wildCardedColumns) wildCardedColumns = [];

  Object.keys(filters).forEach(key => {
    if (filters[key] && (!Array.isArray(filters[key]) || filters[key].length > 0)) {
      var val = filters[key];

      if (key === "start_date" || key === "date" || key === "from_invoice_date") {
        if (dontChangeDates) {
          val = pgFormatDate(moment(val));
        } else {
          val = pgFormatDate(moment(val).startOf("day"));
        }
      }

      if (key === "end_date" || key === "to_invoice_date") {
        if (dontChangeDates) {
          val = pgFormatDate(moment(val));
        } else {
          val = pgFormatDate(moment(val).endOf("day"));
        }
      }

      if (Array.isArray(filters[key])) {
        args[key] = JSON.stringify(val);
        // @ts-ignore
      } else if (wildCardedColumns.indexOf(key) === -1) {
        args[key] = val;
      } else {
        // Encode with % wildcard (postgres) at the begining and end of the argument
        // The encoding is need because args are put into the query URL
        args[key] = "%" + val + "%";
      }
    }
  });

  return args;
}

function clone(obj: any): any {
  if (!obj) return;
  return JSON.parse(JSON.stringify(obj));
}

function creditNoteUaDoNumber(val: any): string {
  if (!val) return "-";
  if (val?.includes("CN-UA")) return val;
  return generalUtils.padLeadingZeros(val, 6);
}

function invoiceNumber(val: any): string {
  if (!val) return "-";
  return "INV-UA" + val;
  if (val?.includes("INV-UA")) return val;
  return generalUtils.padLeadingZeros(val, 6);
}

function archivedInvoiceNumber(val: any): string {
  if (!val) return "-";
  return "UEP-" + val;
  if (val?.includes("UEP")) return val;
  return generalUtils.padLeadingZeros(val, 6);
}

function archivedCreditNoteNumber(val: any): string {
  if (!val) return "-";
  return "UCN-" + val;
  if (val?.includes("UCN")) return val;
  return generalUtils.padLeadingZeros(val, 6);
}

function creditNoteNumber(val: any): string {
  if (!val) return "-";
  return "CN-UA" + val;
  // return "CN-UA" + generalUtils.padLeadingZeros(val, 6);
}

function getBalanceClassNames(account_balance: number, small: boolean): string {
  if (small) {
    return (
      "mt-2 text-sm text-center rounded-full px-5 py-2 font-bold " +
      (account_balance < 0 ? "bg-pink-100 text-pink" : "bg-green-100 text-green")
    );
  }
  return (
    "rounded-full text-center px-5 py-2 font-bold min-h-9 " +
    (account_balance < 0 ? "bg-pink-100 text-pink" : "bg-green-100 text-green")
  );
}

function cleanEmailLoginPage(val: string): string {
  let onlyAscii = val.replace(/[^\x00-\x7F]/g, "");
  let nonWhitespace = onlyAscii.replace(/\s/g, "");
  return nonWhitespace;
}

async function generateAndOpenS3Resource(
  store: IStore,
  url: string,
  args: any,
  method: string = "GET"
): Promise<any> {
  try {
    let response: IResult = await signedRequest(store, url, method, args, {
      "Content-Type": "application/json"
    });

    if (response.ok && response.data) {
      let data: {
        url?: string;
        filename?: string;
        bucket?: string;
        email?: string;
        queued?: boolean;
        message?: string;
      } = response.data;
      if (data.url) {
        window.open(data.url);
      } else if (data.queued) {
        store.emitter.emit("showAlert", {
          title: "File download",
          body: `We see that you are trying to download a large number of items. Let us rather send them to ${data.email} once the file has been generated.`,
          showOkButton: true,
          return: () => {}
        });
      }
    } else if (
      !response.ok &&
      response.error.message.indexOf("User does not have an email address") >= 0
    ) {
      store.emitter.emit("showAlert", {
        title: "File download",
        body: "Please associate an email address with your account before attempting to download a large number of items.",
        showOkButton: true,
        return: () => {}
      });
    } else {
      return generalUtils.getError(response, null);
    }
  } catch (e) {
    return generalUtils.getError(e, null);
  }
}

function getDocProviderDocLink(
  transaction: IBillingTransaction,
  props: { store: IStore }
): ReactElement | string {
  const invoice_number = transaction.provider_doc_number ?? "";

  if (localRoleUtils.userHasAdminSystemAccess(props)) {
    if (invoice_number) {
      return (
        <Link
          to={"/billing" + `?tab=invoice-uploader&invoice_number=${invoice_number}`}
          className="link color"
        >
          {displayString(invoice_number)}
        </Link>
      );
    }
  }
  return "–";
}

function paymentNumber(val: any): string {
  if (!val) return "-";
  if (val.includes("PMT")) return val;
  return "PMT" + generalUtils.padLeadingZeros(val, 6);
}

function getDocIDLink(
  row: any,
  activeUser: IUser,
  props: { store: IStore }
): ReactElement | string {
  const modifiedPayload = row.original ?? row;

  if (modifiedPayload.invoice_id) {
    return (
      <Link
        to={
          "/" +
          (localRoleUtils.userHasAdminSystemAccess(props) ? "accounts/" : "accounts/") +
          "invoices/" +
          modifiedPayload.invoice_id
        }
        className="link color"
      >
        {modifiedPayload.ua_doc_number}
      </Link>
    );
  } else if (modifiedPayload.credit_note_id) {
    return (
      <Link
        to={
          "/" +
          (localRoleUtils.userHasAdminSystemAccess(props) ? "accounts/" : "accounts/") +
          "credit-notes/" +
          modifiedPayload.credit_note_id
        }
        className="link color"
      >
        {creditNoteUaDoNumber(modifiedPayload.ua_doc_number)}
      </Link>
    );
  } else if (modifiedPayload.imported_payment_id && localRoleUtils.isStaff(activeUser)) {
    return (
      <Link
        to={"/billing/imported-payments/" + modifiedPayload.imported_payment_id}
        className="link color"
      >
        {paymentNumber(modifiedPayload.ua_doc_number)}
      </Link>
    );
  }

  return <div>{displayString(modifiedPayload.ua_doc_number)}</div>;
}

async function getAccount(
  store: IStore,
  accountId?: number,
  includeShipments?: boolean,
  includeOrders?: boolean,
  includeChannels?: boolean,
  includeUsers?: boolean,
  includeBalances?: boolean,
  accountLimit?: boolean
): Promise<any> {
  let args: any = {};
  if (!accountId) {
    let activeUser: IUser = activeUserFromStore(store);
    if (!activeUser || !activeUser.account_id) return {};

    accountId = activeUser.account_id;
  }

  args.id = accountId;

  if (includeShipments) {
    args.include_shipments_count = true;
  }

  if (includeOrders) {
    args.include_orders_count = true;
  }

  if (includeChannels) {
    args.include_channels_count = true;
  }

  if (includeUsers) {
    args.include_users_count = true;
  }

  if (includeBalances) {
    args.include_balances = true;
  }

  try {
    let result: IResult = await signedRequest(
      store,
      "/accounts",
      "GET",
      {
        ...args
      },
      null
    );
    if (!result.ok) {
      return { error: generalUtils.getError(result, null) };
    }

    if (!result.ok) {
      return { error: generalUtils.getError(result, null) };
    }

    if (result.ok && result.data.accounts && result.data.accounts.length > 0) {
      let returnAccount = result.data.accounts[0];

      if (accountLimit) {
        let reaching_credit_limit: boolean = false;
        let credit_limit_reached: boolean = false;

        let absCL: number = Math.abs(returnAccount.credit_limit);
        let absBal: number = Math.abs(returnAccount.balance);
        if (returnAccount.balance < 0 && absBal / absCL > 0.8) {
          reaching_credit_limit = true;
        }
        if (absBal > absCL) {
          credit_limit_reached = true;
        }
        returnAccount["reaching_credit_limit"] = reaching_credit_limit;
        returnAccount["credit_limit_reached"] = credit_limit_reached;
        if (returnAccount.balance < 0) {
          returnAccount["remaining_balance"] = returnAccount.balance + returnAccount.credit_limit;
        } else {
          returnAccount["remaining_balance"] = Math.abs(
            returnAccount.balance - returnAccount.credit_limit
          );
        }
      }
      return { account: returnAccount };
    } else {
      return { account: null };
    }
  } catch (e) {
    return { error: generalUtils.getError(e, null) };
  }
}

async function updateAccount(store: IStore, account: IAccount): Promise<any> {
  try {
    let result: IResult = await signedRequest(store, "/accounts", "PATCH", account, null);

    if (result.ok) {
      // update local state
      if (store.account) {
        store.set("account", result.data);
      }
      return { account: result.data };
    } else {
      return { error: generalUtils.getError(result, null) };
    }
  } catch (e) {
    return { error: generalUtils.getError(e, null) };
  }
}

async function addAddressBook(store: IStore, addressBookItem: IAddressBookItem): Promise<any> {
  try {
    const result: IResult = await signedRequest(
      store,
      "/accounts/address-books",
      "POST",
      addressBookItem,
      null
    );

    if (result.ok) {
      return { addressBookItem: result.data };
    } else {
      return { addressBookError: generalUtils.getError(result, false) };
    }
  } catch (e) {
    return { addressBookError: generalUtils.getError(e, false) };
  }
}

function isAccountUser(props: { store: IStore }) {
  const { impersonated_user_id } = props.store;
  const activeUser = activeUserFromStore(props.store);

  return impersonated_user_id || (activeUser && activeUser.account_id) || !activeUser;
}

function isMaintenanceModePage() {
  return window.location.pathname === "/maintenance";
}

function isAccountClosedPage() {
  return window.location.pathname === "/account-closed";
}

const requestTypes = [
  {
    label: "Outgoing",
    options: [
      {
        label: "Outbound API",
        value: "api-outgoing"
      },
      {
        label: "Outbound webhook",
        value: "webhook-outgoing"
      }
    ]
  },
  {
    label: "Incoming",
    options: [
      {
        label: "Inbound API",
        value: "api-incoming"
      },
      {
        label: "Inbound webhook",
        value: "webhook-incoming"
      }
    ]
  }
];

const statusCode = [
  {
    label: "200s",
    value: "200"
  },
  {
    label: "300s",
    value: "300"
  },
  {
    label: "400s",
    value: "400"
  },
  {
    label: "500s",
    value: "500"
  }
];

const requestMethod = [
  {
    label: "GET",
    value: "GET"
  },
  {
    label: "POST",
    value: "POST"
  },
  {
    label: "PATCH",
    value: "PATCH"
  },
  {
    label: "PUT",
    value: "PUT"
  },
  {
    label: "DELETE",
    value: "DELETE"
  },
  {
    label: "SOAP",
    value: "SOAP"
  }
];

const shipmentsPageFilters = (string: string) => {
  if (string === "shipment_status") {
    return [
      "pending-collection",
      "collected",
      "out-for-delivery",
      "in-transit",
      "delivered",
      "finalised",
      "cancelled",
      "cancelled-by-courier",
      "exception",
      "failed-delivery"
    ];
  }
  if (string === "tracking_status") {
    return [
      "Collected by courier",
      "Info received",
      "In transit",
      "Out for delivery",
      "Failed attempt",
      "Delivered",
      "Exception",
      "Expired",
      "Pending collection",
      "No status",
      "Cancelled",
      "Finalised"
    ];
  }
  if (string === "submission_status") {
    return [
      "pending-rates",
      "pending-submission",
      "failed-indefinitely",
      "no-rates",
      "failed-will-retry",
      "success"
    ];
  }
  if (string === "zone") {
    return [
      { label: "Eastern Cape", value: "EC" },
      { label: "Free State", value: "FS" },
      { label: "Gauteng", value: "GP" },
      { label: "Limpopo", value: "LP" },
      { label: "Mpumalanga", value: "MP" },
      { label: "Northern Cape", value: "NC" },
      { label: "Kwazulu-Natal", value: "KZN" },
      { label: "North West", value: "NW" },
      { label: "Western Cape", value: "WC" }
    ];
  }
  return [];
};

const surchargeOptions = [
  "Embassy",
  "Plot",
  "Farm",
  "Shopping mall",
  "Township",
  "Other",
  "Regional"
];

function cleanupShipmentForDuplication(
  clonedShipment: IShipment,
  originalShipment: IShipment,
  setShipment: Function
) {
  if (clonedShipment && clonedShipment.collection_address) {
    // @ts-ignore
    delete clonedShipment.collection_address?.id;
    delete clonedShipment.collection_address.gs_hash;
    // @ts-ignore
    delete clonedShipment.collection_address.hash;
    // @ts-ignore
    delete clonedShipment.collection_address.time_modified;
    // @ts-ignore
    delete clonedShipment.collection_address.time_created;
    // @ts-ignore
    delete clonedShipment.collection_address.status;

    // @ts-ignore
    delete clonedShipment.delivery_address.id;
    delete clonedShipment.delivery_address.gs_hash;
    // @ts-ignore
    delete clonedShipment.delivery_address.hash;
    // @ts-ignore
    delete clonedShipment.delivery_address.time_modified;
    // @ts-ignore
    delete clonedShipment.delivery_address.time_created;
    // @ts-ignore
    delete clonedShipment.delivery_address.status;
  }

  const deliveryContactDetails = {
    delivery_contact_name: clonedShipment.delivery_contact_name,
    delivery_contact_email: clonedShipment.delivery_contact_email,
    delivery_contact_mobile_number: clonedShipment.delivery_contact_mobile_number
  };

  const collectionContactDetails = {
    collection_contact_name: clonedShipment.collection_contact_name,
    collection_contact_email: clonedShipment.collection_contact_email,
    collection_contact_mobile_number: clonedShipment.collection_contact_mobile_number
  };

  setShipment({
    ...originalShipment,
    ...deliveryContactDetails,
    ...collectionContactDetails,
    collection_address: clonedShipment.collection_address,
    delivery_address: clonedShipment.delivery_address,
    instructions_delivery: clonedShipment?.instructions_delivery,
    instructions_collection: clonedShipment?.instructions_collection
  });

  return clonedShipment;
}

function insertUrlParam(key: any, value: any, history?: any, location?: any) {
  if (window.history.pushState) {
    let searchParams = new URLSearchParams(window.location.search);
    searchParams.set(key, value);
    if (history && location) {
      history.push({
        pathname: location.pathname,
        search: searchParams.toString()
      });
    } else {
      let newurl =
        window.location.protocol +
        "//" +
        window.location.host +
        window.location.pathname +
        "?" +
        searchParams.toString();
      window.history.pushState({ path: newurl }, "", newurl);
    }
  }
}

function removeUrlParam(key: any) {
  let searchParams = new URLSearchParams(window.location.search);
  searchParams.delete(key);

  let newUrl =
    window.location.protocol +
    "//" +
    window.location.host +
    window.location.pathname +
    "?" +
    searchParams.toString();
  window.history.pushState({ path: newUrl }, "", newUrl);
}

function generateArchivedLabel(key: string): string {
  if (key === "true") {
    return "Show archived orders";
  }
  return "Hide archived orders";
}

function checkPublicHolidayUniqueness(items: any[]) {
  let isUnique = true;
  items.forEach(item => {
    item.duplicate = false;
  });

  items.forEach(item => {
    let duplicates = items.filter(
      itemToFind => itemToFind.id !== item.id && itemToFind.date === item.date
    );

    duplicates.forEach(duplicate => {
      duplicate.duplicate = true;
      isUnique = false;
    });
  });

  return isUnique;
}

const displayAddress = (address: any) => {
  return (
    <>
      {address.split(", ").map((item: any, index: number) => (
        <p key={index}>{item}</p>
      ))}
    </>
  );
};

const getSignedUrl = async (
  file: any,
  store: IStore,
  folder: string,
  bucket: string,
  accountId?: any
): Promise<any> => {
  let s3Res: string = "";
  let urlError: any = null;
  let account_id: any = "";
  if (accountId) {
    account_id = accountId;
  } else {
    account_id = store.account.id;
  }
  if (file) {
    const fileHash = uuidv4();
    const fileName =
      fileHash +
      "-" +
      file.name
        .replaceAll(" ", "")
        .replace(/\([^)]*\)|, /g, "")
        .replace(/\[[^)]*]|, /g, "") // add [ ] to the stripping,
        .replace(/[^a-zA-Z.]/g, ""); // all special characters
    const result = await signedRequest(store, "/s3-url/upload", "GET", {
      file_name: `/${account_id}/${fileName}`,
      folder: folder,
      bucket: bucket
    });
    if (result.ok) {
      s3Res = result.data;
    }
    if (!result.ok) {
      urlError = generalUtils.getError(result, true);
    }
  }

  return { s3Res, urlError };
};

function displayString(str: any) {
  if (str && str.length > 0) {
    return str;
  }
  return "–";
}

function inputValueToNumber(value: any) {
  if (value === "" || value === undefined || value === null) {
    value = null;
  } else {
    value = Number(value);
  }
  return value;
}

async function checkTime(store: IStore, message?: string) {
  try {
    let response = await signedRequest(store, "/time", "GET");
    if (response.ok && response.data) {
      let serverTime = response.data;
      let now = new Date();

      var duration = moment.duration(moment(now).diff(serverTime));
      var minutes = duration.asMinutes();

      if (Math.abs(minutes) > 5) {
        store.emitter.emit("showAlert", {
          title: "Are you a time traveller?",
          body: (
            <div>
              <div className="flex justify-center">
                <FontAwesomeIcon icon="clock" className="text-primary mb-8 mt-4" size={"4x"} />
              </div>
              <div className="mb-4">
                {message ?? "Your time is out of sync with our server time"}.
              </div>

              <div>
                Our current server time is{" "}
                <span className="font-bold">
                  {moment(serverTime).utcOffset("+0200").format("YYYY-MM-DD HH:mm Z")}
                </span>
                , please set your date and time to{" "}
                <span className="font-bold text-primary inline-flex">
                  {moment(serverTime).format("YYYY-MM-DD HH:mm Z")}
                  <InfoButton>
                    <div className="mb-4">
                      This is your local date and time that correspond to our server time. If this
                      time seems incorrect, please ensure that the correct timezone is set for your
                      device.
                    </div>
                  </InfoButton>
                </span>
              </div>
            </div>
          ),
          showOkButton: true,
          okButtonText: "Ok",
          okButtonVariant: "primary",
          showCancelButton: false,
          return: () => {}
        });
        return { ok: false };
      } else {
        return { ok: true };
      }
    } else {
      return { e: response.error };
    }
  } catch (e) {
    return { e };
  }
}

function checkBrowserVersion(store: IStore) {
  try {
    const browser = detect();

    let browserDetails = { name: browser.name, version: browser.version };
    let activeUser = activeUserFromStore(store);
    let userSettings = store.get("user_settings");
    let ignoreVersion = activeUser
      ? userSettings.ignore_browser_outdated
      : localStorage.getItem("ignore_browser_outdated");

    if (
      generalUtils.isBrowserOutdated(browserDetails.name, browserDetails.version) &&
      !ignoreVersion
    ) {
      store.emitter.emit("showAlert", {
        title: "Your browser is out of date",
        body: (
          <div>
            <div className="flex justify-center">
              <FontAwesomeIcon
                icon={generalUtils.getBrowserIcon(browserDetails.name)}
                className="text-primary mb-8 mt-4"
                size={"4x"}
              />
            </div>

            <div className="flex flex-col space-y-4">
              <div>
                It seems like your browser is out of date. Some features might not work as expected.
              </div>
              <div>In order to have the best experience, please update your browser.</div>
            </div>
          </div>
        ),
        showCancelButton: true,
        showOkButton: true,
        okButtonText: "Don't remind me again",
        cancelButtonText: "Ok",
        okButtonVariant: "danger",
        return: (val: any) => {
          if (val) {
            if (activeUser) {
              setUserSetting(store, "ignore_browser_outdated", "true");
            } else {
              localStorage.setItem("ignore_browser_outdated", "true");
            }
          }
        }
      });
    }
  } catch (e) {
    console.log("checkBrowserVersion: ", e);
  }
}

const ifDev = (val: string) => {
  let isDev = Boolean(
    config.api === "api.dev.ship.uafrica.com" || config.api === "api.dev.bobgo.co.za"
  );

  if (isDev) {
    return val;
  }
  return undefined;
};

const isDev = (): boolean => {
  return config.api === "api.dev.ship.uafrica.com" || config.api === "api.dev.bobgo.co.za";
};

function mergeArrays(arr1: any[], arr2: any[], val: string) {
  return arr1 && arr1.map(obj => (arr2 && arr2.find(p => p[val] === obj[val])) || obj);
}

function kebabCaseToSentenceCase(originalString: string) {
  let formattedString = originalString.replaceAll("-", " ").replaceAll("_", " ");
  formattedString = generalUtils.capitalize(formattedString.toLowerCase());

  return formattedString;
}

function confirmCloseModal(
  store: IStore,
  closeFunction: any,
  closeValidation?: boolean,
  modalTitle?: string,
  bodyContent?: string,
  okButtonText?: string,
  showOkButton?: boolean,
  okButtonVariant?: string,
  showCancelButton?: boolean,
  cancelButtonText?: string
) {
  if (closeValidation) {
    store.emitter.emit("showAlert", {
      title: modalTitle ?? "Are you sure you want to leave?",
      body: bodyContent ?? "There are unsaved changes. Are you sure you want to leave?",
      showOkButton: showOkButton ?? true,
      okButtonText: okButtonText ?? "Yes, I want to leave",
      okButtonVariant: okButtonVariant ?? "danger",
      showCancelButton: showCancelButton ?? true,
      cancelButtonText: cancelButtonText ?? "Cancel",
      return: (confirm: boolean) => {
        if (confirm) {
          closeFunction();
        }
      }
    });
  } else {
    closeFunction();
  }
}

function indefiniteArticle(word: string): string {
  if (["a", "e", "i", "o", "u"].includes(word[0].toLowerCase())) {
    return "an";
  } else {
    return "a";
  }
}

/* Replace any occurrence of a lowercase letter followed by an uppercase letter with a hyphen (-) between the two letters
 * Replace any whitespace characters with a hyphen
 * Convert the whole string to lowercase.
 */
function formatToKebabCase(str: string | unknown): string {
  if (typeof str !== "string" || !str) {
    return "";
  }

  return str
    .replace(/([a-z])([A-Z])/g, "$1-$2")
    .replace(/\s+/g, "-")
    .toLowerCase();
}

function stopImpersonating(store: IStore) {
  if (store.impersonated_user_id) {
    localStorage.removeItem("impersonated_user_id");
    window.location.href = `/accounts/${store.account.id}`;
  }
}

export {
  stopImpersonating,
  checkTime,
  isMaintenanceModePage,
  isAccountClosedPage,
  updateAccount,
  getAccount,
  getDocIDLink,
  getExceptionItems,
  generateAndOpenS3Resource,
  getBalanceClassNames,
  paymentNumber,
  creditNoteNumber,
  invoiceNumber,
  creditNoteUaDoNumber,
  clone,
  addFiltersToArgs,
  activeUser,
  activeUserFromStore,
  addressObjFromGoogleResult,
  searchForShipments,
  getGeneralData,
  getStatusClassNames,
  getStatusAssociatedColor,
  getInvoiceStatusClassNames,
  getPaymentStatusClassNames,
  getChannelStatusClassNames,
  signedRequest,
  getSystemConfig,
  getAccountSettings,
  getUserSettings,
  setUserSetting,
  keyToHumanReadableLower,
  surchargeOptions,
  isAccountUser,
  formatShippingStatus,
  requestTypes,
  requestMethod,
  statusCode,
  shipmentsPageFilters,
  updateInvoiceItem,
  generateFormattedAddress,
  formatPhoneNumber,
  getOrderPaymentStatusClassNames,
  getOrderStatusClassNames,
  getFulfillmentStatusSmallClassNames,
  cleanEmailLoginPage,
  cleanupShipmentForDuplication,
  getApiTypeClassNames,
  getHttpMethodClassNames,
  getResponseCodeClassNames,
  getCommunicationLogStatusClassNames,
  getCreditAllocationClassName,
  getAccountNoteTypeClassNames,
  getAccountPackages,
  generateQuickFulfillPayload,
  generateParcelDimensions,
  getAccountProviders,
  getChannels,
  getOrder,
  getBillingInfo,
  getProviders,
  getAccountPlan,
  getFulfillmentStatusClassNames,
  insertUrlParam,
  removeUrlParam,
  addFiltersToArgsCheck,
  checkPublicHolidayUniqueness,
  generateAddressPayload,
  getPaymentMethodSmallClassNames,
  generateArchivedLabel,
  displayAddress,
  getSignedUrl,
  IsJsonString,
  displayString,
  inputValueToNumber,
  checkBrowserVersion,
  archivedInvoiceNumber,
  archivedCreditNoteNumber,
  getUnmatchedWaybillStatusClassName,
  getAccountActivityTypeClassNames,
  getProviderCreditNotesClasses,
  addAddressBook,
  validateChannel,
  ifDev,
  isDev,
  getDocProviderDocLink,
  getWebhookStatusClassNames,
  mergeArrays,
  pgFormatDate,
  kebabCaseToSentenceCase,
  getUserSetting,
  getAccountSetting,
  updateAccountSetting,
  getUserOnboardingSettings,
  confirmCloseModal,
  indefiniteArticle,
  formatToKebabCase
};
