import { IResult } from "interfaces/result.interface";
import * as utils from "utils/utils";
import { IStore } from "interfaces/store.interface";
import { IOrder } from "interfaces/order.interface";
import { useState } from "react";
import { generalUtils, useStore } from "uafrica-ui-framework";

export const useSinglePackingDownload = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [downloading, setDownloading] = useState<boolean>(false);

  const downloadPackingSlip = async (order: IOrder, store: IStore) => {
    setIsLoading(true);
    setDownloading(true);
    try {
      const res: IResult = await utils.signedRequest(store, "/orders/packing-slips", "GET", {
        account_id: order.account_id,
        order_id: order.id
      });
      if (res.ok) {
        const tempLink = document.createElement("a");
        // const filename = result.data.;
        tempLink.style.display = "none";
        tempLink.href = res.data.download_url;
        tempLink.setAttribute("target", "_blank");
        // tempLink.setAttribute("download", filename);
        tempLink.setAttribute("rel", "noopener noreferrer");
        document.body.appendChild(tempLink);
        tempLink.click();
        document.body.removeChild(tempLink);
      }
      if (!res.ok) {
        store.emitter.emit("showToast", {
          text: generalUtils.getError(res, true),
          variant: "error"
        });
      }
    } catch (e) {
      store.emitter.emit("showToast", {
        text: generalUtils.getError(e, true),
        variant: "error"
      });
    }
    setIsLoading(false);
    setDownloading(false);
  };

  return { isLoading, downloading, downloadPackingSlip };
};

export const usePackingDownload = () => {
  const store: IStore = useStore();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const pollHistory = async (historyId: number) => {
    const maxRetries = 20;
    let retryCount = 1;
    const dowHistoryDownload = async () => {
      try {
        const res: IResult = await utils.signedRequest(store, "/file-history", "GET", {
          id: historyId
        });
        if (res.ok) {
          if (
            res.data &&
            res.data.file_history &&
            res.data.file_history[0].file_status === "success"
          ) {
            const tempLink = document.createElement("a");
            // const filename = result.data.;
            tempLink.style.display = "none";
            tempLink.href = res.data.file_history[0].file_url;
            tempLink.setAttribute("target", "_blank");
            // tempLink.setAttribute("download", filename);
            tempLink.setAttribute("rel", "noopener noreferrer");
            document.body.appendChild(tempLink);
            tempLink.click();
            document.body.removeChild(tempLink);
            setIsLoading(false);
            return;
          }
          if (
            res.data &&
            res.data.file_history &&
            res.data.file_history[0].file_status === "failed"
          ) {
            store.emitter.emit("showToast", {
              text: `${generalUtils.capitalize(
                generalUtils.getError(res.data.file_history[0].failed_reason, true)
              )}`,
              variant: "error"
            });
            setIsLoading(false);
            return;
          }
          retryDownload();
          retryCount++;
        }
        if (!res.ok) {
          store.emitter.emit("showToast", {
            text: `${generalUtils.capitalize(generalUtils.getError(res, true))}`,
            variant: "error",
            autoHide: 5000
          });
          setIsLoading(false);
        }
      } catch (e) {
        store.emitter.emit("showToast", {
          text: `${generalUtils.capitalize(generalUtils.getError(e, true))}`,
          variant: "error",
          autoHide: 5000
        });
        setIsLoading(false);
      }
    };
    const retryDownload = () => {
      if (retryCount >= maxRetries + 1) {
        setIsLoading(false);
        store.emitter.emit("showToast", {
          text: `All packing slips are not available right now, please try again later`,
          variant: "error",
          autoHide: 5000
        });

        return;
      }
      if (retryCount === 3) {
        store.emitter.emit("showToast", {
          text: `Please be patient while we generate the packing slips.`,
          variant: "info",
          autoHide: 10000
        });
      }

      if (retryCount <= maxRetries) {
        let time = 10000;
        switch (retryCount) {
          case 1:
            time = 500;
            break;
          case 2:
          case 3:
          case 4:
            time = 1000;
            break;
          case 6:
          case 7:
            time = 2000;
            break;
          case 8:
            time = 5000;
        }
        setTimeout(() => {
          dowHistoryDownload();
        }, time);
      }
    };
    dowHistoryDownload();
  };

  const downloadBulkPackingSlip = async (
    orders: number[],
    accountId: number | undefined,
    store: IStore
  ) => {
    setIsLoading(true);
    if (!accountId) {
      return;
    }
    try {
      const res: IResult = await utils.signedRequest(store, "/orders/packing-slips", "GET", {
        account_id: accountId,
        order_ids: orders
      });
      if (res.ok) {
        if (res.data.pdf_queued) {
          pollHistory(res.data.file_history_id);
          return;
        }
        const tempLink = document.createElement("a");
        // const filename = result.data.;
        tempLink.style.display = "none";
        tempLink.href = res.data.download_url;
        tempLink.setAttribute("target", "_blank");
        // tempLink.setAttribute("download", filename);
        tempLink.setAttribute("rel", "noopener noreferrer");
        document.body.appendChild(tempLink);
        tempLink.click();
        document.body.removeChild(tempLink);
      }
      if (!res.ok) {
        store.emitter.emit("showToast", {
          text: generalUtils.getError(res, true),
          variant: "error"
        });
        setIsLoading(false);
      }
    } catch (e) {
      store.emitter.emit("showToast", {
        text: generalUtils.getError(e, true),
        variant: "error"
      });
      setIsLoading(false);
    }
    setIsLoading(false);
  };

  return { isLoading, downloadBulkPackingSlip };
};

export const useDownloadWaybill = () => {
  const [downloading, setDownloading] = useState<boolean>(false);
  const [submissionStatus, setSubmissionStatus] = useState<string>("");

  const pollDownload = async (
    trackingRef: any,
    store: IStore,
    size?: string,
    shouldNotDownload?: boolean | undefined
  ) => {
    const maxRetries = 10;
    let retryCount = 1;

    const downloadWaybill = async (trackingRef: any, store: IStore, size?: string) => {
      if (trackingRef) {
        let formData: any = {
          waybill_size: size ?? "",
          tracking_references: [trackingRef]
        };
        setDownloading(true);
        try {
          const res: IResult = await utils.signedRequest(
            store,
            "/shipments/waybill",
            "GET",
            formData
          );
          if (res.ok) {
            if (
              res.data.shipments.every((shipment: any) => shipment.submission_status === "success")
            ) {
              if (!shouldNotDownload) {
                const tempLink = document.createElement("a");
                tempLink.style.display = "none";
                tempLink.href = res.data.waybill_download_url;
                tempLink.setAttribute("target", "_blank");
                tempLink.setAttribute("rel", "noopener noreferrer");
                document.body.appendChild(tempLink);
                tempLink.click();
                document.body.removeChild(tempLink);
                setSubmissionStatus("success");
                setDownloading(false);
                return;
              } else {
                setSubmissionStatus("success");
                setDownloading(false);
              }
            }
            if (
              res.data.shipments.some(
                (shipment: any) =>
                  shipment.submission_status === "failed-indefinitely" ||
                  shipment.submission_status === "no-rates"
              )
            ) {
              store.emitter.emit("showToast", {
                text: `One or more shipment failed to submit to the courier`,
                variant: "error",
                autoHide: 5000
              });
              setDownloading(false);
              setSubmissionStatus(res.data.shipments[0].submission_status);
              return;
            }
            retryDownload();
            retryCount++;
            setSubmissionStatus(res.data.shipments[0].submission_status);
          }
          if (!res.ok) {
            store.emitter.emit("showToast", {
              text: `${generalUtils.getError(res, true)}`,
              variant: "error",
              autoHide: 5000
            });
          }
        } catch (e) {
          console.error(e);
        }

        setDownloading(false);
      }
    };
    const retryDownload = () => {
      if (retryCount >= maxRetries + 1) {
        store.emitter.emit("showToast", {
          text: `Waybill not available right now, please try again later`,
          variant: "error",
          autoHide: 5000
        });
        setSubmissionStatus("max-out");
        setDownloading(false);
        return;
      }
      if (retryCount <= maxRetries) {
        let time = 2000;
        switch (retryCount) {
          case 1:
          case 2:
          case 3:
          case 4:
          case 5:
            time = 500;
            break;
          case 6:
          case 7:
            time = 1000;
            break;
          case 8:
            time = 2000;
        }
        setTimeout(() => {
          downloadWaybill(trackingRef, store, size);
        }, time);
      }
    };
    downloadWaybill(trackingRef, store, size);
  };

  return { downloading, pollDownload, submissionStatus };
};

// only fail and exit when submission status is failed-indefinitely or no-rates
// download when success
// keep polling for other statuses

export const useBulkWaybillDownloads = () => {
  const store: IStore = useStore();
  const [downloading, setDownloading] = useState<boolean>(false);
  const [status, setStatus] = useState<string>("");
  const [submissionStatus, setSubmissionStatus] = useState<string>("");
  const [failedShipments, setFailedShipments] = useState<string[]>([]);
  const [failedReason, setFailedReason] = useState<string>("");
  const [maxedOut, setMaxedOut] = useState<boolean>(false);

  const pollHistory = async (historyId: number) => {
    const maxRetries = 20;
    let retryCount = 1;
    const dowHistoryDownload = async () => {
      try {
        const res: IResult = await utils.signedRequest(store, "/file-history", "GET", {
          id: historyId
        });
        if (res.ok) {
          if (
            res.data &&
            res.data.file_history &&
            res.data.file_history[0].file_status === "success"
          ) {
            const tempLink = document.createElement("a");
            // const filename = result.data.;
            tempLink.style.display = "none";
            tempLink.href = res.data.file_history[0].file_url;
            tempLink.setAttribute("target", "_blank");
            // tempLink.setAttribute("download", filename);
            tempLink.setAttribute("rel", "noopener noreferrer");
            document.body.appendChild(tempLink);
            tempLink.click();
            document.body.removeChild(tempLink);
            setDownloading(false);
            setStatus("success");
            return;
          }
          if (
            res.data &&
            res.data.file_history &&
            res.data.file_history[0].file_status === "failed"
          ) {
            store.emitter.emit("showToast", {
              text: `${generalUtils.capitalize(
                generalUtils.getError(res.data.file_history[0].failed_reason, true)
              )}`,
              variant: "error"
            });
            setDownloading(false);
            return;
          }
          retryDownload();
          retryCount++;
        }
        if (!res.ok) {
          store.emitter.emit("showToast", {
            text: `${generalUtils.capitalize(generalUtils.getError(res, true))}`,
            variant: "error",
            autoHide: 5000
          });
          setDownloading(false);
        }
      } catch (e) {
        store.emitter.emit("showToast", {
          text: `${generalUtils.capitalize(generalUtils.getError(e, true))}`,
          variant: "error",
          autoHide: 5000
        });
        setDownloading(false);
      }
    };
    const retryDownload = () => {
      if (retryCount >= maxRetries + 1) {
        setMaxedOut(true);
        setDownloading(false);
        store.emitter.emit("showToast", {
          text: `All waybills are not available right now, please try again later`,
          variant: "error",
          autoHide: 5000
        });

        return;
      }
      if (retryCount === 3) {
        store.emitter.emit("showToast", {
          text: `Please be patient while we generate the waybills.`,
          variant: "info",
          autoHide: 10000
        });
      }

      if (retryCount <= maxRetries) {
        let time = 10000;
        switch (retryCount) {
          case 1:
            time = 500;
            break;
          case 2:
          case 3:
          case 4:
            time = 1000;
            break;
          case 6:
          case 7:
            time = 2000;
            break;
          case 8:
            time = 5000;
        }
        setTimeout(() => {
          dowHistoryDownload();
        }, time);
      }
    };
    dowHistoryDownload();
  };

  const pollWaybills = (
    shipmentIds: any[],
    store: IStore,
    size?: string,
    shouldNotDownload?: boolean | undefined
  ) => {
    const maxRetries = 10;
    let retryCount = 1;

    const downloadBulkWaybills = async (shipmentIds: any[], store: IStore, size?: string) => {
      let shipmentsTrackingReferences = shipmentIds;
      let formData: any = {
        waybill_size: size ?? "",
        tracking_references: shipmentsTrackingReferences
      };
      setDownloading(true);
      try {
        const res: IResult = await utils.signedRequest(
          store,
          "/shipments/waybill",
          "GET",
          formData
        );
        if (res.ok && res.data.shipments?.length > 0) {
          if (res.data.pdf_queued) {
            pollHistory(res.data.file_history_id);
            return;
          }
          if (
            res.data.shipments.every((shipment: any) => shipment.submission_status === "success")
          ) {
            if (!shouldNotDownload) {
              const tempLink = document.createElement("a");
              // const filename = result.data.;
              tempLink.style.display = "none";
              tempLink.href = res.data.download_url;
              tempLink.setAttribute("target", "_blank");
              // tempLink.setAttribute("download", filename);
              tempLink.setAttribute("rel", "noopener noreferrer");
              document.body.appendChild(tempLink);
              tempLink.click();
              document.body.removeChild(tempLink);
              setDownloading(false);
              setStatus("success");
              return;
            } else {
              setDownloading(false);
              setStatus("success");
              return;
            }
          }
          if (
            res.data.shipments.some(
              (shipment: any) =>
                shipment.submission_status === "failed-indefinitely" ||
                shipment.submission_status === "no-rates"
            )
          ) {
            store.emitter.emit("showToast", {
              text: `One or more shipment failed to submit to the courier`,
              variant: "error",
              autoHide: 5000
            });
            let failedShipments = res.data.shipments
              .filter(
                (shipment: any) =>
                  shipment.submission_status === "failed-indefinitely" ||
                  shipment.submission_status === "no-rates"
              )
              .map((shipment: any) => shipment.tracking_reference);

            setFailedShipments(failedShipments);
            setDownloading(false);
            setSubmissionStatus(res.data.shipments[0].submission_status);
            setFailedReason(res.data.shipments[0].failed_reason);
            setStatus("failed");

            return;
          }
          retryDownload();
          retryCount++;
          setStatus("pending");
        }
        if (!res.ok) {
          store.emitter.emit("showToast", {
            text: `${generalUtils.getError(res, true)}`,
            variant: "error",
            autoHide: 5000
          });
          setStatus("error");
          setDownloading(false);
          return;
        }
      } catch (e) {
        store.emitter.emit("showToast", {
          text: `${generalUtils.getError(e, true)}`,
          variant: "error",
          autoHide: 5000
        });
        setStatus("failed");
        setDownloading(false);
        return;
      }
    };
    const retryDownload = () => {
      if (retryCount >= maxRetries + 1) {
        setMaxedOut(true);
        setDownloading(false);
        store.emitter.emit("showToast", {
          text: `All waybills are not available right now, please try again later`,
          variant: "error",
          autoHide: 5000
        });

        return;
      }
      if (retryCount <= maxRetries) {
        let time = 2000;
        switch (retryCount) {
          case 1:
          case 2:
          case 3:
          case 4:
          case 5:
            time = 500;
            break;
          case 6:
          case 7:
            time = 1000;
            break;
          case 8:
            time = 2000;
        }
        setTimeout(() => {
          downloadBulkWaybills(shipmentIds, store, size);
        }, time);
      }
    };
    downloadBulkWaybills(shipmentIds, store, size);
  };

  return {
    downloading,
    pollWaybills,
    status,
    failedShipments,
    submissionStatus,
    failedReason,
    maxedOut
  };
};
