import * as utils from "utils/utils";
import { IUser } from "interfaces/user.interface";
import { IStore } from "interfaces/store.interface";
import { cacheUtils, roleUtils } from "uafrica-ui-framework";

const ROLE_PUBLIC = "Public";
const ROLE_ACCOUNT_USER = "AccountUser";
const ROLE_ACCOUNT_SUPER_USER = "AccountSuperUser";
const ROLE_SUPER_ADMIN = "SuperAdmin";
const ROLE_BILLING_ADMIN = "BillingAdmin";
const ROLE_OPS_ADMIN = "Ops";

const searchBarPermissions = [
  "API_/shipments:GET",
  "API_/accounts:GET",
  "API_/invoices:GET",
  "API_/credit-notes:GET"
];

const accountSpecificRoles = [
  {
    label: "Account User",
    value: 4
  },
  {
    label: "Account Superuser",
    value: 3
  }
];

function isAccountSuperUser(activeUser: IUser | undefined): boolean {
  return Boolean(
    activeUser && activeUser.account_id && roleUtils.hasRole(activeUser, [ROLE_ACCOUNT_SUPER_USER])
  );
}

function isAccountUser(activeUser: IUser | undefined): boolean {
  return Boolean(
    activeUser && activeUser.account_id && roleUtils.hasRole(activeUser, [ROLE_ACCOUNT_USER])
  );
}

function allStaffRoles(roles: any[], activeUser: IUser | undefined): any[] {
  let filteredRoles = roles.filter(
    role =>
      role.name !== ROLE_ACCOUNT_USER &&
      role.name !== ROLE_ACCOUNT_SUPER_USER &&
      role.name !== ROLE_PUBLIC
  );

  if (!isSuperAdmin(activeUser)) {
    filteredRoles = filteredRoles.filter(role => role.value !== ROLE_SUPER_ADMIN);
  }

  return filteredRoles;
}

function isStaff(activeUser: IUser | undefined): boolean {
  return (
    Boolean(activeUser && !activeUser.account_id) && !roleUtils.hasRole(activeUser, [ROLE_PUBLIC])
  );
}

function isSuperAdmin(activeUser: IUser | undefined): boolean {
  return Boolean(activeUser && roleUtils.hasRole(activeUser, [ROLE_SUPER_ADMIN]));
}

function isBillingUser(activeUser: IUser | undefined): boolean {
  return Boolean(activeUser && roleUtils.hasRole(activeUser, [ROLE_BILLING_ADMIN]));
}

function isOpsUser(activeUser: IUser | undefined): boolean {
  return Boolean(activeUser && roleUtils.hasRole(activeUser, [ROLE_OPS_ADMIN]));
}

function displayStaffOnly(store: IStore) {
  return Boolean(
    store.logged_in_user && isStaff(store.logged_in_user) && !store.impersonated_user_id
  );
}

function isAccountUserOrSuper(activeUser: IUser | undefined): boolean {
  return Boolean(
    activeUser &&
      activeUser.account_id &&
      roleUtils.hasRole(activeUser, [ROLE_ACCOUNT_USER, ROLE_ACCOUNT_SUPER_USER])
  );
}

function userHasAdminSystemAccess(props: { store: IStore }) {
  const { impersonated_user_id } = props.store;
  const activeUser = utils.activeUserFromStore(props.store);
  return !impersonated_user_id && activeUser && !activeUser.account_id && isStaff(activeUser);
}

function userHasUiPermission(user: IUser | undefined, uiPermission: string) {
  if (user) {
    if (user?.role?.permissions_ui) {
      if (user.role?.permissions_ui.indexOf(uiPermission) >= 0) {
        return true;
      }
    }
  }

  return false;
}

function getRolesFromStore(props: any, includeStaffRoles: boolean, includeAccountRoles: boolean) {
  let roles: any = [];

  if (includeAccountRoles) {
    roles = accountSpecificRoles.map((role: any) => ({
      ...role
    }));
  }

  if (includeStaffRoles) {
    let staffRoles = allStaffRoles(
      cacheUtils.getFromStore(props.store, "roles"),
      utils.activeUser(props)
    );

    roles = roles.concat(
      staffRoles.map(role => ({
        ...role
      }))
    );
  }

  return roles;
}

export {
  allStaffRoles,
  userHasAdminSystemAccess,
  isAccountSuperUser,
  isAccountUser,
  isAccountUserOrSuper,
  isSuperAdmin,
  isStaff,
  isBillingUser,
  isOpsUser,
  displayStaffOnly,
  userHasUiPermission,
  getRolesFromStore,
  accountSpecificRoles,
  searchBarPermissions,
  ROLE_PUBLIC,
  ROLE_ACCOUNT_SUPER_USER,
  ROLE_ACCOUNT_USER,
  ROLE_SUPER_ADMIN,
  ROLE_BILLING_ADMIN,
  ROLE_OPS_ADMIN
};
