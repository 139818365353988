import { IAccountAlert } from "interfaces/accountAlert.interface";
import * as utils from "utils/utils";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { Button, dateUtils, useStore } from "uafrica-ui-framework";
import { IResult } from "interfaces/result.interface";
import { useEffect, useState } from "react";
import { IStore } from "interfaces/store.interface";

function AccountAlert(props: {
  alert: IAccountAlert;
  setAlerts?: Function;
  showMarkAsRead?: boolean;
}) {
  const history: any = useHistory();
  const store: IStore = useStore();
  const [alert, setAlert] = useState<IAccountAlert>(props.alert);

  const alertDate = moment(new Date(alert.time_created), "MM/D/YYYY");
  const now = moment(new Date(), "MM/D/YYYY");

  useEffect(() => {
    setAlert(props.alert);
  }, [props.alert]);

  function doAlertAction() {
    if (alert.action.method === "navigate") {
      history.push(alert.action.path);
    }
  }

  async function onClickAlert() {
    if (!alert.read) {
      let result: IResult = await utils.signedRequest(store, "/accounts/alerts", "PATCH", {
        id: alert.id
      });

      if (result.ok) {
        store.emitter.emit("reloadUnreadAlerts");
        doAlertAction();
        setAlert({ ...alert, read: true });
      }
    } else {
      doAlertAction();
    }
  }

  async function onReadAlert() {
    const result: IResult = await utils.signedRequest(store, "/accounts/alerts", "PATCH", {
      id: alert.id
    });

    if (result.ok) {
      store.emitter.emit("reloadUnreadAlerts");
      props.setAlerts && props.setAlerts(alert);
      setAlert({ ...alert, read: true });
    }
  }

  return (
    <>
      <div
        className={"p-4 hover:bg-gray-100 cursor-pointer " + (alert.read ? "bg-gray-50" : "")}
        onClick={onClickAlert}
      >
        <div className="flex justify-between mb-4">
          <div className={alert.read ? "" : "font-bold"}>{alert.subject}</div>
          <div className={alert.read ? "" : "font-bold"}>
            {!alert.read && props.showMarkAsRead && (
              <Button.Link
                icon="envelope-open"
                hoverTitle="Mark as read"
                onClick={(e: any) => {
                  e.stopPropagation();
                  onReadAlert();
                }}
                className={"mark-as-read-envelope"}
              />
            )}
            {alertDate.isSame(now, "day")
              ? dateUtils.humanFormatDateTimeToFormat(alert.time_created, "HH:mm")
              : dateUtils.humanFormatDate(alert.time_created)}
          </div>
        </div>
        <div>{alert.message}</div>
      </div>
      <hr />
    </>
  );
}

export default AccountAlert;
