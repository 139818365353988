import { Suspense } from "react";
import { Switch } from "react-router-dom";
import { lazy } from "@loadable/component";
import * as utils from "utils/utils";
import * as roleUtils from "utils/roleUtils";
import { Loader } from "uafrica-ui-framework";
import AlertsPage from "pages/alerts/AccountAlertsPage";
import { IStore } from "interfaces/store.interface";

// @ts-ignore
const AuthenticatedRoutePage = lazy(() => import("pages/auth/AuthenticatedRoute"));
const UnauthenticatedRoutePage = lazy(() => import("pages/auth/UnauthenticatedRoutePage"));
const NotFoundPage = lazy(() => import("pages/auth/NotFoundPage"));
const LoginPage = lazy(() => import("pages/auth/LoginPage"));
const LogoutPage = lazy(() => import("pages/auth/LogoutPage"));
const ShipmentsParentPage = lazy(() => import("pages/shipments/ShipmentsParentPage"));
const ShipmentPage = lazy(() => import("pages/shipments/ShipmentPage"));
const CreateShipmentPage = lazy(() => import("pages/shipments/CreateShipmentPage"));
const UsersPage = lazy(() => import("pages/users/UsersPage"));
const ProfilePage = lazy(() => import("pages/profile/ProfilePage"));
const UserDetailsPage = lazy(() => import("pages/users/UserDetailsPage"));
const AccountPage = lazy(() => import("pages/accounts/AccountPage"));

const AccountSignupPage = lazy(() => import("pages/accounts/AccountSignupPage"));
const AccountSignupSuccessPage = lazy(() => import("pages/accounts/AccountSignupSuccessPage"));
const AddressBookPage = lazy(() => import("pages/addressBook/AddressBookPage"));

const ApiLogsPage = lazy(() => import("pages/logs/LogsPage"));
const AdminDashboardPage = lazy(() => import("pages/home/AdminDashboardPage"));
const AccountDashboardPage = lazy(() => import("pages/home/AccountsDashboardPage"));
const OnboardingDashboard = lazy(() => import("components/onboarding/OnboardingHello"));
const AdminHomePage = lazy(() => import("pages/home/AdminHomePage"));
const AccountsHomePage = lazy(() => import("pages/home/AccountsHomePage"));

// @ts-ignore
const BillingPage = lazy(() => import("pages/billing/BillingPage"));
const BillingExceptionsPage = lazy(() => import("pages/billing/BillingExceptionsPage"));
const BillingProviderCreditNotesPage = lazy(
  () => import("pages/billing/BillingProviderCreditNotesPage")
);
// @ts-ignore
const AccountsPage = lazy(() => import("pages/accounts/AccountsPage"));
const AccountsMarkupFormulaPage = lazy(() => import("pages/accounts/AccountMarkupFormulaPage"));
const AccountInvoicePage = lazy(() => import("pages/accounts/InvoicePage"));
const ArchivedCreditNoteDetailsPage = lazy(() => import("pages/accounts/ArchivedCreditNotePage"));
const ArchivedInvoiceDetailsPage = lazy(() => import("pages/accounts/ArchivedInvoicePage"));
const CreditNoteDetailsPage = lazy(() => import("pages/accounts/CreditNotePage"));
const ReportsPage = lazy(() => import("pages/reports/ReportsPage"));
const AccountReportsPage = lazy(() => import("pages/reports/AccountReportPage"));
const ProvidersPage = lazy(() => import("pages/providers/ProvidersPage"));
const ProviderPage = lazy(() => import("components/providers/Provider"));

const PaymentConfirmationPage = lazy(
  () => import("pages/paymentConfirmation/PaymentConfirmationPage")
);
const PaymentCancelledPage = lazy(() => import("pages/paymentConfirmation/PaymentCancelled"));

// @ts-ignore
const RolesPage = lazy(() => import("pages/users/RolesPage"));
const RolePage = lazy(() => import("pages/users/RolePage"));

const SystemConfigPage = lazy(() => import("pages/settings/SystemConfigPage"));
const AccountSettingsPage = lazy(() => import("pages/settings/AccountSettingsPage"));

const OrdersIndexPage = lazy(() => import("pages/orders/OrdersIndexPage"));
const AdminOrderIndexPage = lazy(() => import("pages/orders/AdminOrdersIndex"));
const OrderDetailsPage = lazy(() => import("pages/orders/OrderDetailsPage"));
const CreateOrderPage = lazy(() => import("pages/orders/CreateOrderPage"));

const TagsPage = lazy(() => import("pages/manageTags/ManageTagsPage"));

const FulfillPage = lazy(() => import("pages/fulfill/FulfillPage"));
const BulkValidationPage = lazy(() => import("pages/fulfill/BulkFulfillmentValidationPage"));
const BulkFulfillmentPage = lazy(() => import("pages/fulfill/BulkFulfillmentPage"));

const SalesChannelPage = lazy(() => import("pages/salesChannels/SalesChannelsPage"));
const SalesChannelDetailsdPage = lazy(() => import("pages/salesChannels/ChannelDetails"));
const FinaliseShopifyInstall = lazy(
  () => import("components/salesChannels/FinaliseShopifyInstall")
);
const FinaliseBidOrBuyInstall = lazy(
  () => import("components/salesChannels/FinaliseBidOrBuyInstall")
);
const InstallChannelFromShopify = lazy(
  () => import("components/salesChannels/InstallChannelFromShopify")
);
const TrackMyParcelPage = lazy(() => import("pages/trackyMyParcel/TrackMyParcelPage"));
const RatesAtCheckoutPage = lazy(() => import("pages/ratesAtCheckout/RatesAtCheckoutPage"));

const MapsPage = lazy(() => import("pages/maps/MapsPage"));

// tools
const BulkOrderImporter = lazy(() => import("components/orders/importer/BulkOrderImporter"));

// Unauthenticated routes are restricted to unauthenticated users

// Authenticated routes require you to be logged in
// Authenticated routes with a "restrict" attribute are restricted to users in that group, eg superusers
const loader = <Loader.Page />;

type Route = {
  path: string;
  isAuthenticatedRoute: boolean;
  component: any;
  restrictUi?: any;
  restrict: any;
  exact?: boolean;
};

type RouteProps = {
  childProps: {
    userHasAuthenticated: any;
    token: any;
    store: IStore;
    showOnboarding: boolean;
  };
};

export default (props: RouteProps) => {
  let { childProps } = props;
  let activeUser = utils.activeUser(childProps);
  let userHasAdminAccess = roleUtils.userHasAdminSystemAccess(childProps);
  let isLoggedIn = Boolean(activeUser);

  const routeConfigs: Route[] = [
    {
      path: "/",
      isAuthenticatedRoute: isLoggedIn,
      component: !isLoggedIn
        ? LoginPage
        : userHasAdminAccess
        ? AdminHomePage
        : childProps.showOnboarding
        ? OnboardingDashboard
        : AccountsHomePage,
      restrictUi: null,
      restrict: null
    },
    {
      path: "/index.html",
      isAuthenticatedRoute: isLoggedIn,
      component: !isLoggedIn ? LoginPage : userHasAdminAccess ? AdminHomePage : AccountsHomePage,
      restrictUi: null,
      restrict: null
    },
    {
      path: "/login",
      isAuthenticatedRoute: false,
      component: LoginPage,
      restrictUi: null,
      restrict: null
    },
    {
      path: "/register",
      isAuthenticatedRoute: false,
      component: AccountSignupPage,
      restrictUi: null,
      restrict: null
    },
    {
      path: "/register/success",
      isAuthenticatedRoute: false,
      component: AccountSignupSuccessPage,
      restrictUi: null,
      restrict: null
    },
    {
      path: "/logout",
      isAuthenticatedRoute: false,
      component: LogoutPage,
      restrict: null,
      restrictUi: null
    },
    {
      path: "/profile",
      isAuthenticatedRoute: true,
      component: ProfilePage,
      restrictUi: null,
      restrict: null
    },
    {
      path: "/address-book",
      isAuthenticatedRoute: true,
      component: AddressBookPage,
      restrictUi: null,
      restrict: null
    },
    {
      path: "/dashboard",
      isAuthenticatedRoute: true,
      component: userHasAdminAccess ? AdminDashboardPage : AccountDashboardPage,
      // TODO Add ui restrictions
      restrictUi: null,
      // TODO add api restrictions
      restrict: null
    },
    {
      path: "/shipments/create",
      isAuthenticatedRoute: true,
      component: CreateShipmentPage,
      restrict: null,
      restrictUi: "shipping"
    },
    {
      path: "/shipments",
      isAuthenticatedRoute: true,
      component: ShipmentsParentPage,
      restrict: ["API_/shipments:GET"],
      restrictUi: "shipping"
    },
    {
      path: "/shipments/:id",
      isAuthenticatedRoute: true,
      component: ShipmentPage,
      restrict: ["API_/shipments:GET"],
      restrictUi: "shipping"
    },
    {
      path: "/maps",
      isAuthenticatedRoute: true,
      component: MapsPage,
      restrict: ["API_/geofence/zones:GET", "API_/geofence/zones/tags:GET"],
      restrictUi: "maps"
    },
    {
      path: "/orders/create",
      isAuthenticatedRoute: true,
      component: CreateOrderPage,
      restrictUi: "orders",
      restrict: ["API_/orders:POST"]
    },
    {
      path: "/orders",
      isAuthenticatedRoute: true,
      component: userHasAdminAccess ? AdminOrderIndexPage : OrdersIndexPage,
      restrictUi: "orders",
      restrict: [
        "API_/orders:GET",
        "API_/orders:PATCH",
        "API_/orders:POST",
        "API_/orders/import:POST",
        "API_/rates:GET",
        "API_/bulk-fulfillments:*"
      ]
    },
    {
      path: "/orders/bulk-validation",
      isAuthenticatedRoute: true,
      component: BulkValidationPage,
      restrictUi: "bulk-fulfillments",
      restrict: [
        "API_/orders:*",
        "API_/orders/*",
        "API_/order-fulfillments/cancel:POST",
        "API_/bulk-fulfillments:*"
      ]
    },
    {
      path: "/orders/bulk-fulfillment",
      isAuthenticatedRoute: true,
      component: BulkFulfillmentPage,
      restrictUi: "bulk-fulfillments",
      restrict: [
        "API_/orders:GET",
        "API_/orders/*",
        "API_/order-fulfillments/cancel:POST",
        "API_/bulk-fulfillments:*"
      ]
    },
    {
      path: "/orders/:id",
      isAuthenticatedRoute: true,
      component: OrderDetailsPage,
      restrictUi: "orders",
      restrict: ["API_/orders:GET", "API_/orders/PATCH", "API_/order-fulfillments/cancel:POST"]
    },
    {
      path: "/orders/:id/fulfill",
      isAuthenticatedRoute: true,
      component: FulfillPage,
      restrictUi: "orders",
      restrict: ["API_/order-fulfillments/cancel:POST", "API_/order-fulfillments:POST"]
    },
    {
      path: "/orders/:id/return",
      isAuthenticatedRoute: true,
      component: FulfillPage,
      restrictUi: "orders",
      restrict: null
    },
    {
      path: "/logs",
      isAuthenticatedRoute: true,
      component: ApiLogsPage,
      // TODO add logs to the ui permissions array
      restrictUi: "logs",
      restrict: ["API_/api-logs:GET"]
    },
    {
      path: "/billing",
      isAuthenticatedRoute: true,
      component: BillingPage,
      restrictUi: "billing",
      restrict: userHasAdminAccess
        ? [
            "API_/billing/transactions:GET",
            "API_/billing/provider-invoices:GET",
            "API_/billing/provider-invoices/*",
            "API_/billing/invoices:GET",
            "API_/billing/credit-notes:GET",
            "API_/payments:GET"
          ]
        : [
            "API_/billing/transactions:GET",
            "API_/billing/provider-invoices:GET",
            "API_/billing/provider-invoices/*",
            "API_/billing/invoices:GET",
            "API_/billing/credit-notes:GET"
          ]
    },
    {
      path: "/billing/billing-exceptions/:id",
      isAuthenticatedRoute: true,
      component: BillingExceptionsPage,
      restrictUi: "billing",
      restrict: ["API_/billing/provider-invoices:GET"]
    },
    {
      path: "/billing/provider-credit-notes/:id",
      isAuthenticatedRoute: true,
      component: BillingProviderCreditNotesPage,
      restrictUi: "billing",
      restrict: ["API_/billing/provider-credit-notes:GET"]
    },
    {
      path: "/accounts",
      isAuthenticatedRoute: true,
      component: userHasAdminAccess ? AccountsPage : AccountPage,
      restrictUi: "accounts",
      restrict: ["API_/accounts:PATCH", "API_/users:GET"]
    },
    {
      path: "/accounts/payment-confirmation",
      isAuthenticatedRoute: true,
      component: PaymentConfirmationPage,
      restrict: null,
      restrictUi: null
    },
    {
      path: "/accounts/payment-cancel",
      isAuthenticatedRoute: true,
      component: PaymentCancelledPage,
      restrict: null,
      restrictUi: null
    },
    {
      path: "/accounts/:id",
      isAuthenticatedRoute: true,
      component: AccountPage,
      restrictUi: "accounts",
      restrict: ["API_/accounts:PATCH"]
    },
    {
      path: "/accounts/:id/markup",
      isAuthenticatedRoute: true,
      component: AccountsMarkupFormulaPage,
      restrictUi: "markup-formula",
      restrict: ["API_/accounts:PATCH"]
    },
    {
      path: "/accounts/invoices/:id",
      isAuthenticatedRoute: true,
      component: AccountInvoicePage,
      restrictUi: "accounts",
      restrict: ["API_/billing/invoices:GET"]
    },
    {
      path: "/accounts/credit-notes/:id",
      isAuthenticatedRoute: true,
      component: CreditNoteDetailsPage,
      restrictUi: "accounts",
      restrict: ["API_/billing/credit-notes:GET"]
    },
    {
      path: "/accounts/archived-invoices/:id",
      isAuthenticatedRoute: true,
      component: ArchivedInvoiceDetailsPage,
      restrictUi: "accounts",
      restrict: ["API_/billing/archived-invoices:GET"]
    },
    {
      path: "/accounts/archived-credit-notes/:id",
      isAuthenticatedRoute: true,
      component: ArchivedCreditNoteDetailsPage,
      restrictUi: "accounts",
      restrict: ["API_/billing/archived-credit-notes:GET"]
    },
    {
      path: "/accounts/credit-notes/:id",
      isAuthenticatedRoute: true,
      component: CreditNoteDetailsPage,
      restrictUi: "accounts",
      restrict: ["API_/accounts:PATCH"]
    },
    {
      path: "/accounts/invoices/:id",
      isAuthenticatedRoute: true,
      component: AccountInvoicePage,
      restrictUi: "accounts",
      restrict: ["API_/accounts:PATCH"]
    },

    {
      path: "/tags",
      isAuthenticatedRoute: true,
      component: TagsPage,
      restrictUi: null,
      restrict: ["API_/tags:GET"]
    },
    {
      path: "/users",
      isAuthenticatedRoute: true,
      component: UsersPage,
      restrictUi: "users",
      restrict: ["API_/users:PATCH", "API_/users:GET"]
    },
    {
      path: "/users/roles",
      isAuthenticatedRoute: true,
      component: RolesPage,
      exact: true,
      restrictUi: "roles",
      restrict: ["API_/roles:PATCH", "API_/roles:DELETE", "API_/roles:GET"]
    },
    {
      path: "/users/:id",
      isAuthenticatedRoute: true,
      component: UserDetailsPage,
      restrictUi: "users",
      restrict: ["API_/users:PATCH", "API_/users:GET"]
    },
    {
      path: "/users/roles/:id",
      isAuthenticatedRoute: true,
      component: RolePage,
      restrictUi: "roles",
      restrict: ["API_/roles:PATCH", "API_/roles:GET"]
    },
    {
      path: "/system-config",
      isAuthenticatedRoute: true,
      component: SystemConfigPage,
      // TODO add admin-config to list of arrays ui permissions
      restrictUi: "admin-config",
      restrict: ["API_/config:POST"]
    },
    {
      path: "/settings",
      isAuthenticatedRoute: true,
      component: AccountSettingsPage,
      restrictUi: null,
      restrict: null
    },
    {
      path: "/alerts",
      isAuthenticatedRoute: true,
      component: AlertsPage,
      restrictUi: null,
      restrict: ["API_/accounts/alerts:GET", "API_/accounts/alerts:POST"]
    },
    {
      path: "/sales-channels",
      isAuthenticatedRoute: true,
      component: SalesChannelPage,
      restrictUi: "channels",
      restrict: userHasAdminAccess
        ? [
            "API_/channel/types:GET",
            "API_/channel/types:POST",
            "API_/channels/install-webhooks:POST",
            "API_/channels/install:POST",
            "API_/channels/uninstall:POST",
            "API_/channels:GET",
            "API_/channels:POST",
            "API_/channels:PATCH"
          ]
        : [
            "API_/channels/install-webhooks:POST",
            "API_/channels/install:POST",
            "API_/channels/uninstall:POST",
            "API_/channels:GET",
            "API_/channels:POST",
            "API_/channels:PATCH"
          ]
    },
    {
      path: "/sales-channels/authorized",
      isAuthenticatedRoute: true,
      component: FinaliseShopifyInstall,
      restrictUi: "channels",
      restrict: ["API_/channels/install:POST"]
    },
    {
      path: "/shopify/authorised",
      isAuthenticatedRoute: true,
      component: InstallChannelFromShopify,
      restrictUi: "channels",
      restrict: ["API_/channels/install:POST"]
    },
    {
      path: "/sales-channels/authorized-bidorbuy",
      isAuthenticatedRoute: true,
      component: FinaliseBidOrBuyInstall,
      restrictUi: "channels",
      restrict: ["API_/channels/install:POST"]
    },
    {
      path: "/sales-channels/:id",
      isAuthenticatedRoute: true,
      component: SalesChannelDetailsdPage,
      restrictUi: "channels",
      restrict: userHasAdminAccess
        ? [
            "API_/channel/types:GET",
            "API_/channel/types:POST",
            "API_/channels/install-webhooks:POST",
            "API_/channels/install:POST",
            "API_/channels/uninstall:POST",
            "API_/channels:GET",
            "API_/channels:POST",
            "API_/channels:PATCH"
          ]
        : [
            "API_/channels/install-webhooks:POST",
            "API_/channels/install:POST",
            "API_/channels/uninstall:POST",
            "API_/channels:GET",
            "API_/channels:POST",
            "API_/channels:PATCH"
          ]
    },

    {
      path: "/reports",
      isAuthenticatedRoute: true,
      component: userHasAdminAccess ? ReportsPage : AccountReportsPage,
      restrictUi: "reports",
      restrict: ["API_/reports:GET", "API_/admin-queries:GET"]
    },
    {
      path: "/providers",
      isAuthenticatedRoute: true,
      component: ProvidersPage,
      restrictUi: "provider",
      restrict: ["API_/providers:GET", "API_/service-levels:GET"]
    },
    {
      path: "/providers/:id",
      isAuthenticatedRoute: true,
      component: ProviderPage,
      restrictUi: "provider",
      restrict: [
        "API_/providers:GET",
        "API_/service-levels:GET",
        "API_/providers/service-levels:GET"
      ]
    },
    {
      path: "/tools/order-bulk-import",
      isAuthenticatedRoute: true,
      component: BulkOrderImporter,
      restrict: ["API_/orders:POST"],
      restrictUi: "orders"
    },
    {
      path: "/track",
      isAuthenticatedRoute: true,
      component: TrackMyParcelPage,
      restrictUi: null,
      restrict: null
    },
    {
      path: "/rates-at-checkout",
      isAuthenticatedRoute: true,
      component: RatesAtCheckoutPage,
      restrictUi: "rates-at-checkout",
      restrict: [
        "API_/geofence/zones:GET",
        "API_/rates-at-checkout/service-levels:GET",
        "API_/rates-at-checkout/surcharges:GET"
      ]
    }
  ];

  const renderRoute = (routeConfigObject: Route) => {
    if (routeConfigObject.isAuthenticatedRoute) {
      return (
        // @ts-ignore
        <AuthenticatedRoutePage
          key={routeConfigObject.path}
          // @ts-ignore
          restrict={routeConfigObject.restrict}
          restrictUi={routeConfigObject.restrictUi}
          path={routeConfigObject.path}
          exact
          component={routeConfigObject.component}
          props={{ ...childProps }}
        />
      );
    } else {
      return (
        // @ts-ignore
        <UnauthenticatedRoutePage
          key={routeConfigObject.path}
          path={routeConfigObject.path}
          exact
          component={routeConfigObject.component}
          props={{ ...childProps }}
        />
      );
    }
  };

  const renderRoutes = () => {
    return routeConfigs.map(routeConfigObject => {
      return renderRoute(routeConfigObject);
    });
  };
  return (
    <Suspense fallback={loader}>
      {/*@ts-ignore*/}
      <Switch>
        {renderRoutes()}
        {/*@ts-ignore*/}
        <UnauthenticatedRoutePage component={NotFoundPage} props={childProps} />
      </Switch>
    </Suspense>
  );
};
