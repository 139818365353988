var host = window.location.host.replace("www.", "");

var accounts: any = {
  "dev.ship.uafrica.com": {
    title: "uAfrica.com :: Dev",
    cognito: {
      REGION: "eu-west-1",
      USER_POOL_ID: "eu-west-1_UWqe9lC10",
      APP_CLIENT_ID: "s1cfoehoj3nl0jc7k4ifsm8lc",
      IDENTITY_POOL_ID: "eu-west-1:3900706a-135d-445e-8f80-dc8f0fc3a898"
    },
    api: "api.dev.ship.uafrica.com",
    shipmentImagesBucket: "",
    notesBucket: "",
    gtmId: "GTM-MHHPRWL",
    raygunKey: "eeWGqgaCYswuogUgpFNJrw",
    tracking: "https://track.dev.ship.uafrica.com"
  },
  "dev.bobgo.co.za": {
    title: "Bob Go :: Dev",
    cognito: {
      REGION: "eu-west-1",
      USER_POOL_ID: "eu-west-1_UWqe9lC10",
      APP_CLIENT_ID: "s1cfoehoj3nl0jc7k4ifsm8lc",
      IDENTITY_POOL_ID: "eu-west-1:3900706a-135d-445e-8f80-dc8f0fc3a898"
    },
    api: "api.dev.bobgo.co.za",
    shipmentImagesBucket: "",
    notesBucket: "",
    gtmId: "GTM-MHHPRWL",
    raygunKey: "eeWGqgaCYswuogUgpFNJrw",
    tracking: "https://track.dev.bobgo.co.za"
  },
  "sandbox.ship.uafrica.com": {
    title: "uAfrica.com :: Sandbox",
    cognito: {
      REGION: "eu-west-1",
      USER_POOL_ID: "eu-west-1_hmmh3K8oj",
      APP_CLIENT_ID: "5drsi3unqpq70sj6unn14kgnrp",
      IDENTITY_POOL_ID: "eu-west-1:f0027d7c-268a-4157-89bc-76122bf9d428"
    },
    api: "api.sandbox.ship.uafrica.com",
    shipmentImagesBucket: "",
    notesBucket: "",
    gtmId: "GTM-MHHPRWL",
    raygunKey: "kG47OLUb3ys1ZO6hASfmJA",
    tracking: "https://track.sandbox.ship.uafrica.com"
  },
  "sandbox.bobgo.co.za": {
    title: "Bob Go :: Sandbox",
    cognito: {
      REGION: "eu-west-1",
      USER_POOL_ID: "eu-west-1_hmmh3K8oj",
      APP_CLIENT_ID: "5drsi3unqpq70sj6unn14kgnrp",
      IDENTITY_POOL_ID: "eu-west-1:f0027d7c-268a-4157-89bc-76122bf9d428"
    },
    api: "api.sandbox.bobgo.co.za",
    shipmentImagesBucket: "",
    notesBucket: "",
    gtmId: "GTM-MHHPRWL",
    raygunKey: "kG47OLUb3ys1ZO6hASfmJA",
    tracking: "https://track.sandbox.bobgo.co.za"
  },
  "playground.ship.uafrica.com": {
    title: "uAfrica.com :: Playground",
    cognito: {
      REGION: "eu-west-1",
      USER_POOL_ID: "eu-west-1_coghEDGDR",
      APP_CLIENT_ID: "4tu3j2vtib49midqan5mflj1nm",
      IDENTITY_POOL_ID: "eu-west-1:d178d1fd-4de3-40f5-bbca-0131d0d2af09"
    },
    api: "api.playground.ship.uafrica.com",
    shipmentImagesBucket: "",
    notesBucket: "",
    gtmId: "GTM-MHHPRWL",
    raygunKey: "nY2rKdLJwNO9VWsmVjuQ",
    tracking: "https://track.playground.ship.uafrica.com"
  },
  "playground.bobgo.co.za": {
    title: "Bob Go :: Playground",
    cognito: {
      REGION: "eu-west-1",
      USER_POOL_ID: "eu-west-1_coghEDGDR",
      APP_CLIENT_ID: "4tu3j2vtib49midqan5mflj1nm",
      IDENTITY_POOL_ID: "eu-west-1:d178d1fd-4de3-40f5-bbca-0131d0d2af09"
    },
    api: "api.playground.bobgo.co.za",
    shipmentImagesBucket: "",
    notesBucket: "",
    gtmId: "GTM-MHHPRWL",
    raygunKey: "nY2rKdLJwNO9VWsmVjuQ",
    tracking: "https://track.playground.bobgo.co.za"
  },
  "ship.uafrica.com": {
    title: "uAfrica.com",
    cognito: {
      REGION: "eu-west-1",
      USER_POOL_ID: "eu-west-1_in9Huf5lA",
      APP_CLIENT_ID: "4g1q8bgen6banm5vqldo1rjijb",
      IDENTITY_POOL_ID: "eu-west-1:cd55f40e-be55-4cda-8b91-8070bb45ce67"
    },
    api: "api.ship.uafrica.com",
    shipmentImagesBucket: "",
    notesBucket: "",
    gtmId: "GTM-52CL6WB",
    // gtmId: "GTM-NH6L6SC",
    raygunKey: "YRXd5zyoYZEU47Ah95yNA",
    tracking: "https://track.uafrica.com"
  },
  "my.bobgo.co.za": {
    title: "Bob Go",
    cognito: {
      REGION: "eu-west-1",
      USER_POOL_ID: "eu-west-1_in9Huf5lA",
      APP_CLIENT_ID: "4g1q8bgen6banm5vqldo1rjijb",
      IDENTITY_POOL_ID: "eu-west-1:cd55f40e-be55-4cda-8b91-8070bb45ce67"
    },
    api: "api.bobgo.co.za",
    shipmentImagesBucket: "",
    notesBucket: "",
    gtmId: "GTM-52CL6WB",
    // gtmId: "GTM-NH6L6SC",
    raygunKey: "YRXd5zyoYZEU47Ah95yNA",
    tracking: "https://track.bobgo.co.za"
  }
};

if (!accounts[host]) {
  // TODO Change to bobgo again once the auth errors are fixed
  host = "dev.bobgo.co.za";
  // host = "dev.ship.uafrica.com";
}

const account = accounts[host];

export default {
  accounts,
  region: "af-south-1",
  ...account
};
