import { Modal, PageHeading } from "uafrica-ui-framework";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as utils from "utils/utils";

interface IProps {
  status: string;
  onHide: any;
  error?: string;
}

function PaymentStatusModal({ status, onHide, error }: IProps) {
  return (
    <>
      <Modal.Medium show={true} closeButton={false} onHide={onHide}>
        <div className="flex justify-end mb-4 -mt-20">
          <FontAwesomeIcon
            data-test={utils.ifDev("Payment-status-modal__close-modal")}
            className={"cursor-pointer"}
            icon="times"
            id="create_shipment_close"
            onClick={onHide}
          />
        </div>
        <div
          className={"w-full flex flex-col justify-center items-center"}
          style={{ height: "60vh" }}
        >
          {status === "success" && (
            <div className={"flex flex-col gap-8 items-center"}>
              <PageHeading>payment successful!</PageHeading>
              <img
                src={process.env.PUBLIC_URL + "/assets/payment-success.svg"}
                alt={"Success payment"}
              />
            </div>
          )}
          {status === "pending" && (
            <div className={"flex flex-col gap-8 items-center"}>
              <PageHeading>payment {status}</PageHeading>
              <span>
                We are waiting for payment confirmation. The payment may take some time to reflect
                in your balance.
              </span>
              <img
                src={process.env.PUBLIC_URL + "/assets/payment-pending.svg"}
                alt={"Pending payment"}
              />
            </div>
          )}
          {status === "cancelled" && (
            <div className={"flex flex-col gap-8 items-center"}>
              <PageHeading>payment cancelled</PageHeading>
              <img
                src={process.env.PUBLIC_URL + "/assets/payment-failed.svg"}
                alt={"Failed payment"}
              />
            </div>
          )}
          {status === "failed" && (
            <div className={"flex flex-col gap-8 items-center"}>
              <PageHeading>payment failed</PageHeading>
              {error && <p className={"text-danger"}>Payment failed because {error}</p>}
              <img
                src={process.env.PUBLIC_URL + "/assets/payment-failed.svg"}
                alt={"Failed payment"}
              />
            </div>
          )}
        </div>
      </Modal.Medium>
    </>
  );
}

export default PaymentStatusModal;
