import { useEffect, useState } from "react";
import { IStore } from "interfaces/store.interface";
import {
  Button,
  generalUtils,
  Modal,
  useSignedRequest,
  useStore,
  withError
} from "uafrica-ui-framework";
import ManageSubscription from "components/shared/ManageSubscription";

import * as utils from "utils/utils";
import MakePaymentsModal from "components/accounts/MakePaymentsModal";

type PaymentResponseType = {
  is_upgrade: boolean;
  description: string;
  amount: number;
  plan_id: number;
  plan_cost: number;
  new_plan: {
    name: string;
    description: string;
  };
};

function ReviewSubscription() {
  const store: IStore = useStore();
  const [showReviewModal, setShowReviewModal] = useState<boolean>(false);
  const [reviewSubscription, setReviewSubscription] = useState<{
    description: string;
    amount: number;
    name: string;
    plan_id?: number;
    plan_cost?: number;
    is_upgrade?: boolean;
  } | null>(null);
  const [displayZeroAmount, setDisplayZeroAmount] = useState<boolean>(false);
  const [displayMakePayment, setDisplayMakePayment] = useState<boolean>(false);

  const {
    isLoading: isUpdatingAccountPlan,
    error: updatingAccountPlanError,
    makeRequest: accountPlanUpdate
  } = useSignedRequest({
    signedRequest: utils.signedRequest,
    url: "/accounts/plan",
    method: "POST",
    initialLoadingState: false,
    fetchOnInit: false,
    onSuccess: async () => {
      const { account } = await utils.getAccount(
        store,
        undefined,
        true,
        true,
        true,
        true,
        true,
        true
      );
      store.set("account", account);
      setDisplayZeroAmount(false);
      setShowReviewModal(false);
    }
  });

  useEffect(() => {
    if (store.account && store.account) {
      if (
        store.account.plan_status === "frozen" &&
        store.account.plan_frozen_reason === "unpaid-invoice" &&
        store.account?.hasOwnProperty("has_valid_subscription")
      ) {
        setShowReviewModal(!store.account.has_valid_subscription ?? false);
      } else if (
        store.account &&
        store.account.plan_status === "active" &&
        store.account?.hasOwnProperty("has_valid_subscription")
      ) {
        setShowReviewModal(!store.account.has_valid_subscription ?? false);
      }
    }
  }, [store.account?.plan_status]);

  function handleReviewDisplay(data: PaymentResponseType) {
    setReviewSubscription({
      description: data.description,
      amount: data.amount,
      name: data.new_plan?.name ?? "",
      plan_id: data.plan_id,
      plan_cost: data.plan_cost,
      is_upgrade: data.is_upgrade
    });
    if (data.amount === 0) {
      setDisplayZeroAmount(true);
      return;
    }
    setDisplayMakePayment(true);
  }

  if (showReviewModal) {
    return (
      <>
        <ManageSubscription
          onComplete={(res: PaymentResponseType) => {
            handleReviewDisplay(res);
          }}
          onHide={() => {}}
          title={"Renew subscription"}
          headerText={
            "In order to continue using our services, you need to renew your subscription. You may keep or change your current plan to any of those listed below."
          }
          // Add so that we do not have to create a new manage subscription component
          fromValidSubscriptionCheck={true}
          closeButton={false}
        />
        {displayZeroAmount && (
          <Modal.Small
            show={true}
            closeButton={true}
            onHide={() => {
              setDisplayZeroAmount(false);
              setReviewSubscription(null);
            }}
            title={"Manage subscription"}
          >
            <div className={"mb-2"}>
              You will be upgraded to the {reviewSubscription?.name} plan.
            </div>
            {updatingAccountPlanError && generalUtils.showError(updatingAccountPlanError)}
            <Button.ButtonsPanel>
              <Button.Cancel
                onClick={() => {
                  setDisplayZeroAmount(false);
                  setReviewSubscription(null);
                }}
              />
              <Button.Primary
                title={"Ok"}
                isLoading={isUpdatingAccountPlan}
                disabled={isUpdatingAccountPlan}
                onClick={() => {
                  accountPlanUpdate({
                    plan_id: reviewSubscription?.plan_id,
                    has_valid_subscription: false
                  });
                }}
              />
            </Button.ButtonsPanel>
          </Modal.Small>
        )}
        {displayMakePayment && (
          // @ts-ignore
          <MakePaymentsModal
            planUpgrade
            onHide={() => setDisplayMakePayment(false)}
            title={"Make payment"}
            paymentItems={{ ...reviewSubscription, new_plan: { name: reviewSubscription?.name } }}
            makeAccountCall={false}
            fromManageSubscription
            onComplete={async () => {
              setShowReviewModal(false);
              setDisplayMakePayment(false);
            }}
            displayPaymentStatus
          />
        )}
      </>
    );
  }

  return null;
}

export default withError(ReviewSubscription);
