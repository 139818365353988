import { Fragment } from "react";
import { useEffect, useState } from "react";
import { generalUtils } from "uafrica-ui-framework";

//Zoho Sales Iq Script:
const useScript = (url: any, widgetCode: any) => {
  useEffect(() => {
    const script = document.createElement("script");
    script.setAttribute("type", "text/javascript");

    let code = `var $zoho=$zoho || {};$zoho.salesiq = $zoho.salesiq || {widgetcode: "${widgetCode}", values:{},ready:function(){}};var d=document;s=d.createElement("script");s.type="text/javascript";s.id="zsiqscript";s.defer=true;s.src="${url}";t=d.getElementsByTagName("script")[0];t.parentNode.insertBefore(s,t);d.innerHTML = "<div id='zsiqwidget'></div>";`;

    script.appendChild(document.createTextNode(code));
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);
};

export default function SalesIQ() {
  const [isDesktop] = useState<boolean>(generalUtils.isScreenDesktopSize());

  if (isDesktop) {
    return (
      <Fragment>
        {/*@ts-ignore*/}
        {useScript(
          "https://salesiq.zoho.com/widget",
          "363dd1c769a0e9a98c3291a8f5b25e79560cfbedbf251936586aeea612ce2ca798dc5be45a67a8cef96c2d4f8d76575e"
        )}
      </Fragment>
    );
  }
  return null;
}
