import AdminNavigation from "components/navigation/AdminNavigation";
import AccountsNavigation from "components/navigation/AccountsNavigation";

import * as utils from "utils/utils";
import * as localRoleUtils from "utils/roleUtils";

import { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MakePaymentsModal from "components/accounts/MakePaymentsModal";

import { withStore, roleUtils, generalUtils, withError, useStore } from "uafrica-ui-framework";

import BarcodeScanner from "components/shared/BarcodeScanner";
import { useHistory, withRouter, Link, useLocation } from "react-router-dom";

// Routes management
import Routes from "Routes";
import Avatar from "react-avatar";
import {
  Button,
  Message,
  Modal,
  ModalActionsPanel,
  PageHeading,
  numberUtils,
  NewVersionAvailable
} from "uafrica-ui-framework";
import config from "config";
import { IResult } from "interfaces/result.interface";
import { IAnnouncement } from "interfaces/announcement.interface";
import Announcement from "components/settings/systemConfig/Announcement";
import { IStore } from "interfaces/store.interface";
import TermsComponent from "components/TermsComponent";

import { usePrinting } from "components/shared/printing";
import { useBulkWaybillDownloads } from "components/orders/shared";
import AccountFrozen from "components/shared/AccountFrozen";
import moment from "moment";
import ManageSubscription from "components/shared/ManageSubscription";
import AccountCancelled from "components/shared/AccountCancelled";
import ReviewSubscription from "components/shared/ReviewSubscription";
import SalesIQ from "components/ZohoComponent";

import OnboardingHeader from "components/onboarding/OnboardingHeader";

interface IProps {
  onNavSectionReady: any;
  history: any;
  token: any;
  activeUser: any;
  userHasAuthenticated: boolean;
  showNav: boolean;
  newVersionAvailable: boolean;
  isNavbarCollapsed: boolean;
  setNavbarCollapsed: any;
  version: string;
  isDesktop: boolean;
  unreadAlertsCount: number;
  announcements: IAnnouncement[];
}

function PageLayout(props: IProps) {
  const history: any = useHistory();
  const location = useLocation();
  let { isNavbarCollapsed, activeUser, showNav, newVersionAvailable, version, unreadAlertsCount } =
    props;

  const store: IStore = useStore();

  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [announcements] = useState<IAnnouncement[]>(props.announcements);
  const [accepting, setAccepting] = useState<boolean>(false);
  const [isOffline, setIsOffline] = useState(false);
  const [showBackOnline, setShowBackOnline] = useState(false);
  const [termsError, setTermsError] = useState<any>(null);
  const [paymentModal, setPaymentModal] = useState<boolean>(false);
  const [terms, setTerms] = useState<{
    terms_url: any;
    terms_major_release_datetime_utc: any;
  } | null>(null);
  const [showTopupAccountModal, setShowTopupAccountModal] = useState(false);
  const [displayMinorTerms, setDisplayMinorTerms] = useState<boolean>(false);
  const [manageSubscription, setManageSubscription] = useState<boolean>(false);
  const [showOnboarding, setShowOnboarding] = useState<boolean>(false);
  const [enterOnboarding, setEnterOnboarding] = useState<boolean>(false);
  const [displayZoho, setDisplayZoho] = useState<boolean>(false);

  const { printBulkWaybills } = usePrinting();
  const { pollWaybills } = useBulkWaybillDownloads();

  window.addEventListener("online", () => {
    setShowBackOnline(true);
    setTimeout(() => {
      setShowBackOnline(false);
      setIsOffline(false);
    }, 2000);
  });
  window.addEventListener("offline", () => setIsOffline(true));

  /* --------------------------------*/
  /* EFFECTS */
  /* --------------------------------*/

  useEffect(() => {
    getConfig();
    if (store.account?.id) {
      const { keyValue: onboardingCompleted } = utils.getAccountSetting(
        store,
        "onboarding_completed"
      );
      if (!onboardingCompleted) {
        return;
      }
      if (!onboardingCompleted.value) {
        const { keyValue } = utils.getAccountSetting(store, "onboarding");

        if (!keyValue || (keyValue && Object.values(keyValue).every((val: any) => !val.viewed))) {
          const isOnboarding: any = localStorage.getItem("onboarding_busy");
          if (location.pathname !== "/" && !isOnboarding) {
            history.push("/");
          }
          setShowOnboarding(true);
        }
      }
    }
  }, [store.user, store.logged_in_user]);

  useEffect(() => {
    // TODO move entire onboarding setup logic to App.tsx
    if (store.onboarding_entered) {
      setEnterOnboarding(store.onboarding_entered);
    } else {
      setEnterOnboarding(false);
    }
  }, [store.onboarding_entered]);

  useEffect(() => {
    if (store.account && store.account.id) {
      if (["Silver", "Custom", "Gold"].indexOf(store.account.plan.name) >= 0) {
        setDisplayZoho(true);
      }
    }
  }, [store.account]);

  /* --------------------------------*/
  /* FUNCTIONS */
  /* --------------------------------*/

  const getConfig = async () => {
    if (!store.account && !store.user) {
      return;
    }
    let { systemConfig } = await utils.getSystemConfig(store, {
      key: ["terms_major_release_datetime_utc", "terms_url"]
    });

    if (systemConfig) {
      setTerms(systemConfig);

      if (store.user.accepted_terms) {
        if (
          compareTime(
            systemConfig?.terms_major_release_datetime_utc?.value,
            store?.user?.accepted_terms
          )
        ) {
          setDisplayMinorTerms(true);
        }
      }
    }
  };

  function compareTime(time1: Date, time2: Date) {
    return new Date(time1) > new Date(time2); // true if time1 is later
  }

  function toggleNavbarCollapsed() {
    props.setNavbarCollapsed(!isNavbarCollapsed);
    localStorage.setItem("is_navbar_collapsed", JSON.stringify(!isNavbarCollapsed));
  }

  function isLoginScreen() {
    return (
      window.location.pathname.indexOf("/login") >= 0 ||
      window.location.pathname.indexOf("/register") >= 0
    );
  }

  /* --------------------------------*/
  /* RENDER METHODS */
  /* --------------------------------*/

  function renderOnboarding() {
    if (!enterOnboarding) {
      return null;
    }

    return (
      <div style={{ marginBlock: "1rem" }}>
        <OnboardingHeader />
      </div>
    );
  }

  function renderOfflineNotification() {
    if (!isOffline) {
      return null;
    }

    return (
      <div
        className={`${
          showBackOnline ? "bg-green-100" : "bg-red-100"
        } p-4 flex justify-between items-center ${
          showBackOnline ? "text-green" : "text-red"
        } font-bold sticky top-0 z-20 -mt-4 mb-4 -mx-4`}
      >
        <div className="flex items-center">
          <FontAwesomeIcon
            icon={showBackOnline ? "check" : "exclamation-circle"}
            className={`mr-4 ${isLoginScreen() ? "ml-4" : ""}`}
          />
          <div className="link-container">
            {showBackOnline
              ? "You are back online!"
              : "You are currently offline. Please check your internet connection."}
          </div>
        </div>
      </div>
    );
  }

  function renderAccountFrozen() {
    return <AccountFrozen />;
  }

  function renderAccountCancelled() {
    return <AccountCancelled />;
  }

  function renderTermsOfService() {
    const acceptTerms = async () => {
      setAccepting(true);
      try {
        let res: IResult = await utils.signedRequest(store, "/users", "PATCH", {
          accepted_terms: true,
          id: store.user.id
        });
        if (res.ok) {
          store.set("user", res.data);
          store.set("logged_in_user", res.data);
          setDisplayMinorTerms(false);
        }
        if (!res.ok) {
          setTermsError(generalUtils.getError(res, true));
        }
      } catch (e) {
        console.error(e);
      }
      setAccepting(false);
    };
    if (store.logged_in_user && !store.impersonated_user_id) {
      if (
        (!store.logged_in_user.accepted_terms && !localRoleUtils.isStaff(store.logged_in_user)) ||
        displayMinorTerms
      ) {
        return (
          <Modal.Small title={"Update to our terms of service"} show={true} closeButton={false}>
            <div className={"mb-20"}>
              <TermsComponent displayMinorTerms={displayMinorTerms} systemConfig={terms} />
            </div>

            <Modal.ButtonsPanel fixed>
              <Button.Cancel title={"Log out"} onClick={() => store.emitter.emit("logOut")} />
              <div className={"flex gap-4 items-center justify-end"}>
                <p className={"text-danger"}>{termsError}</p>
                <Button.Primary
                  id={utils.ifDev("Page-layout__accept-updated-terms")}
                  title={"Accept"}
                  onClick={() => acceptTerms()}
                  isLoading={accepting}
                />
              </div>
            </Modal.ButtonsPanel>
          </Modal.Small>
        );
      }
    }

    return null;
  }

  function renderBarcodeScanner() {
    if (localRoleUtils.isStaff(store.logged_in_user)) {
      return null;
    }

    const getScannedOrder = async (scanned: any) => {
      const { newOrder, newOrderError } = await utils.getOrder(store, parseInt(scanned));

      if (newOrderError) {
        store.emitter.emit("showToast", {
          text: "Error finding order: " + newOrderError,
          variant: "error",
          autoHide: 5000
        });
      } else if (newOrder.fulfillment_status === "unfulfilled") {
        history.push({ pathname: `/orders/${newOrder.id}/fulfill`, search: "" });
      } else {
        history.push({
          pathname: `/orders/${newOrder.id}`,
          search: ""
        });
      }
    };

    const autoFulfillScannedOrder = async (scanned: any) => {
      try {
        let res: IResult = await utils.signedRequest(store, "/order-fulfillments", "POST", {
          method: "shipment",
          type: "original",
          order_id: parseInt(scanned),
          source: "scan"
        });

        if (res.ok) {
          if (store.account_settings.waybills_sticker_printing?.value) {
            printBulkWaybills([res.data.method_reference], store);
          } else {
            pollWaybills([res.data.method_reference], store);
          }
        }
        if (!res.ok) {
          store.emitter.emit("showToast", {
            text: generalUtils.getError(res, true),
            variant: "error",
            autoHide: 5000
          });
        }
      } catch (e) {
        store.emitter.emit("showToast", {
          text: generalUtils.getError(e, true),
          variant: "error",
          autoHide: 5000
        });
      }
    };

    const barcodeScanned = async (scanned: string) => {
      if (store.account_settings?.allow_auto_fulfill_barcode_scan?.value) {
        autoFulfillScannedOrder(scanned);
        return;
      }
      getScannedOrder(scanned);
    };
    return (
      <BarcodeScanner
        onScan={(code: any) => {
          barcodeScanned(code);
        }}
      />
    );
  }

  function renderImpersonation() {
    if (!store || !store.impersonated_user_id || !store.logged_in_user) {
      return null;
    }

    return (
      <div className="bg-orange-100 p-4 flex justify-between items-center text-orange-500 font-bold sticky top-0 -mt-4 mb-4 -mx-4 z-50">
        <div>
          <FontAwesomeIcon icon="rocket" className="mr-4" />
          Impersonating {store.logged_in_user.name} ({store.logged_in_user.email}){" "}
        </div>
        <FontAwesomeIcon
          id="stop_impersonating"
          icon="times"
          className="cursor-pointer hover:text-orange-900"
          onClick={() => {
            utils.stopImpersonating(store);
          }}
        />
      </div>
    );
  }

  function renderAccountLocked() {
    if (!store || !store.account) {
      return null;
    } else if (store.account.has_locked_channels) {
      return (
        <Message.Error>
          Some sales channels require reauthorisation. Please go to your{" "}
          <Link to={"/sales-channels"} className={"font-bold"}>
            sales channels
          </Link>{" "}
          to reauthorise them.
        </Message.Error>
      );
    } else {
      return null;
    }
  }

  function renderAnnouncements() {
    return (
      announcements.length > 0 && (
        <Announcement announcement={announcements[0]} placement="header" />
      )
    );
  }

  function renderSandboxEnvironment() {
    if (config.api === "api.sandbox.ship.uafrica.com") {
      return (
        <Message.Warning>
          You are currently in the Sandbox environment. Please note that this is a fake environment
          and no orders or shipments will actually be created.
        </Message.Warning>
      );
    }
    return null;
  }

  function renderTrialAccount() {
    if (!store || !store.account || store.account?.plan?.name !== "Trial") {
      return null;
    }

    if (localRoleUtils.isStaff(store.logged_in_user)) {
      return null;
    }

    if (
      store &&
      store.account?.plan_status === "frozen" &&
      store.account?.plan_frozen_reason !== "plan-expired"
    ) {
      return null;
    }

    const currentDate = moment(Date.now());
    const createdDate = moment(store.account.plan_expiry_date);
    const duration = moment.duration(createdDate.diff(currentDate)).asDays() - 1;

    if (duration > 7) {
      return null;
    }

    const renderAccountLimit = () => {
      if (store.account && store.account.credit_limit_reached) {
        return (
          <div>
            {Math.abs(Math.ceil(duration)) === 0 ? (
              <>Your trial expires today </>
            ) : (
              <>
                {Math.ceil(duration) < 0 ? (
                  <>Your trial has expired </>
                ) : (
                  <>
                    Your trial will expire in {Math.ceil(duration)} {duration > 1 ? "days" : "day"}{" "}
                  </>
                )}
              </>
            )}
            and you have reached your account limit.{" "}
            <span
              className={
                roleUtils.hasPermission(utils.activeUser({ store }), "API_/payments:POST")
                  ? "font-bold text-yellow-link underline cursor-pointer"
                  : ""
              }
              onClick={async () => {
                roleUtils.hasPermission(utils.activeUser({ store }), "API_/payments:POST") &&
                  utils
                    .getAccount(store)
                    .then(({ account }) => store.set("account", account))
                    .then(() => setPaymentModal(true));
              }}
            >
              Pay your outstanding balance
            </span>{" "}
            and{" "}
            <span
              className={"underline text-yellow-link cursor-pointer"}
              onClick={() => setManageSubscription(!manageSubscription)}
            >
              upgrade your plan
            </span>{" "}
            to continue using uAfrica.
          </div>
        );
      }
      if (store.account && store.account.reaching_credit_limit) {
        return (
          <div>
            {Math.abs(Math.ceil(duration)) === 0 ? (
              <>Your trial expires today </>
            ) : (
              <>
                {Math.ceil(duration) < 0 ? (
                  <>Your trial has expired </>
                ) : (
                  <>
                    Your trial will expire in {Math.ceil(duration)} {duration > 1 ? "days" : "day"}{" "}
                  </>
                )}
              </>
            )}
            and you have almost reached your account limit. Once your account limit is reached you
            will not be able to fulfill orders or create shipments.{" "}
            <span
              className={
                roleUtils.hasPermission(utils.activeUser({ store }), "API_/payments:POST")
                  ? "font-bold text-yellow-link underline cursor-pointer"
                  : ""
              }
              onClick={async () => {
                roleUtils.hasPermission(utils.activeUser({ store }), "API_/payments:POST") &&
                  utils
                    .getAccount(store)
                    .then(({ account }) => store.set("account", account))
                    .then(() => setPaymentModal(true));
              }}
            >
              Pay your outstanding balance
            </span>{" "}
            and{" "}
            <span
              className={"underline text-yellow-link cursor-pointer"}
              onClick={() => setManageSubscription(!manageSubscription)}
            >
              upgrade your plan
            </span>{" "}
            to continue using uAfrica.
          </div>
        );
      }

      return (
        <div>
          {Math.abs(Math.ceil(duration)) === 0 ? (
            <>Your trial expires today. </>
          ) : (
            <>
              {Math.ceil(duration) < 0 ? (
                <>Your trial has expired. </>
              ) : (
                <>
                  Your trial will expire in {Math.ceil(duration)} {duration > 1 ? "days" : "day"}.{" "}
                </>
              )}
            </>
          )}
          <span
            className={"underline text-yellow-link cursor-pointer"}
            onClick={() => setManageSubscription(!manageSubscription)}
          >
            Upgrade your plan
          </span>{" "}
          to keep using uAfrica.
        </div>
      );
    };

    return (
      <div className="bg-yellow-100 p-4 text-yellow font-bold sticky top-0 z-50 -mt-4 mb-4 -mx-4">
        <div className="flex items-center gap-4 my-2">
          <FontAwesomeIcon icon="exclamation-circle" />
          {renderAccountLimit()}
          <div className={"text-left"}></div>
        </div>
      </div>
    );
  }

  function renderPickAPlan() {
    if (store.frozen && store.frozen_reason === "plan-expired") {
      return (
        <ManageSubscription
          onHide={() => {
            store.remove("frozen");
            store.remove("frozen_reason");
          }}
          onComplete={async () => {
            const { account } = await utils.getAccount(store, undefined, true, true, true, true);
            const { settings } = await utils.getAccountSettings(store);
            let { packageSettings } = await utils.getAccountPackages(store);
            store.set("account", account);
            store.set("account_settings", { ...settings, ...packageSettings });
            store.remove("frozen");
            store.remove("frozen_reason");
          }}
        />
      );
    }
    if (store.cancelled) {
      return (
        <Modal.Medium show={true} closeButton={true}>
          <ModalActionsPanel>
            <FontAwesomeIcon
              data-test={utils.ifDev("Page-layout__close-modal")}
              className={"cursor-pointer"}
              icon="times"
              id="create_order_modal"
              onClick={() => store.remove("cancelled")}
            />
          </ModalActionsPanel>
          <PageHeading center={true}>your subscription is cancelled</PageHeading>
          <div className={"text-center my-8"}>
            Contact support at{" "}
            <a className={"link font-bold"} href={"mailto:" + "support@uafrica.com"}>
              support@uafrica.com
            </a>{" "}
            or phone <strong>(+27) 12 940 1060</strong>
          </div>

          <div className={"flex justify-center my-8"}>
            <img
              src={process.env.PUBLIC_URL + "/public/assets/account-inactive.svg"}
              alt={"Account inactive"}
            />
          </div>
        </Modal.Medium>
      );
    }
    return null;
  }

  function renderAccountLimit() {
    if (!store || !store.account || (store.account && !store.account.reaching_credit_limit)) {
      return null;
    }

    if (localRoleUtils.isStaff(store.logged_in_user)) {
      return null;
    }

    if (
      store &&
      store.account?.plan_status === "frozen" &&
      store.account?.plan_frozen_reason !== "plan-expired"
    ) {
      return null;
    }

    const currentDate = moment(Date.now());
    const createdDate = moment(store.account.plan_expiry_date);
    const duration = moment.duration(createdDate.diff(currentDate)).asDays() - 1;

    if (duration < 8 && store.account?.plan_frozen_reason === "plan-expired") {
      return null;
    }

    if (store && store.account?.plan_status !== "active") {
      return null;
    }

    if (store && store.providers?.some(provider => provider.is_configured)) {
      return null;
    }

    return (
      <div className="bg-yellow-100 p-4 text-yellow font-bold sticky top-0 z-50 -mt-4 mb-4 -mx-4">
        <div className="flex items-center gap-4 my-2">
          <FontAwesomeIcon icon="exclamation-circle" />
          <div className={"text-left"}>
            {store.account.credit_limit_reached
              ? "You have reached your account limit. "
              : "You have almost reached your account limit. Once your account limit is reached you will not be able to fulfill orders or create shipments. "}
            Please{" "}
            <span
              className={
                roleUtils.hasPermission(utils.activeUser({ store }), "API_/payments:POST")
                  ? "font-bold text-yellow-link underline cursor-pointer"
                  : ""
              }
              data-test={utils.ifDev("Page-layout__pay-outstanding-balance-banner")}
              onClick={async () => {
                roleUtils.hasPermission(utils.activeUser({ store }), "API_/payments:POST") &&
                  setPaymentModal(true);
              }}
            >
              pay your outstanding balance.
            </span>
          </div>
        </div>
      </div>
    );
  }

  function renderNavExpandCollapseButton() {
    let activeUser = utils.activeUser({ store });

    if (!localRoleUtils.userHasAdminSystemAccess({ store }) || !activeUser || !showNav) return null;

    return (
      <div
        onClick={() => {
          toggleNavbarCollapsed();
        }}
        className="cursor-pointer text-gray-700 hover:text-gray-900"
        title={isNavbarCollapsed && !sidebarOpen ? "Expand navigation" : "Collapse navigation"}
      >
        <FontAwesomeIcon
          icon={isNavbarCollapsed && !sidebarOpen ? "angle-double-right" : "angle-double-left"}
        />
      </div>
    );
  }

  function renderLogo(sectionName: string, excludeMargin?: boolean) {
    return (
      <a
        href={"/"}
        className={
          "w-auto cursor-pointer h-10 md:h-auto " + (excludeMargin ? "align-center" : "mx-auto")
        }
      >
        <img
          onLoad={() => {
            props.onNavSectionReady(sectionName);
          }}
          className={
            "w-auto cursor-pointer h-10 md:h-auto " +
            (excludeMargin ? "align-center" : "mx-auto mb-4")
          }
          alt="Logo"
          src={
            isNavbarCollapsed && !sidebarOpen
              ? utils.isDev()
                ? process.env.PUBLIC_URL + "/bobgo_favicon.png"
                : process.env.PUBLIC_URL + "/favicon.png"
              : utils.isDev()
              ? "https://ik.imagekit.io/z1viz85yxs/prod-v3/bobgo/corporate-identity/bobgo_logo_full_colour.png?tr=h-200"
              : process.env.PUBLIC_URL + "/uafrica_soon_to_be_bob_go.svg"
          }
        />
      </a>
    );
  }

  function renderReviewSubscription() {
    if (localRoleUtils.isStaff(activeUser)) {
      return;
    }
    if (!store.account) {
      return;
    }

    return <ReviewSubscription />;
  }

  function renderTopUp() {
    let isStaff = localRoleUtils.isStaff(activeUser);
    let isAccountUser = localRoleUtils.isAccountUser(activeUser);
    let { account: activeAccount } = store;
    return (
      <>
        {activeUser && (
          <div>
            <hr />
            {!isStaff && !isAccountUser && activeAccount && (
              <div>
                <div className={utils.getBalanceClassNames(activeAccount.balance, true)}>
                  Balance:{" "}
                  <span className="break-normal inline-block">
                    {numberUtils.formatNumberWithCurrency(activeAccount.balance, false, true)}
                  </span>
                </div>
                {roleUtils.hasPermission(utils.activeUser({ store }), "API_/payments:POST") && (
                  <div className="flex justify-center">
                    <div
                      data-test={utils.ifDev("Page-layout__top-up-account")}
                      className="border rounded-full uppercase space-x-4 px-4 py-1 mt-2 text-sm text-center cursor-pointer hover:bg-gray-100"
                      onClick={() => {
                        setShowTopupAccountModal(true);
                        setSidebarOpen(false);
                      }}
                    >
                      {" "}
                      <FontAwesomeIcon icon="money-bill" />
                      <span>Top up account</span>
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        )}
        {showTopupAccountModal && (
          <MakePaymentsModal
            isTopUp
            title="Top up account"
            displayPaymentStatus
            onComplete={async () => {
              let { account } = await utils.getAccount(store, undefined, true, true, true, false);
              store.set("account", account);
              setShowTopupAccountModal(!showTopupAccountModal);
            }}
            onHide={() => {
              setShowTopupAccountModal(false);
            }}
          />
        )}
      </>
    );
  }

  function renderSystemDetails() {
    return (
      <div style={{ display: showNav ? undefined : "none" }}>
        {!utils.isMaintenanceModePage() && !utils.isAccountClosedPage() && (
          <div>
            {!isNavbarCollapsed && (
              <div className="mt-4">
                {newVersionAvailable && <NewVersionAvailable />}
                <div className="px-3 pt-2 text-xs">{version}</div>
              </div>
            )}
          </div>
        )}
      </div>
    );
  }

  function renderNavigation() {
    return localRoleUtils.userHasAdminSystemAccess({ store }) ? (
      <AdminNavigation
        onMounted={() => {
          props.onNavSectionReady("navItems");
        }}
        toggleNavbarCollapsed={toggleNavbarCollapsed}
        isNavbarCollapsed={isNavbarCollapsed}
        activeUser={props.activeUser}
        closeMenu={() => setSidebarOpen(false)}
      />
    ) : (
      // @ts-ignore
      <AccountsNavigation
        unreadAlertsCount={unreadAlertsCount}
        onMounted={() => {
          props.onNavSectionReady("navItems");
        }}
        isNavbarCollapsed={isNavbarCollapsed}
        activeUser={props.activeUser}
        closeMenu={() => setSidebarOpen(false)}
      />
    );
  }

  function renderProfile() {
    if (!props.activeUser) return null;

    let activeAccount = store.account;

    if (isNavbarCollapsed) {
      return (
        <div className="py-2 cursor-pointer" onClick={() => props.setNavbarCollapsed(false)}>
          <Avatar name={props.activeUser.name} size="30" round={true} className="ml-1" />
          <div className="ml-4 mt-3">{!sidebarOpen && renderNavExpandCollapseButton()}</div>
        </div>
      );
    }

    return (
      <div className="flex border-t border-gray-200 py-2 px-4">
        <div className="flex w-full">
          <div className="flex items-center">
            {/* @ts-ignore */}
            <Avatar name={props.activeUser.name} size="30" round={true} className="mt-0.5" />
          </div>
          <div className="ml-3">
            <p className="text-gray-700 font-semibold mr-2">
              {generalUtils.shorten(props.activeUser.name, 18)}
            </p>
            {(activeAccount || props.activeUser?.role?.name) && (
              <p
                title={
                  activeAccount && activeAccount.account_code && activeAccount.name
                    ? activeAccount.account_code + " - " + activeAccount.name
                    : props.activeUser?.role?.name
                }
                className="text-xs whitespace-nowrap overflow-hidden w-36"
                style={{ textOverflow: "ellipsis" }}
                data-test={utils.ifDev("Page-layout__account-code")}
              >
                {activeAccount && activeAccount.account_code && activeAccount.name
                  ? activeAccount.account_code + " – " + activeAccount.name
                  : props.activeUser?.role?.name}
              </p>
            )}
            <p
              data-test={utils.ifDev("Page-layout__logout")}
              className="text-xs font-semibold text-gray-500 group-hover:text-gray-700 cursor-pointer"
              onClick={() => store.emitter.emit("logOut")}
            >
              Log out
            </p>
          </div>
          <div className="flex items-center ">
            {!sidebarOpen && renderNavExpandCollapseButton()}
          </div>
        </div>
      </div>
    );
  }

  function render() {
    const childProps = {
      userHasAuthenticated: props.userHasAuthenticated,
      token: props.token,
      store: store,
      showOnboarding: utils.isDev() && showOnboarding
    };

    const displayNavItems = () => {
      if (!showOnboarding) {
        return true;
      } else if (showOnboarding && location.pathname === "/") {
        return false;
      }
      return true;
    };

    return (
      <div className="h-screen flex overflow-hidden bg-gray-50">
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog
            as="div"
            static
            className="fixed inset-0 flex z-40 md:hidden"
            open={sidebarOpen}
            onClose={setSidebarOpen}
          >
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
            </Transition.Child>
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <div className="relative flex-1 flex flex-col max-w-xs w-full bg-white">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute top-0 right-0 -mr-12 pt-2">
                    <button
                      className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none"
                      onClick={() => setSidebarOpen(false)}
                    >
                      <span className="sr-only">Close sidebar</span>
                      <FontAwesomeIcon icon="times" className="text-white" />
                    </button>
                  </div>
                </Transition.Child>
                <div className="flex-1 h-0 pt-5 pb-4 overflow-y-auto">
                  <div className="flex-shrink-0 flex items-center px-4">
                    {renderLogo("mobileLogo")}
                  </div>
                  <nav className="mt-2 px-2">
                    <div className="z-20">{renderNavigation()}</div>
                  </nav>
                </div>
                {!localRoleUtils.isStaff(store.user) && (
                  <div className={"w-fit-content px-6 py-4"}>
                    <a
                      href={"https://support.uafrica.com"}
                      target={"_blank"}
                      className={"bubble left cursor-pointer"}
                    >
                      Need help?
                    </a>
                  </div>
                )}
                {renderProfile()}
              </div>
            </Transition.Child>
            <div className="flex-shrink-0 w-14">
              {/* Force sidebar to shrink to fit close icon */}
            </div>
          </Dialog>
        </Transition.Root>

        {/* Static sidebar for desktop */}
        <div className="hidden md:flex md:flex-shrink-0">
          <div className={"flex flex-col " + (isNavbarCollapsed && !sidebarOpen ? "w-12" : "w-60")}>
            {/* Sidebar component, swap this element with another sidebar if you like */}
            <div className="flex flex-col h-0 flex-1 border-r border-gray-100 bg-white">
              <div className="flex-1 flex flex-col pt-5 pb-4 overflow-y-auto">
                <div
                  className={
                    "flex items-center flex-shrink-0 " +
                    (isNavbarCollapsed && !sidebarOpen ? "px-1" : "px-4")
                  }
                >
                  {renderLogo("desktopLogo")}
                </div>
                <nav className="flex-1 px-2 bg-white">
                  {displayNavItems() && (
                    <>
                      {renderNavigation()}
                      {renderTopUp()}
                      {renderSystemDetails()}
                    </>
                  )}
                </nav>
              </div>
              {!displayZoho && !localRoleUtils.isStaff(store.user) && store.user && (
                <div className={"w-fit-content px-6 py-4"}>
                  <a
                    href={"https://support.uafrica.com"}
                    target={"_blank"}
                    className={"bubble left cursor-pointer"}
                  >
                    Need help?
                  </a>
                </div>
              )}
              <>{renderProfile()}</>
            </div>
          </div>
        </div>

        <div className="flex flex-col w-0 flex-1 overflow-hidden">
          <div className="md:hidden pl-1 border-b flex fixed z-10 w-full bg-white">
            <button
              className="-ml-0.5 -mt-0.5 h-12 w-12 inline-flex items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none"
              onClick={() => setSidebarOpen(true)}
            >
              <span className="sr-only">Open sidebar</span>
              <FontAwesomeIcon icon="bars" />
            </button>
            <div className="items-center justify-center flex">{renderLogo("mobileLogo", true)}</div>
          </div>
          <main
            className={`flex-1 relative z-0 overflow-y-auto focus:outline-none ${
              store.onboarding_entered ? " onboarding-layover" : ""
            }`}
            id="page_content"
          >
            <div className={isLoginScreen() ? "" : "pt-16 md:pt-6 pb-4"}>
              <div className={"mx-auto " + (isLoginScreen() ? "" : "px-4")}>
                {/* This is a container for all full width banners/ribbons */}
                <div className={isLoginScreen() ? "mt-4" : "-mt-6"}>
                  {renderOfflineNotification()}
                  {renderImpersonation()}
                  {renderTrialAccount()}
                  {renderAnnouncements()}
                  {renderAccountFrozen()}
                  {renderAccountCancelled()}
                  {renderAccountLimit()}
                  {renderAccountLocked()}
                  {renderReviewSubscription()}
                  {renderTermsOfService()}
                </div>
                {/* This is a container for components that do not classify as full width banners/ribbons */}
                <div className={"mt-6"}>
                  {renderSandboxEnvironment()}
                  {renderBarcodeScanner()}
                  {renderPickAPlan()}
                  {displayZoho && <SalesIQ />}
                </div>

                {renderOnboarding()}
                <Routes childProps={childProps} />
              </div>
            </div>
          </main>
        </div>
        {paymentModal && (
          <MakePaymentsModal
            onHide={() => {
              setPaymentModal(!paymentModal);
            }}
            makeAccountCall
            title={"Make payment"}
            displayPaymentStatus
            onComplete={async () => {
              let { account } = await utils.getAccount(store, undefined, true, true, true, false);
              store.set("account", account);
              setPaymentModal(!paymentModal);
            }}
          />
        )}
        {manageSubscription && (
          <ManageSubscription
            onHide={() => {
              setManageSubscription(false);
            }}
            onComplete={async () => {
              const { account } = await utils.getAccount(store, undefined, true, true, true, true);
              const { settings } = await utils.getAccountSettings(store);
              let { packageSettings } = await utils.getAccountPackages(store);

              let absCL: number = Math.abs(account.credit_limit);
              let absBal: number = Math.abs(account.balance);
              let reaching_credit_limit: boolean = false;
              let credit_limit_reached: boolean = false;
              if (account.balance < 0 && absBal / absCL > 0.8) {
                reaching_credit_limit = true;
              }
              if (absBal > absCL) {
                credit_limit_reached = true;
              }
              account["reaching_credit_limit"] = reaching_credit_limit;
              account["credit_limit_reached"] = credit_limit_reached;
              if (account.balance < 0) {
                account["remaining_balance"] = account.balance + account.credit_limit;
              } else {
                account["remaining_balance"] = Math.abs(account.balance - account.credit_limit);
              }

              store.set("account", account);
              store.set("account_settings", { ...settings, ...packageSettings });
              setManageSubscription(false);
            }}
          />
        )}
      </div>
    );
  }

  return render();
}

export default withRouter(withStore(withError(PageLayout)));
