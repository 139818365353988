import * as utils from "utils/utils";
import { IStore } from "interfaces/store.interface";
import {
  Button,
  Confirm,
  Modal,
  Loader,
  numberUtils,
  generalUtils,
  roleUtils
} from "uafrica-ui-framework";
import { useStore } from "uafrica-ui-framework";
import { useEffect, useState } from "react";
import { IPlan } from "interfaces/plan.interface";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IResult } from "interfaces/result.interface";
import MakePaymentsModal from "components/accounts/MakePaymentsModal";
import moment from "moment/moment";

interface IProps {
  onHide: Function;
  onComplete: Function;
  title?: "Manage subscription" | "Renew subscription";
  fromValidSubscriptionCheck?: boolean;
  headerText?: string;
  closeButton?: boolean;
  fromManageSubscription?: boolean;
}

function ManageSubscription({
  onHide,
  onComplete,
  title,
  fromValidSubscriptionCheck,
  headerText,
  closeButton,
  fromManageSubscription
}: IProps) {
  const store: IStore = useStore();
  const [loadingState, setLoadingState] = useState<{
    gettingPlans: boolean;
    paymentRequest: boolean;
    planId: any;
    planUpdate: boolean;
  }>({ gettingPlans: false, paymentRequest: false, planId: undefined, planUpdate: false });
  const [error, setError] = useState<{ planError: any; paymentRequest: any; planUpdate: any }>({
    planError: null,
    paymentRequest: null,
    planUpdate: null
  });
  const [plans, setPlans] = useState<IPlan[]>([]);
  const [currentPlan, setCurrentPlan] = useState<IPlan>();
  const [modalToShow, setModalToShow] = useState<string>("");
  const [planChangeBody, setPlanChangesBody] = useState<{
    is_upgrade: boolean;
    description: string;
    amount: number;
    plan_id: number;
    new_plan: {
      name: string;
    };
  } | null>(null);
  const [renewPlanId, setRenewPlanId] = useState<number>();

  // initially set the renew plan id to the current plan
  useEffect(() => {
    if (fromValidSubscriptionCheck) {
      setRenewPlanId(store.account.plan_id);
    }
  }, [fromValidSubscriptionCheck]);

  useEffect(() => {
    getPlans();
  }, []);

  const getPlans = async () => {
    setLoadingState({ ...loadingState, gettingPlans: true });
    let { plans, planError } = await utils.getAccountPlan(store);

    if (planError) {
      setError({ ...error, planError: planError });
    } else {
      setPlans(
        plans
          .filter((plan: IPlan) => plan.slug !== "custom" && plan.slug !== "trial")
          .sort((a: any, b: any) => {
            if (a.position > b.position) {
              return 1;
            }
            if (a.position < b.position) {
              return -1;
            }
            return 0;
          })
      );
      if (store.account && store.account.plan_status !== "cancelled") {
        setCurrentPlan(plans.find((plan: IPlan) => plan.id === store.account.plan_id));
      }
    }
    setLoadingState({ ...loadingState, gettingPlans: false });
  };

  const paymentRequest = async (plan_id: number, includeValidSubscription?: boolean) => {
    setLoadingState({ ...loadingState, paymentRequest: true, planId: plan_id });
    let args: any = { plan_id: plan_id };

    if (includeValidSubscription) {
      args.has_valid_subscription = false;
    }
    try {
      let res: IResult = await utils.signedRequest(store, "/payment-request", "GET", {
        ...args
      });
      if (res.ok) {
        if (!fromValidSubscriptionCheck) {
          setLoadingState({ ...loadingState, paymentRequest: false, planId: undefined });
          if (!res.data.is_upgrade) {
            setModalToShow("downgrade");
          }
          if (res.data.is_upgrade) {
            setModalToShow("upgrade");
          }
          setPlanChangesBody(res.data);
        } else {
          // onHide();
          onComplete(res.data);
        }
      }
      if (!res.ok) {
        setError({ ...error, paymentRequest: generalUtils.getError(res, true) });
      }
    } catch (e) {
      setError({ ...error, paymentRequest: generalUtils.getError(e, true) });
    }
  };

  const accountPlanUpdate = async (id: number) => {
    setLoadingState({ ...loadingState, planUpdate: true });
    let args: any = {
      plan_id: id
    };
    try {
      let res: IResult = await utils.signedRequest(store, "/accounts/plan", "POST", {
        ...args
      });
      if (res.ok) {
        let { account } = await utils.getAccount(store, undefined, true, true, true, true);
        store.set("account", account);
        setModalToShow("");
        onHide && onHide();
        onComplete();
      }
      if (!res.ok) {
        setError({ ...error, planUpdate: generalUtils.getError(res, true) });
        // return;
      }
    } catch (e) {
      setError({ ...error, planUpdate: generalUtils.getError(e, true) });
    }
    setLoadingState({ ...loadingState, planUpdate: false });
  };

  // render options for from valid subscription check or normal buttons
  const renderPlanButtons = (plan: IPlan) => {
    if (fromValidSubscriptionCheck) {
      return (
        <>
          {renewPlanId === plan.id ? (
            <Button.Primary
              id={utils.ifDev(`Manage-subscription__select-${plan.name}-plan`)}
              disabled={!fromValidSubscriptionCheck}
              onClick={() => {
                setRenewPlanId(plan.id);
              }}
              title={currentPlan?.id === plan.id ? "Current plan" : "Selected plan"}
            />
          ) : (
            <Button.Secondary
              id={utils.ifDev(`Manage-subscription__select-${plan.name}-plan`)}
              onClick={() => {
                setRenewPlanId(plan.id);
              }}
              title={currentPlan?.id === plan.id ? "Current plan" : "Select plan"}
            />
          )}
        </>
      );
    }

    return (
      <>
        {currentPlan?.id === plan.id ? (
          <Button.Primary
            id={utils.ifDev(`Manage-subscription__select-${plan.name}-plan`)}
            disabled={!fromValidSubscriptionCheck}
            onClick={() => {
              if (fromValidSubscriptionCheck) {
                paymentRequest(plan.id, true);
              } else {
                return;
              }
            }}
            title={"Current plan"}
          />
        ) : (
          <Button.Secondary
            id={utils.ifDev(`Manage-subscription__select-${plan.name}-plan`)}
            onClick={() => {
              if (fromValidSubscriptionCheck) {
                setRenewPlanId(plan.id);
              } else {
                paymentRequest(plan.id);
              }
            }}
            isLoading={loadingState.paymentRequest && loadingState.planId === plan.id}
            title={"Select plan"}
          />
        )}
      </>
    );
  };

  // Render methods
  // plans will be rendered with a border depending on if a plan renewal check or not
  const renderPlan = (plan: IPlan) => {
    let descArr: any = plan && plan.description && plan.description.split("\n");
    return (
      <div
        className={`py-2 px-4 min-h-35 bg-white rounded-lg shadow-md sm:p-3 dark:bg-gray-800 ${
          (!renewPlanId && currentPlan?.id === plan.id) || renewPlanId === plan.id
            ? "border-4 border-primary"
            : "border dark:border-gray-700"
        }`}
        style={{ width: "19%", minWidth: "236px" }}
        key={plan.id}
      >
        <h5 className="my-6 text-xl font-bold">{plan.name.toUpperCase()}</h5>
        <div className="flex items-baseline justify-center text-gray-900 dark:text-white">
          <span className="font-size-xxl font-extrabold whitespace-nowrap">
            {numberUtils.formatNumberWithCurrency(plan.default_cost / 1.15, false, true)}
          </span>
          <span className="ml-1 text-xl font-normal text-gray-500 dark:text-gray-400 whitespace-nowrap">
            / month
          </span>
        </div>
        <div className={"text-sm text-muted -mt-2"}>
          {numberUtils.formatNumberWithCurrency(plan.default_cost, false, true)} incl. VAT
        </div>
        <Button.ButtonsPanel center>{renderPlanButtons(plan)}</Button.ButtonsPanel>
        <div className={"px-2"}>
          <h6 className={"align-text-left font-normal mt-6 font-semibold mt-6"}>WHAT'S INCLUDED</h6>
          <div className={"mt-4"}>
            {descArr?.map((item: any, i: number) => {
              return (
                <div key={i} className={"flex gap-4 items-start text-left my-2"}>
                  <FontAwesomeIcon icon={"check"} color={" #1abc9c"} className={"mt-1"} />
                  <span>{item}</span>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  };

  function renderHeader() {
    if (store && store.account && store.account?.plan?.name === "Trial") {
      const currentDate = moment(Date.now());
      const createdDate = moment(store.account.plan_expiry_date);
      const duration = moment.duration(createdDate.diff(currentDate)).asDays() - 1;

      if (Math.ceil(duration) < 0) {
        return (
          <>
            <>
              <h5 className="my-4 text-xl">
                <b>Your trial has expired.</b>
              </h5>
              <h6 className="mb-10">Please select a plan to continue using our services.</h6>
            </>
          </>
        );
      }
      return (
        <>
          <h5 className="my-4 text-xl">
            You are currently on the{" "}
            <b data-test={utils.ifDev("Manage-subscription__current-plan")}>
              {currentPlan?.name} plan
              {currentPlan?.default_cost !== 0 && (
                <>
                  {" "}
                  at{" "}
                  {numberUtils.formatNumberWithCurrency(
                    currentPlan && currentPlan?.default_cost / 1.15,
                    false,
                    true
                  )}{" "}
                  (excl. VAT)
                  {" per month"}
                </>
              )}
              .
            </b>
          </h5>
          <h6 className="mb-10">{headerText}</h6>
        </>
      );
    }
    return (
      <>
        <h5 className="my-4 text-xl">
          You are currently on the{" "}
          <b data-test={utils.ifDev("Manage-subscription__current-plan")}>
            {currentPlan?.name} plan
            {currentPlan?.default_cost !== 0 && (
              <>
                {" "}
                at{" "}
                {numberUtils.formatNumberWithCurrency(
                  currentPlan && currentPlan?.default_cost / 1.15,
                  false,
                  true
                )}{" "}
                (excl. VAT)
                {" per month"}
              </>
            )}
            .
          </b>
        </h5>
        <h6 className="mb-10">{headerText}</h6>
      </>
    );
  }

  return (
    <>
      <Modal.Large
        onHide={() => onHide()}
        show={true}
        closeButton={closeButton ? closeButton : false}
        title={title}
      >
        <div className={"align-text-center mb-4"}>
          {renderHeader()}
          <div className={"flex flex-wrap gap-4 items-center justify-center align-text-center"}>
            {loadingState.gettingPlans ? (
              <Loader.Modal />
            ) : (
              <>{plans.map(plan => renderPlan(plan))}</>
            )}
          </div>
          <h6 className="my-8">
            All payments are billed in South African Rand and inclusive of VAT.{" "}
            {utils.isDev() && <>You may cancel your active subscription at any time.</>}
          </h6>
          <Button.ButtonsPanel center>
            <Confirm
              title={"Cancel subscription"}
              onConfirm={() => {}}
              body={"Are you sure you want to cancel your active subscription?"}
              confirmText={"Confirm"}
              cancelText={"Cancel"}
              showCancelButton={true}
            >
              {/*// only display when dev and no valid subscription check*/}
              {!fromValidSubscriptionCheck && utils.isDev() && (
                <Button.Cancel title={"Cancel subscription"} className={"w-full"} />
              )}
            </Confirm>
            {fromValidSubscriptionCheck && (
              <Button.Primary
                title={"Continue to payment"}
                disabled={
                  !renewPlanId || !roleUtils.hasPermission(store.user, "API_/payments:POST")
                }
                onClick={() => {
                  if (renewPlanId) {
                    paymentRequest(renewPlanId, true);
                  }
                }}
              />
            )}
          </Button.ButtonsPanel>
        </div>
      </Modal.Large>
      {modalToShow === "downgrade" && planChangeBody && (
        <Modal.Small
          onHide={() => {
            setModalToShow("");
            setPlanChangesBody(null);
          }}
          show={true}
          closeButton={true}
          title={"Confirm subscription"}
        >
          {generalUtils.showError(error.planUpdate)}
          <div data-test={utils.ifDev("Account-details__plan-downgrade-modal-message")}>
            {planChangeBody.description}
          </div>
          <Button.ButtonsPanel>
            <Button.Cancel
              id={utils.ifDev("Manage-subscription__cancel-downgrade")}
              onClick={() => {
                setModalToShow("");
                setPlanChangesBody(null);
              }}
            />
            <Button.Primary
              id={utils.ifDev("Manage-subscription__confirm-downgrade")}
              title={"Ok"}
              isLoading={loadingState.planUpdate}
              disabled={loadingState.planUpdate}
              onClick={() => {
                accountPlanUpdate(planChangeBody.plan_id);
              }}
            />
          </Button.ButtonsPanel>
        </Modal.Small>
      )}
      {modalToShow === "upgrade" && planChangeBody && (
        <>
          {planChangeBody.amount === 0 ? (
            <Modal.Small show={true} closeButton={true} title={"Manage subscription"}>
              {generalUtils.showError(error.planUpdate)}
              <div className={"mb-2"}>
                You will be upgraded to the {planChangeBody.new_plan?.name} plan.
              </div>
              <div>{planChangeBody.description}</div>
              <Button.ButtonsPanel>
                <Button.Cancel
                  onClick={() => {
                    setModalToShow("");
                    setPlanChangesBody(null);
                  }}
                />
                <Button.Primary
                  title={"Ok"}
                  isLoading={loadingState.planUpdate}
                  disabled={loadingState.planUpdate}
                  onClick={() => {
                    accountPlanUpdate(planChangeBody.plan_id);
                  }}
                />
              </Button.ButtonsPanel>
            </Modal.Small>
          ) : (
            <MakePaymentsModal
              onHide={() => setModalToShow("")}
              planUpgrade
              paymentItems={planChangeBody}
              makeAccountCall={false}
              title={"Make payment"}
              displayPaymentStatus
              fromManageSubscription={fromManageSubscription}
              onComplete={async () => {
                onComplete();
                setModalToShow("");
              }}
            />
          )}
        </>
      )}
    </>
  );
}

ManageSubscription.defaultProps = {
  title: "Manage subscription",
  headerText:
    "You may change your current plan to any of those listed below. Please note that no refund will be given if you downgrade.",
  closeButton: true
};

export default ManageSubscription;
