// Regex for an email address based on international standard (RFC 5322)
const emailRegex =
  /^(([^<>()\[\]\.,;:\s@\"À-ÖØ-öø-ÿ]+(\.[^<>()\[\]\.,;:\s@\"À-ÖØ-öø-ÿ]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"À-ÖØ-öø-ÿ]+\.)+[^<>()[\]\.,;:\s@\"À-ÖØ-öø-ÿ]{2,})$/;

// Regex for a whole number with two fractions
const decimalDigitsWithTwoFractionsRegex = /^(?:\d*\.\d{1,2}|\d+)$/;

// Regex for a whole number with three fractions
const decimalDigitsWithThreeFractionsRegex = /^(?:\d*\.\d{1,3}|\d+)$/;

// Regex for an integer number
const integerNumberRegex = /^[+-]?[0-9]+$/;

// Regex for an unlimited amount of whole numbers [0-9]
const decimalDigitRegex = /^\d+$/;

/*
  Name validation
  * Must have a first name
  * Last / middle name is optional
  * only dash ( - ) allowed as special character, no others allowed
  * all accented characters allowed
*/
const emailNameRegex = /^[a-zA-Z\u00C0-\u017F-.]+(\s[a-zA-Z\u00C0-\u017F-.]+)*$/;

const nameRegex = /.*[a-zA-Z].*/;

// Regex for decimal digits that are 3 characters long
const threeDecimalDigitRegex = /^\d{3}$/;

// Regex to match customised fully qualified domain name with scheme
const fqdnWithSchemeRegex = /^(ftp|http|https):\/\/[^ "]+$/;

export {
  emailRegex,
  decimalDigitsWithTwoFractionsRegex,
  decimalDigitsWithThreeFractionsRegex,
  integerNumberRegex,
  decimalDigitRegex,
  nameRegex,
  threeDecimalDigitRegex,
  fqdnWithSchemeRegex,
  emailNameRegex
};
