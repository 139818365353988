import { Component } from "react";
import { withRouter } from "react-router-dom";

// Used to generate UUIDs
import { v4 as uuidv4 } from "uuid";

// Used for AWS authentication
import Auth from "@aws-amplify/auth";

// Used to retrieve the latest version string
import axios from "axios";

// Used to check current app version
import packageJson from "../package.json";

// Only load relevant FontAwesome icons
import "setupIcons";

// Mitt is used to emit events from bottom level components to this component e.g. for showing toasts
import mitt from "mitt";

// Data store storing App wide state
import { cacheUtils, createStore, generalUtils, withStore } from "uafrica-ui-framework";

import config from "config";
import * as utils from "utils/utils";
import * as localRoleUtils from "utils/roleUtils";

// Custom page loader component
import MaintenancePage from "pages/auth/MaintenancePage";
import PageLayout from "components/PageLayout";
import { Modal, Button, Loader, Message, roleUtils } from "uafrica-ui-framework";
import AccountClosedPage from "pages/auth/AccountClosedPage";
import { IResult } from "interfaces/result.interface";
import { ErrorBoundary } from "react-error-boundary";
import ErrorState from "components/shared/ErrorState";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TagManager from "react-gtm-module";
import rg4js, { RaygunPayload } from "raygun4js";
import { IAnnouncement } from "interfaces/announcement.interface";
import { IUser } from "interfaces/user.interface";
import * as mapUtils from "utils/mapUtils";
import onboardingData from "components/onboarding/data.json";
import { IStore } from "interfaces/store.interface";

let { title } = config;
const emitter = mitt();

interface IProps {
  store: IStore;
  history: any;
  location: any;
}

interface IState {
  toasts: any;
  isAuthenticating: boolean;
  error: any;
  alertModal: any;
  newVersionAvailable: boolean;
  isDesktop: boolean;
  isNavbarCollapsed: boolean;
  showNav: boolean;
  sectionsLoaded: any;
  token: any;
  showLoggingOut: boolean;
  unreadAlertsCount: number;
  userTerms: boolean;
  announcements: IAnnouncement[];
  userOnboardingSetup: boolean;
}

class App extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    utils.checkTime(this.props.store);
    let isNavbarCollapsed = localStorage.getItem("is_navbar_collapsed");

    this.state = {
      isAuthenticating: true,
      error: undefined,
      toasts: [],
      alertModal: null,
      newVersionAvailable: false,
      isDesktop: window.innerWidth >= 992,
      isNavbarCollapsed: Boolean(isNavbarCollapsed && JSON.parse(isNavbarCollapsed)),
      token: undefined,
      showNav: false,
      sectionsLoaded: {
        desktopLogo: false,
        mobileLogo: false,
        navItems: false
      },
      showLoggingOut: false,
      unreadAlertsCount: 0,
      userTerms: false,
      announcements: [],
      userOnboardingSetup: false
    };

    // ensures the navbar is not in collapsed mode whenever switching to mobile view
    window.addEventListener("resize", () => {
      let isNavbarCollapsed = localStorage.getItem("is_navbar_collapsed");

      window.innerWidth < 992
        ? this.setState({ isDesktop: false, isNavbarCollapsed: false })
        : this.setState({
            isDesktop: true,
            isNavbarCollapsed: Boolean(isNavbarCollapsed && JSON.parse(isNavbarCollapsed))
          });
    });

    setInterval(async () => {
      if (this.props.store.inFocus) {
        await this.getAccount();
      }
    }, 5 * 60 * 1000); // 5 minutes

    setInterval(async () => {
      await this.checkPackageVersion();
    }, 20 * 60 * 1000); // 20 minutes

    setInterval(async () => {
      if ((await Auth.currentCredentials()).authenticated) {
        await this.getGeneralData();
      }
    }, 60 * 60 * 1000); // 60 minutes

    setInterval(async () => {
      if (this.props.store.inFocus) {
        await this.getAnnouncements(this.props.store.user);
      }
    }, 30 * 60 * 1000); // 30 minutes

    // The listener
    this.props.history.listen((location: any) => {
      rg4js("trackEvent", { type: "pageView", path: location.pathname });
    });

    this.props.store.set("configUrl", config.api);
  }

  async componentDidMount() {
    this.createRaygunLogger();
    this.getTagManager();
    this.setupEmitter();
    this.checkMaintenanceMode();

    document.title = title;

    this.props.store.set("inFocus", true);

    window.addEventListener("focus", () => {
      this.props.store.set("inFocus", true);
    });

    window.addEventListener("blur", () => this.props.store.set("inFocus", false));

    try {
      if (await Auth.currentSession()) {
        await this.userHasAuthenticated(true);
      } else {
        this.setState({ isAuthenticating: false });
      }
    } catch (e) {
      console.log(e);
      await this.userHasAuthenticated(false);
      this.setState({ isAuthenticating: false });
    }

    await this.checkPackageVersion();
    utils.checkBrowserVersion(this.props.store);
  }

  componentWillUnmount() {
    emitter.off("showToast", this.showToast);
  }

  createRaygunLogger = () => {
    rg4js("apiKey", config.raygunKey);
    rg4js("enableCrashReporting", true);
    rg4js("setVersion", `${packageJson.version}`);
    rg4js("enablePulse", false);
    rg4js("options", {
      // remove if you want to have it working on dev as well
      excludedHostnames: ["localhost", "127.0.0.1:9100", "127.0.0.1"],
      // Will include later if 3rd party not working.
      // captureUnhandledRejections: false,
      ignore3rdPartyErrors: true
    });
    rg4js("logContentsOfXhrCalls", true);
    const myBeforeSend = function (payload: RaygunPayload) {
      if (payload.Details.Error.Message.includes("Failed to write to device")) {
        return false;
      }
      return payload;
    };
    rg4js("onBeforeSend", myBeforeSend);

    rg4js("withTags", [`${packageJson.version}`, "front-end"]);
  };

  getTagManager = () => {
    // include conversion to see if Google Ads work
    TagManager.initialize({
      gtmId: config.gtmId
    });
  };

  setupEmitter = () => {
    this.props.store.set("emitter", emitter);
    emitter.on("showToast", this.showToast);
    emitter.on("showAlert", this.showAlert);
    emitter.on("refreshAccount", this.getAccount);
    emitter.on("accountClosed", this.accountClosed);
    emitter.on("logOut", this.handleLogout);
    emitter.on("maintenanceMode", this.enterMaintenanceMode);
    emitter.on("reloadUnreadAlerts", this.getAccount);
    emitter.on("loadGoogleMaps", this.loadGoogleMaps);
  };

  loadGoogleMaps = () => {
    if (!this.props.store.isGoogleMapsLoaded) {
      mapUtils.loadGoogleMaps(this.props.store);
    }
  };

  enterMaintenanceMode = () => {
    let location = window.location.pathname;
    let q = `?r=${location}`;
    if (location === "/maintenance") {
      q = window.location.search;
    }
    this.props.history.push("/maintenance" + q);
    setTimeout(() => {
      window.location.reload();
    }, 5 * 60000 + Math.round(Math.random() * 60000)); // 5 minutes plus a random minute
  };

  checkMaintenanceMode = () => {
    let location = window.location.pathname;
    if (location === "/maintenance") {
      const urlParams = new URLSearchParams(window.location.search);
      let redirect = urlParams.get("r");
      redirect = redirect ? redirect : "/";

      this.props.history.push(redirect);
    }
  };

  checkPackageVersion = async () => {
    if (window.location.hostname === "localhost") return;

    try {
      const response = await axios.get(window.location.origin + "/version.json");
      if (response.status === 200) {
        let semver = require("semver");

        if (semver.gt(response.data.version, packageJson.version)) {
          console.log("New version available");
          this.setState({ newVersionAvailable: true });
          let diff = semver.diff(response.data.version, packageJson.version);
          if (diff === "major") {
            // version x.y.z -> fires on x (major) changes
            this.props.store.emitter.emit("showAlert", {
              title: "New version available",
              body: (
                <div className="space-y-4 text-center">
                  <div className="flex items-center justify-center mt-2">
                    <div className="rounded-full h-20 w-20 bg-yellow-100 flex items-center justify-center ">
                      <FontAwesomeIcon icon="smile" className={`text-yellow`} size="3x" />
                    </div>
                  </div>
                  <div>A major new version of the app is available.</div>
                  <div>
                    <strong>Please refresh</strong> to make sure your app is up to date with all the
                    awesome features!
                  </div>
                </div>
              ),
              showOkButton: true,
              showCancelButton: false,
              okButtonText: "Refresh now",
              okButtonVariant: "primary",
              disablePressEscToClose: true,
              return: async (confirm: boolean) => {
                if (confirm) {
                  location.reload();
                }
              }
            });
          } else if (diff === "minor") {
            // version x.y.z -> fires on  y (minor) changes
            this.props.store.emitter.emit("showAlert", {
              title: "New version available",
              body: (
                <div className="space-y-4 text-center">
                  <div className="flex items-center justify-center mt-2">
                    <div className="rounded-full h-20 w-20 bg-yellow-100 flex items-center justify-center ">
                      <FontAwesomeIcon icon="smile" className={`text-yellow`} size="3x" />
                    </div>
                  </div>
                  <div>A new version of the app is available.</div>
                  <div>
                    <strong>Please refresh</strong> to ensure you're up to date with all the awesome
                    new features!
                  </div>
                  <div>
                    We'll remind you again in a little while if you're in the middle of something.
                  </div>
                </div>
              ),
              showOkButton: true,
              showCancelButton: true,
              okButtonText: "Refresh now",
              okButtonVariant: "primary",
              cancelButtonText: "I'll refresh later",
              return: async (confirm: boolean) => {
                if (confirm) {
                  location.reload();
                }
              }
            });
          }
        } else {
          this.setState({ newVersionAvailable: false });
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  accountClosed = async () => {
    this.props.history.push("/account-closed");
  };

  getGeneralData = async () => {
    let { data, error } = await utils.getGeneralData(this.props.store);

    if (error) {
      this.setState({ error });
      data = {};
    }

    let accountPackageSettings: any = {};

    if (data.package_settings) {
      accountPackageSettings = data.package_settings;
    }

    let userSettings: any = {};
    let systemConfig: any = {};
    let accountSettings: any = {};

    if (data.user_settings) {
      data.user_settings.forEach((setting: any) => {
        try {
          userSettings[setting.setting] = JSON.parse(setting.value);
        } catch (e) {
          userSettings[setting.setting] = setting.value;
        }
      });
    }

    // Create onboarding account settings here
    if (
      utils.isDev() &&
      data.account_settings &&
      !data.account_settings.hasOwnProperty("onboarding_completed")
    ) {
      let onboarding_completed = {
        account_id: data.account.id,
        setting: "onboarding_completed",
        value: "false"
      };
      accountSettings = {
        ...accountSettings,
        onboarding_completed: {
          setting: "onboarding_completed"
        }
      };

      let { resData } = await utils.updateAccountSetting(this.props.store, onboarding_completed);

      try {
        accountSettings["onboarding_completed"].value = JSON.parse(resData.value);
      } catch (e) {
        accountSettings["onboarding_completed"].value = resData.value;
      }
    }

    if (utils.isDev() && data.account_settings && !data.account_settings.onboarding) {
      let onboarding_setting = {
        onboarding: {
          account_id: data.account.id,
          setting: "onboarding",
          value: onboardingData.onboarding.value
        }
      };

      accountSettings = {
        ...accountSettings,
        onboarding: {
          setting: "onboarding"
        }
      };

      let { resData } = await utils.updateAccountSetting(
        this.props.store,
        onboarding_setting.onboarding
      );
      try {
        accountSettings["onboarding"].value = JSON.parse(resData.value);
      } catch (e) {
        accountSettings["onboarding"].value = resData.value;
      }
    }

    if (data.config) {
      for (let configObject of data.config) {
        try {
          configObject.value = JSON.parse(configObject.value);
        } catch (_) {}
        systemConfig[configObject.key] = configObject;
      }
    }

    let account: any = {};
    if (data && data.account) {
      let absCL: number = Math.abs(data.account.credit_limit);
      let absBal: number = Math.abs(data.account.balance);
      let reaching_credit_limit: boolean = false;
      let credit_limit_reached: boolean = false;
      if (data.account.balance < 0 && absBal / absCL > 0.8) {
        reaching_credit_limit = true;
      }
      if (absBal > absCL) {
        credit_limit_reached = true;
      }
      account["reaching_credit_limit"] = reaching_credit_limit;
      account["credit_limit_reached"] = credit_limit_reached;
      if (account.balance < 0) {
        account["remaining_balance"] = account.balance + account.credit_limit;
      } else {
        account["remaining_balance"] = Math.abs(account.balance - account.credit_limit);
      }
    }
    this.setState({ unreadAlertsCount: { ...data.account, ...account }.unread_alerts_count });

    this.props.store.set("user_settings", userSettings);
    this.props.store.set("service_levels", data.service_levels ?? []);
    this.props.store.set("account_settings", {
      ...data.account_settings,
      ...accountPackageSettings,
      ...accountSettings
    });
    this.props.store.set("channel_types", data.channel_types ?? []);
    this.props.store.set("providers", data.providers ?? []);
    this.props.store.set("service_levels", data.service_levels ?? []);
    this.props.store.set("user", data.user);
    this.props.store.set("account", { ...data.account, ...account });
    this.props.store.set("system_config", systemConfig);
    this.props.store.set("default_collection_address", data.default_collection_address);
    this.props.store.set("account_channels", data.channels);

    cacheUtils.setInStore(this.props.store, "roles", data.roles);

    return { data, error };
  };

  getAccount = async (initialCall: boolean = false) => {
    let { account, error } = await utils.getAccount(this.props.store);

    if (!account && !error) return;

    let reaching_credit_limit: boolean = false;
    let credit_limit_reached: boolean = false;

    if (account) {
      let absCL: number = Math.abs(account.credit_limit);
      let absBal: number = Math.abs(account.balance);
      if (account.balance < 0 && absBal / absCL > 0.8) {
        reaching_credit_limit = true;
      }
      if (absBal > absCL) {
        credit_limit_reached = true;
      }
      account["reaching_credit_limit"] = reaching_credit_limit;
      account["credit_limit_reached"] = credit_limit_reached;
      if (account.balance < 0) {
        account["remaining_balance"] = account.balance + account.credit_limit;
      } else {
        account["remaining_balance"] = Math.abs(account.balance - account.credit_limit);
      }
      this.props.store.set("account", account);
      this.setState({ unreadAlertsCount: account.unread_alerts_count });
    } else if (initialCall) {
      this.setState({
        error
      });
    }
  };

  getAnnouncements = async (user: IUser) => {
    if (user?.account_id && roleUtils.hasPermission(user, "API_/announcements:GET")) {
      try {
        const args: any = {
          active: true
        };
        const res: IResult = await utils.signedRequest(
          this.props.store,
          "/announcements",
          "GET",
          args
        );
        if (res.ok) {
          if (res.data.announcements) {
            this.setState({
              announcements: res.data.announcements
                .filter((announcement: IAnnouncement) => {
                  return announcement.placements.indexOf("header") >= 0;
                })
                .sort(
                  (a: any, b: any) =>
                    Date.parse(new Date(b.time_modified).toISOString()) -
                    Date.parse(new Date(a.time_modified).toISOString())
                )
            });
          } else {
            this.setState({ announcements: [] });
          }
        }
      } catch (e) {
        console.error(generalUtils.getError(e, true));
      }
    }
  };

  userHasAuthenticated = async (
    authenticated: any,
    latestRoleResponse?: any,
    showLoggingOut?: boolean
  ) => {
    let { store, history } = this.props;
    this.setState({ showLoggingOut: Boolean(showLoggingOut) });

    let token = null;

    // valid user
    if (authenticated) {
      let session: any = await Auth.currentSession();

      token = session.idToken;

      const urlParams = new URLSearchParams(window.location.search);
      let impersonated_user_id = urlParams.get("impersonate_id");
      if (impersonated_user_id) {
        store.set("impersonated_user_id", impersonated_user_id);
        localStorage.setItem("impersonated_user_id", impersonated_user_id);
      } else {
        impersonated_user_id = localStorage.getItem("impersonated_user_id");
        if (impersonated_user_id) {
          try {
            store.set("impersonated_user_id", impersonated_user_id);
          } catch (e) {
            console.log(e);
          }
        }
      }
      let { data, error } = await this.getGeneralData();

      await this.getAnnouncements(data.user);

      if (data && data.account) {
        await utils.getUserOnboardingSettings(store, "onboarding");
        await this.setState({ userOnboardingSetup: true });
      }

      if (!error) {
        latestRoleResponse = data.roles;

        roleUtils.fillRole(data.user, latestRoleResponse, this.props.store);
        store.set("logged_in_user", data.user);
      } else {
        store.set("impersonated_user_id", undefined);
        store.set("logged_in_user", undefined);
      }
    } else {
      store.set("impersonated_user_id", undefined);
      store.set("account", undefined);
      store.set("logged_in_user", undefined);
      store.set("user_settings", undefined);
    }

    this.setState(
      {
        isAuthenticating: false,
        token: token
      },
      () => {
        if (authenticated) {
          const urlParams = new URLSearchParams(window.location.search);
          let redirect = urlParams.get("redirect");
          if (redirect) {
            // will only perform split if redirect exists
            const redirectUrl = window.location.search.split("redirect=")[1];
            history.push(redirectUrl);
          } else if (
            window.location.pathname.indexOf("/login") >= 0 ||
            window.location.pathname.indexOf("/register") >= 0
          ) {
            history.push("/");
          }
        }
      }
    );
  };

  handleLogout = async () => {
    let activeUser = utils.activeUser(this.props);
    await Auth.signOut();
    await this.userHasAuthenticated(false, undefined, true);
    // on logout clear cookies
    let accountClosed = localStorage.getItem("accountClosed");
    sessionStorage.clear();
    localStorage.clear();
    if (accountClosed) {
      localStorage.setItem("accountClosed", accountClosed);
    }
    let location = `/`;
    if (localRoleUtils.isStaff(activeUser) && this.props.location.pathname !== "/logout") {
      location = `/login?redirect=${this.props.location.pathname}${this.props.location.search}`;
    }

    window.location.href = location; // using window.location.href to ensure amplify does not keep cached user on logout
  };

  hideToast = (uuid: any) => {
    let { toasts } = this.state;
    toasts = toasts.filter((toast: any) => toast.uuid !== uuid);
    this.setState({ toasts });
  };

  showToast = (message: any) => {
    let { toasts } = this.state;

    message.uuid = uuidv4();

    toasts.push(message);
    this.setState({ toasts });

    if (message.autoHide && message.autoHide > 0) {
      setTimeout(() => this.hideToast(message.uuid), message.autoHide);
    }
  };

  showAlert = (alertObject: any) => {
    this.setState({ alertModal: alertObject });
  };

  onNavSectionReady = (property: any) => {
    let { sectionsLoaded } = this.state;
    sectionsLoaded[property] = true;

    let isAllReady = true;
    Object.keys(sectionsLoaded).forEach(key => {
      if (!sectionsLoaded[key]) {
        isAllReady = false;
      }
    });
    this.setState({ showNav: isAllReady });
  };

  /* --------------------------------*/
  /* RENDER METHODS */
  /* --------------------------------*/

  renderAlertModal() {
    const { alertModal } = this.state;
    if (!alertModal) return;

    return (
      alertModal && (
        <Modal.Small
          title={alertModal.title}
          show={true}
          closeButton={false}
          onHide={() => this.setState({ alertModal: null })}
        >
          {alertModal.body}
          <Modal.ButtonsPanel>
            {alertModal.showCancelButton && (
              <Button.Cancel
                id="modal_cancel_button"
                onClick={() => {
                  alertModal.return(false);
                  this.setState({ alertModal: null });
                }}
                title={alertModal.cancelButtonText ? alertModal.cancelButtonText : "Cancel"}
              />
            )}
            {alertModal.showOkButton && alertModal.okButtonVariant === "danger" && (
              <Button.Danger
                id="confirm_button_modal"
                onClick={() => {
                  alertModal.return(true);
                  this.setState({ alertModal: null });
                }}
                title={alertModal.okButtonText ? alertModal.okButtonText : "OK"}
              />
            )}
            {alertModal.showOkButton && alertModal.okButtonVariant !== "danger" && (
              <Button.Danger
                id="confirm_button_modal"
                onClick={() => {
                  alertModal.return(true);
                  this.setState({ alertModal: null });
                }}
                title={alertModal.okButtonText ? alertModal.okButtonText : "OK"}
              />
            )}
          </Modal.ButtonsPanel>
        </Modal.Small>
      )
    );
  }
  renderToasts() {
    const { toasts } = this.state;

    if (toasts.length === 0) return;

    return (
      <div className="toasts">
        {toasts.map((toast: any) => {
          let content = (
            <div
              className="cursor-pointer"
              onClick={() => {
                this.hideToast(toast.uuid);
              }}
            >
              {toast.text}
            </div>
          );

          if (toast.variant === "error") {
            return (
              <Message.Error shadow key={toast.uuid}>
                {content}
              </Message.Error>
            );
          } else if (toast.variant === "success") {
            return (
              <Message.Success shadow key={toast.uuid}>
                {content}
              </Message.Success>
            );
          } else {
            return (
              <Message.Info shadow key={toast.uuid}>
                {content}
              </Message.Info>
            );
          }
        })}
      </div>
    );
  }

  render() {
    const {
      token,
      isAuthenticating,
      error,
      isNavbarCollapsed,
      showNav,
      newVersionAvailable,
      isDesktop,
      showLoggingOut,
      unreadAlertsCount,
      userOnboardingSetup
    } = this.state;

    if (isAuthenticating) {
      return <Loader.Page />;
    }

    let { account_settings, account_channels, account } = this.props.store;

    let activeUser = utils.activeUser(this.props);
    let activeAccount = this.props.store.account;

    if (showLoggingOut) {
      return <Loader.Page />;
    }

    if (utils.isAccountClosedPage()) {
      return <AccountClosedPage />;
    }

    if (error) {
      return <Message.Error>{error}</Message.Error>;
    }

    if (utils.isMaintenanceModePage()) {
      return <MaintenancePage error={error} />;
    }

    if (
      activeUser &&
      activeUser?.account_id &&
      (!activeAccount || !account_settings || !account_channels || !account || !userOnboardingSetup)
    ) {
      return <Loader.Page />;
    }

    return (
      <div>
        <ErrorBoundary FallbackComponent={ErrorState}>
          {/* @ts-ignore*/}
          <PageLayout
            onNavSectionReady={this.onNavSectionReady}
            token={token}
            userHasAuthenticated={this.userHasAuthenticated}
            activeUser={activeUser}
            showNav={showNav}
            newVersionAvailable={newVersionAvailable}
            version={packageJson.version}
            isNavbarCollapsed={isNavbarCollapsed}
            setNavbarCollapsed={(value: any) => this.setState({ isNavbarCollapsed: value })}
            isDesktop={isDesktop}
            unreadAlertsCount={unreadAlertsCount}
            announcements={this.state.announcements}
          />
          {this.renderAlertModal()}
          {this.renderToasts()}
          <Modal.Host />
        </ErrorBoundary>
      </div>
    );
  }
}

// @ts-ignore
export default createStore(withRouter(withStore(App)));
