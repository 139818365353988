import * as utils from "utils/utils";
import { IStore } from "interfaces/store.interface";
import { IShipment } from "interfaces/shipment.interface";
import { useEffect, useState } from "react";
import { generalUtils, useStore } from "uafrica-ui-framework";
import * as localRoleUtils from "utils/roleUtils";

const BrowserPrint = (function () {
  function g(a: any, d: any) {
    var c = new XMLHttpRequest();
    "withCredentials" in c
      ? c.open(a, d, !0)
      : // @ts-ignore
      "undefined" != typeof XDomainRequest
      ? // @ts-ignore
        ((c = new XDomainRequest()), c.open(a, d))
      : // @ts-ignore
        (c = null);
    return c;
  }
  function h(a: any, d: any, c: any, b: any) {
    void 0 != a &&
      (void 0 == c && (c = a.sendFinishedCallback), void 0 == b && (b = a.sendErrorCallback));
    d.onreadystatechange = function () {
      d.readyState === XMLHttpRequest.DONE && 200 === d.status
        ? c(d.responseText)
        : d.readyState === XMLHttpRequest.DONE &&
          (b ? b(d.responseText) : console.log("error occurred with no errorCallback set."));
    };
    return d;
  }
  let e = {
      name: undefined,
      deviceType: undefined,
      connection: undefined,
      uid: undefined,
      version: 2,
      provider: undefined,
      manufacturer: undefined,
      sendErrorCallback: undefined,
      sendFinishedCallback: undefined,
      send: undefined,
      sendUrl: undefined,
      readErrorCallback: undefined,
      readFinishedCallback: undefined,
      read: undefined,
      sendThenRead: undefined,
      ApplicationConfiguration: undefined,
      application: undefined,
      getLocalDevices: undefined,
      getDefaultDevice: undefined,
      getApplicationConfiguration: undefined,
      readOnInterval: undefined,
      stopReadOnInterval: undefined,
      bindFieldToReadData: undefined
    },
    k = {},
    f = "http://127.0.0.1:9100/";
  0 <= navigator.userAgent.indexOf("Trident/7.0") &&
    "https:" === location.protocol &&
    (f = "https://localhost:9101/");
  // @ts-ignore
  e.Device = function (a: any) {
    let d: any = this;
    this.name = a.name;
    this.deviceType = a.deviceType;
    this.connection = a.connection;
    this.uid = a.uid;
    this.version = 2;
    this.provider = a.provider;
    this.manufacturer = a.manufacturer;
    // @ts-ignore
    this.sendErrorCallback = function (c) {};
    // @ts-ignore
    this.sendFinishedCallback = function (c) {};
    // @ts-ignore
    this.send = function (c, a, l) {
      var b = g("POST", f + "write");
      b &&
        (h(d, b, a, l),
        b.send(
          JSON.stringify({
            device: {
              name: this.name,
              uid: this.uid,
              connection: this.connection,
              deviceType: this.deviceType,
              version: this.version,
              provider: this.provider,
              manufacturer: this.manufacturer
            },
            data: c
          })
        ));
    };
    // @ts-ignore
    this.sendUrl = function (c: any, a: any, l: any) {
      var b = g("POST", f + "write");
      b &&
        (h(d, b, a, l),
        b.send(
          JSON.stringify({
            device: {
              name: this.name,
              uid: this.uid,
              connection: this.connection,
              deviceType: this.deviceType,
              version: this.version,
              provider: this.provider,
              manufacturer: this.manufacturer
            },
            url: c
          })
        ));
    };
    // @ts-ignore
    this.readErrorCallback = function (c: any) {};
    // @ts-ignore
    this.readFinishedCallback = function (c) {};
    // @ts-ignore
    this.read = function (c: any, a: any) {
      var b = g("POST", f + "read");
      b &&
        (h(d, b, c, a),
        b.send(
          JSON.stringify({
            device: {
              name: this.name,
              uid: this.uid,
              connection: this.connection,
              deviceType: this.deviceType,
              version: this.version,
              provider: this.provider,
              manufacturer: this.manufacturer
            }
          })
        ));
    };
    // @ts-ignore
    this.sendThenRead = function (a: any, b: any, d: any) {
      // @ts-ignore
      this.send(
        a,
        (function (a) {
          return function () {
            // @ts-ignore
            a.read(b, d);
          };
        })(this),
        d
      );
    };
  };
  // @ts-ignore
  e.ApplicationConfiguration = function () {
    // @ts-ignore
    this.application = {
      version: "1.2.0.3",
      build_number: 3,
      api_level: 2,
      platform: ""
    };
  };
  // @ts-ignore
  e.getLocalDevices = function (a, d, c) {
    var b = g("GET", f + "available");
    const finishedFunction = function (b: any) {
      let response = b;
      response = JSON.parse(response);
      for (var d in response)
        if (response.hasOwnProperty(d) && response[d].constructor === Array)
          // @ts-ignore
          for (let arr = response[d], b = 0; b < arr.length; ++b) arr[b] = new e.Device(arr[b]);
      void 0 == c
        ? a(response)
        : (response.hasOwnProperty(c) || (response[c] = []), a(response[c]));
    };
    // @ts-ignore
    b && (finishedFunction, h(void 0, b, finishedFunction, d), b.send());
  };
  // @ts-ignore
  e.getDefaultDevice = function (a, d, c) {
    var b = "default";
    void 0 != a && null != a && (b = b + "?type=" + a);
    const finishedFunction = function (a: any) {
      let response = a;
      "" == response
        ? d(null)
        : // @ts-ignore
          ((response = JSON.parse(response)), (a = new e.Device(response)), d(a));
    };
    if ((a = g("GET", f + b))) {
      // @ts-ignore
      finishedFunction, (a = h(void 0, a, finishedFunction, c)), a.send();
    }
  };
  // @ts-ignore
  e.getApplicationConfiguration = function (a, d) {
    var c = g("GET", f + "config");
    const finishedFunction = function (b: any) {
      let response = b;
      "" == response ? a(null) : ((response = JSON.parse(response)), a(response));
    };
    // @ts-ignore
    c && (finishedFunction, h(void 0, c, finishedFunction, d), c.send());
  };
  // @ts-ignore
  e.readOnInterval = function (a: any, d: any, c: any) {
    if (void 0 == c || 0 == c) c = 1;
    const readFunc = function () {
      a.read(
        function (b: any) {
          d(b);
          // @ts-ignore
          k[a] = setTimeout(readFunc, c);
        },
        // @ts-ignore
        function (b: any) {
          // @ts-ignore
          k[a] = setTimeout(readFunc, c);
        }
      );
    };
    // @ts-ignore
    k[a] = setTimeout(readFunc, c);
  };
  // @ts-ignore
  e.stopReadOnInterval = function (a) {
    // @ts-ignore
    k[a] && clearTimeout(k[a]);
  };
  // @ts-ignore
  e.bindFieldToReadData = function (a, d, c, b) {
    // @ts-ignore
    e.readOnInterval(
      a,
      function (a: any) {
        "" != a && ((d.value = a), void 0 != b && null != b && b());
      },
      c
    );
  };
  return e;
})();

const connectPrinter = () => {
  return new Promise((resolve, reject) => {
    // @ts-ignore
    BrowserPrint.getDefaultDevice(
      "printer",
      (printer: any) => {
        if (printer != null && printer.connection !== undefined) {
          resolve(printer);
        }
      },
      () => {
        const message =
          "An error occurred while attempting to connect to your Zebra Printer. You may not have Zebra Browser Print installed, or it may not be running. Install Zebra Browser Print, or start the Zebra Browser Print Service, and try again.";
        reject(message);
      }
    );
  });
};

const checkPrinterStatus = (printer: any) => {
  return new Promise((resolve, reject) => {
    //if some strange comms issue is present, handle it
    const timeout = setTimeout(function () {
      reject("No response from printer. Please make sure the printer is connected and turned on.");
    }, 3000);
    //lets clear the print buffer, just incase
    printer.send("~JA");

    //get the printer status
    printer.sendThenRead(
      "~HQES",
      function (text: any) {
        clearTimeout(timeout);

        const statuses: any = [];
        const cleanResponse = text.replace(/\W/g, "");
        //check that we got a status response
        if (cleanResponse.length <= 0) {
          reject(
            "No response from printer. Please make sure the printer is connected and turned on."
          );
        }

        let printerOk = false;
        let printerHasError = cleanResponse.charAt(19);
        let printerMedia = cleanResponse.charAt(35);
        let printerHead = cleanResponse.charAt(34);
        let printerPause = cleanResponse.charAt(31);
        // check each flag that prevents printing
        if (printerHasError === "0") {
          printerOk = true;
          statuses.push("Ready to Print");
        }
        if (printerMedia === "1") {
          statuses.push("Out of paper");
        }
        if (printerMedia === "2") {
          statuses.push("Out of Ribbon");
        }
        if (printerMedia === "4") {
          statuses.push("Media Door Open");
        }
        if (printerMedia === "8") {
          statuses.push("Cutter Fault");
        }
        if (printerHead === "1") {
          statuses.push("Printhead Overheating");
        }
        if (printerHead === "2") {
          statuses.push("Motor Overheating");
        }
        if (printerHead === "4") {
          statuses.push("Printhead Fault");
        }
        if (printerHead === "8") {
          statuses.push("Incorrect Printhead");
        }
        if (printerPause === "1") {
          statuses.push("Printer Paused");
        }
        if (!printerOk && statuses.count === 0) {
          statuses.push("Unknown Error");
        }

        if (printerOk) {
          resolve({ status: statuses.join(" - "), printer });
        } else {
          reject(statuses.join(" - "));
        }
      },
      (errorText: any) => reject(errorText)
    );
  });
};

const printLabel = (printer: any, labelZpl: any) => {
  return new Promise<void>((resolve, reject) =>
    printer.send(
      labelZpl,
      // @ts-ignore
      res => {
        resolve();
      },
      (text: any) => reject(text)
    )
  );
};

const print = (labelZpl: any, store: IStore) => {
  if (labelZpl === "") {
    return;
  }

  // @ts-ignore
  return new Promise<{ complete: boolean; error?: any; status?: any }>((resolve, reject) => {
    if (labelZpl === "") {
      // reject();
      return { complete: true };
    }

    store.emitter.emit("showToast", {
      text: "Checking connection to printer...",
      variant: "info",
      autoHide: 2000
    });
    connectPrinter()
      .then(printer => checkPrinterStatus(printer))
      .then(({ printer }: any) => {
        store.emitter.emit("showToast", {
          text: "Connected to printer, printing label...",
          variant: "info",
          autoHide: 3000
        });
        return printLabel(printer, labelZpl);
      })
      .then(() => {
        store.emitter.emit("showToast", {
          text: "Print complete",
          variant: "success",
          autoHide: 3000
        });

        resolve({ complete: true });
      })
      .catch(error => {
        store.emitter.emit("showToast", {
          text: `Print error: ${generalUtils.getError(error, true)}`,
          variant: "error",
          autoHide: 5000
        });

        // reject(error);
        // @ts-ignore
        resolve({ error: error, status: "failed" });
      });
    return { complete: true };
  });
};

export const printFulfillment = (shipment: IShipment, store: IStore) => {
  let maxRetries = 5;
  let retryCount = 0;

  if (!shipment) {
    return;
  }

  const getDataToPrint = async () => {
    let args = {
      tracking_references: [shipment.tracking_reference],
      type: "zpl"
    };

    try {
      let res = await utils.signedRequest(store, "/shipments/sticker-waybill", "GET", args);

      if (res.data.shipments) {
        let zpl_string = "";
        res.data.shipments.forEach((shipment: any) => {
          shipment.parcels.forEach((parcel: any) => {
            zpl_string += parcel.sticker_waybill_content
              .replaceAll("\n", "")
              .replaceAll("\\&", `\&`);
          });
        });
        print(zpl_string, store);
      } else {
        retryGetData(), retryCount++;
      }

      if (!res.ok) {
        store.emitter.emit("showToast", {
          text: generalUtils.getError(res, true),
          variant: "error",
          autoHide: 5000
        });
      }
    } catch (e) {
      store.emitter.emit("showToast", {
        text: generalUtils.getError(e, true),
        variant: "error",
        autoHide: 5000
      });
    }
  };

  const retryGetData = () => {
    if (retryCount >= maxRetries) {
      store.emitter.emit("showToast", {
        text: `Label for ${shipment.tracking_reference} is not ready for printing. Please try again later.`,
        variant: "error",
        autoHide: 5000
      });
    } else {
      setTimeout(() => {
        if (retryCount === 1) {
          store.emitter.emit("showToast", {
            text: `Label for ${shipment.tracking_reference} is not ready for printing. Retrying, please wait...`,
            variant: "info",
            autoHide: 5000
          });
        }
        getDataToPrint();
      }, 2000);
    }
  };
  store.emitter.emit("showToast", {
    text: `Printing label for ${shipment.tracking_reference}.`,
    variant: "info",
    autoHide: 3000
  });

  getDataToPrint();
};

export const useBulkPrinting = () => {
  const store: IStore = useStore();
  const [isPrinting, setIsPrinting] = useState<boolean>(false);
  const maxRetries: number = 5;
  const [retryCount, setRetryCount] = useState<number>(0);
  const [shipments, setShipments] = useState<any>([]);
  // @ts-ignore
  const [batchCount, setBatchCount] = useState<number>(0);
  const [batchIndex, setBatchIndex] = useState<number>(0);

  useEffect(() => {
    if (shipments.length >= 1 && batchCount) {
      getDataToPrint();
    }
  }, [shipments.length, batchIndex]);

  const printBulkWaybills = async (shipments: string[]) => {
    setBatchCount(0);
    setBatchIndex(0);
    setShipments([]);
    if (shipments.length === 0) {
      return;
    }
    const perChunk = 10; // items per chunk

    const result = shipments.reduce((resultArray: any[], item: string, index: number) => {
      const chunkIndex = Math.floor(index / perChunk);

      if (!resultArray[chunkIndex]) {
        resultArray[chunkIndex] = []; // start a new chunk
      }

      resultArray[chunkIndex].push(item);

      return resultArray;
    }, []);
    setBatchCount(result.length);
    setShipments(result);
  };

  const getDataToPrint = async () => {
    if (!shipments[batchIndex]) {
      return;
    }
    setIsPrinting(true);
    let args = {
      tracking_references: shipments[batchIndex],
      type: "zpl"
    };

    try {
      let res = await utils.signedRequest(store, "/shipments/sticker-waybill", "GET", args);

      if (
        res.data.shipments &&
        res.data.shipments.every((shipment: any) => shipment.submission_status === "success")
      ) {
        let zpl_string = "";
        res.data.shipments.forEach((shipment: any) => {
          shipment.parcels.forEach((parcel: any) => {
            zpl_string += parcel.sticker_waybill_content
              .replaceAll("\n", "")
              .replaceAll("\\&", `\&`);
          });
        });
        // @ts-ignore
        print(zpl_string, store).then((res: any) => {
          if (res.status && res.status === "failed") {
            setShipments([]);
          }
          if (res.complete) {
            setTimeout(() => {
              setBatchIndex(batchIndex + 1);
              if (batchIndex === batchCount) {
                setBatchCount(0);
              }
            }, 20000);
          }
        });

        setIsPrinting(false);
      } else {
        setRetryCount(retryCount + 1);
        retryGetData();
      }

      if (!res.ok) {
        store.emitter.emit("showToast", {
          text: generalUtils.getError(res, true),
          variant: "error",
          autoHide: 5000
        });
        setIsPrinting(false);
      }
    } catch (e) {
      store.emitter.emit("showToast", {
        text: generalUtils.getError(e, true),
        variant: "error",
        autoHide: 5000
      });
      setIsPrinting(false);
    }
  };

  const retryGetData = () => {
    if (retryCount >= maxRetries) {
      store.emitter.emit("showToast", {
        text: `Waybills are not ready for printing. Please try again later.`,
        variant: "error",
        autoHide: 5000
      });
      setIsPrinting(false);
    } else {
      setTimeout(() => {
        if (retryCount === 1) {
          store.emitter.emit("showToast", {
            text: `Waybills are not ready for printing. Retrying, please wait...`,
            variant: "info",
            autoHide: 5000
          });
        }
        getDataToPrint();
      }, 2000);
    }
  };

  return { isPrinting, printBulkWaybills };
};

export const usePrinting = () => {
  const [isPrinting, setIsPrinting] = useState<boolean>(false);

  const printBulkWaybills = async (shipments: string[], store: IStore) => {
    let maxRetries = 5;
    let retryCount = 0;

    if (shipments.length === 0) {
      return;
    }

    const getDataToPrint = async () => {
      setIsPrinting(true);
      let args = {
        tracking_references: shipments,
        type: "zpl"
      };

      try {
        let res = await utils.signedRequest(store, "/shipments/sticker-waybill", "GET", args);

        if (
          res.data.shipments &&
          res.data.shipments.every((shipment: any) => shipment.submission_status === "success")
        ) {
          let zpl_string = "";
          res.data.shipments.forEach((shipment: any) => {
            shipment.parcels.forEach((parcel: any) => {
              zpl_string += parcel.sticker_waybill_content
                .replaceAll("\n", "")
                .replaceAll("\\&", `\&`);
            });
          });
          print(zpl_string, store);
          setIsPrinting(false);
        } else {
          retryGetData(), retryCount++;
        }

        if (!res.ok) {
          store.emitter.emit("showToast", {
            text: generalUtils.getError(res, true),
            variant: "error",
            autoHide: 5000
          });
          setIsPrinting(false);
        }
      } catch (e) {
        store.emitter.emit("showToast", {
          text: generalUtils.getError(e, true),
          variant: "error",
          autoHide: 5000
        });
        setIsPrinting(false);
      }
    };

    const retryGetData = () => {
      if (retryCount >= maxRetries) {
        store.emitter.emit("showToast", {
          text: `Waybills are not ready for printing. Please try again later.`,
          variant: "error",
          autoHide: 5000
        });
        setIsPrinting(false);
      } else {
        setTimeout(() => {
          if (retryCount === 1) {
            store.emitter.emit("showToast", {
              text: `Waybills are not ready for printing. Retrying, please wait...`,
              variant: "info",
              autoHide: 5000
            });
          }
          getDataToPrint();
        }, 2000);
      }
    };
    store.emitter.emit("showToast", {
      text: `Printing waybills.`,
      variant: "info",
      autoHide: 3000
    });

    getDataToPrint();
  };

  const printWaybill = (shipment: IShipment, store: IStore) => {
    let maxRetries = 5;
    let retryCount = 0;

    if (!shipment) {
      return;
    }

    setIsPrinting(true);

    const getDataToPrint = async () => {
      let args: any = {
        tracking_references: [shipment.tracking_reference],
        type: "zpl"
      };

      if (localRoleUtils.isStaff(store.user)) {
        args.account_id = shipment.account_id;
      }

      try {
        let res = await utils.signedRequest(store, "/shipments/sticker-waybill", "GET", args);

        if (!res.ok) {
          store.emitter.emit("showToast", {
            text: generalUtils.getError(res, true),
            variant: "error",
            autoHide: 5000
          });
          setIsPrinting(false);
          return;
        }

        if (
          res.data.shipments &&
          res.data.shipments.every((shipment: any) => shipment.submission_status === "success")
        ) {
          if (res.ok) {
            let zpl_string = "";
            res.data.shipments.forEach((shipment: any) => {
              shipment.parcels.forEach((parcel: any) => {
                zpl_string += parcel.sticker_waybill_content
                  .replaceAll("\n", "")
                  .replaceAll("\\&", `\&`);
              });
            });
            // @ts-ignore
            print(zpl_string, store).then(res => {});
            setIsPrinting(false);
          } else {
            retryGetData(), retryCount++;
          }
        }
      } catch (e) {
        store.emitter.emit("showToast", {
          text: generalUtils.getError(e, true),
          variant: "error",
          autoHide: 5000
        });
        setIsPrinting(false);
      }
    };

    const retryGetData = () => {
      if (retryCount >= maxRetries) {
        store.emitter.emit("showToast", {
          text: `Label for ${shipment.tracking_reference} is not ready for printing. Please try again later.`,
          variant: "error",
          autoHide: 5000
        });
        setIsPrinting(false);
      } else {
        setTimeout(() => {
          if (retryCount === 1) {
            store.emitter.emit("showToast", {
              text: `Label for ${shipment.tracking_reference} is not ready for printing. Retrying, please wait...`,
              variant: "info",
              autoHide: 5000
            });
          }
          getDataToPrint();
        }, 2000);
      }
    };
    store.emitter.emit("showToast", {
      text: `Printing label for ${shipment.tracking_reference}.`,
      variant: "info",
      autoHide: 3000
    });

    getDataToPrint();
  };
  return { isPrinting, printBulkWaybills, printWaybill };
};
