import { useEffect, useState } from "react";
import { generalUtils, useStore } from "uafrica-ui-framework";
import { IStore } from "interfaces/store.interface";
import { useHistory, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

type StepType = {
  viewed: boolean;
  completed: boolean;
  optional: boolean;
  value: string;
  url: string;
  step_id: "address-book" | "settings" | "notifications" | "billing" | "sales-channels" | "";
};

function OnboardingHeader() {
  const store: IStore = useStore();
  const location = useLocation();
  const history = useHistory();
  const [displayOnboardingData, setDisplayOnboardingData] = useState<any>();
  const [activeArr, setActiveArr] = useState<string[]>([]);
  const [activeId, setActiveId] = useState<
    "address-book" | "shipping" | "notifications" | "billing" | "sales-channels" | ""
  >("");

  let displayArr: string[] = [
    "address-book",
    "shipping",
    "notifications",
    "billing",
    "sales-channels"
  ];

  useEffect(() => {
    if (store.account_settings.onboarding) {
      setDisplayOnboardingData(store.account_settings.onboarding.value);
      let onboardingObject = store.account_settings.onboarding.value;

      let firstIncomplete: any = Object.values(store.account_settings.onboarding.value).find(
        (val: any) => !val.completed
      );

      setActiveId(onboardingObject[Object.keys(onboardingObject)[0]].step_id);

      history.push(firstIncomplete?.url ?? "/orders");
    }
  }, [store.account_settings]);

  useEffect(() => {
    if (location.pathname === "/address-book") {
      setActiveId("address-book");
    } else if (
      location.pathname === "/settings?tab=notifications" ||
      location.search === "?tab=notifications"
    ) {
      setActiveId("notifications");
    } else if (
      location.pathname === "/settings?tab=shipping" ||
      location.search === "?tab=shipping"
    ) {
      setActiveId("shipping");
    } else if (
      location.search === "?tab=billing" ||
      location.pathname === "/accounts?tab=billing"
    ) {
      setActiveId("billing");
    } else {
      setActiveId("sales-channels");
    }
  }, [location.pathname, location.search]);

  useEffect(() => {
    let index = displayArr.findIndex(item => item === activeId);

    // Generate array of steps that will be used te set the colour of the step bar
    let newArr = displayArr.slice(0, index + 1);

    setActiveArr(newArr);
  }, [activeId]);

  return (
    <>
      <div className={"onboarding-header"}>
        {activeId &&
          displayOnboardingData &&
          Object.keys(displayOnboardingData).map((item: string) => {
            const displayItem: StepType = displayOnboardingData[item];

            return (
              <div
                className={"onboarding-item cursor-pointer"}
                key={item}
                onClick={() => {
                  history.push(displayItem.url);
                }}
              >
                <div className={"progress-bar"}>
                  <div
                    className={
                      activeArr.indexOf(displayItem.step_id) >= 0
                        ? "border-primary-500 border-b-3 text-sm"
                        : "border-gray-300 border-b-3 text-sm"
                    }
                    style={
                      activeId === displayItem.step_id ? { animation: "progress 1s" } : undefined
                    }
                  />
                </div>
                <div
                  className={
                    activeArr.indexOf(displayItem.step_id) >= 0
                      ? "text-primary-600 text-sm"
                      : "text-gray-300 text-sm"
                  }
                >
                  <div className={"flex items-center gap-2 text-sm font-bold mt-1 sm:mt-0"}>
                    {generalUtils.keyToHumanReadable(generalUtils.capitalize(item))}
                    {displayItem.optional && <span className={"ml-1 text-sm"}>(optional)</span>}

                    {store.account_settings?.onboarding.value[item]?.completed && (
                      <FontAwesomeIcon icon={"check-circle"} color={"#1abc9c"} />
                    )}
                  </div>
                </div>
                <div>
                  <span className={"text-sm"}>{displayItem.value}</span>
                </div>
              </div>
            );
          })}
      </div>
    </>
  );
}

export default OnboardingHeader;
