import { Checkbox, Input, Message, Select } from "uafrica-ui-framework";
import { Controller } from "react-hook-form";
import * as utils from "utils/utils";
import * as localValidationUtils from "utils/validationUtils";

interface IProps {
  paymentMethod: any;
  register: any;
  errors: any;
  control: any;
  setAsDefault?: boolean;
}

function RenderCreditCardDetails({
  paymentMethod,
  register,
  errors,
  control,
  setAsDefault
}: IProps) {
  function generateArrayOfYears() {
    let min = new Date().getFullYear();
    let max = min + 10;
    let years = [];

    for (let i = min; i <= max; i++) {
      years.push({ value: i, label: i });
    }
    return years;
  }

  function generateArrayOfMonths() {
    let min = 1;
    let max = 12;
    let months = [];

    for (let i = min; i <= max; i++) {
      i.toLocaleString();
      months.push({ value: i, label: i });
    }
    return months;
  }

  return (
    <>
      {paymentMethod.display_name.toLowerCase() === "bogus payment gateway" &&
        paymentMethod.id === 2 &&
        utils.isDev() && (
          <Message.Warning>
            <ul className={"ml-8"}>
              <li> Successful transaction credit card number: 1</li>
              <li> Failed transaction credit card number: 2</li>
              <li> Exception transaction credit card number: 3</li>
            </ul>
          </Message.Warning>
        )}
      {paymentMethod.id === 2 && utils.isDev() && (
        <Message.Success>
          <ul>
            <li className={"list-none"}> Card number: 4242424242424242</li>
            <li className={"list-none"}> CVV: 123</li>
          </ul>
        </Message.Success>
      )}
      <div className={"mt-6"}>
        <div className={"ml-8"}>
          <div className={"flex items-center my-4"}>
            <div className={"label-right"}>Name on card</div>
            <Input
              dataTest={utils.ifDev("credit_card_details.card_name")}
              name={"credit_card_details.card_name"}
              labelInline
              containerClassName={"w-96"}
              register={register({ required: "This field is required" })}
              placeholder="eg. Jane Doe"
              validationError={errors && errors.card_name && errors.card_name}
            />
          </div>
          <div className={"flex items-center my-4"}>
            <div className={"label-right"}>Card number</div>
            <Input
              dataTest={utils.ifDev("credit_card_details.card_number")}
              name={"credit_card_details.card_number"}
              labelInline
              containerClassName={"w-96"}
              register={register({ required: "This field is required" })}
              placeholder={"eg. 1234 5678 9101 1213"}
              type={"number"}
              validationError={errors && errors.card_number && errors.card_number}
            />
          </div>
          <div className={"flex items-center"}>
            <div className={"label-right"}>Expiry date</div>
            <div className={"flex -mt-4 w-96 justify-between"}>
              <Controller
                name={"credit_card_details.card_expiry_year"}
                control={control}
                render={({ onChange, value }) => {
                  return (
                    <div id={utils.ifDev("credit_card_details__card_expiry_year_outer-div")}>
                      <Select
                        dataTest={utils.ifDev("credit_card_details.card_expiry_year")}
                        buttonWidth={"w-44"}
                        options={generateArrayOfYears()}
                        placeholder={"YYYY"}
                        value={value ?? ""}
                        onChange={(val: any) => {
                          onChange(val);
                        }}
                      />
                    </div>
                  );
                }}
              />
              <Controller
                name={"credit_card_details.card_expiry_month"}
                control={control}
                render={({ onChange, value }) => {
                  return (
                    <div id={utils.ifDev("credit_card_details__card_expiry_month_outer-div")}>
                      <Select
                        dataTest={utils.ifDev("credit_card_details.card_expiry_month")}
                        buttonWidth={"w-44"}
                        options={generateArrayOfMonths()}
                        placeholder={"MM"}
                        value={value}
                        onChange={(val: any) => {
                          onChange(val);
                        }}
                      />
                    </div>
                  );
                }}
              />
            </div>
          </div>
          <div className={"flex items-center my-4"}>
            <div className={"label-right"}>Security code (CVV)</div>
            <Controller
              name={"credit_card_details.card_cvv"}
              rules={{
                required: "This field is required",
                pattern: {
                  value: localValidationUtils.threeDecimalDigitRegex,
                  message: "Please provide a valid CVV code"
                }
              }}
              control={control}
              render={({ onChange, value }) => {
                return (
                  <Input
                    dataTest={utils.ifDev("credit_card_details.card_cvv")}
                    value={value ?? ""}
                    maxLength={3}
                    onChange={(e: any) => onChange(e.target.value.replace(/\D/g, ""))}
                    labelInline
                    inputClassName={"w-100"}
                    containerClassName={"w-96"}
                    placeholder={"eg. 000"}
                  />
                );
              }}
            />
          </div>
          {setAsDefault && (
            <Controller
              name={"set_as_default_payment_method"}
              control={control}
              defaultValue={false}
              render={({ onChange, value }) => {
                return (
                  <div className={"flex"}>
                    <div className={"label-right"} />
                    <Checkbox
                      dataTest={utils.ifDev("set_as_default_payment_method")}
                      label={"Set as default payment method"}
                      labelRight
                      onClick={(e: any) => {
                        onChange(e.target.checked ?? !value);
                      }}
                      checked={value}
                    />
                  </div>
                );
              }}
            />
          )}
        </div>
      </div>
    </>
  );
}

export default RenderCreditCardDetails;
