import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as utils from "utils/utils";

// Component with the same look as the default close buttons on the uafrica-ui-framework,
// but allows for custom functionality before closing the modal
// eg. confirming close if there are unsaved changes

function CloseModalButton(props: { id: string; onClick: any }) {
  let { id, onClick } = props;
  return (
    <div className="text-lg leading-6 font-bold text-gray-900 no-print">
      <FontAwesomeIcon
        data-test={utils.ifDev("Close-modal-button__close-modal")}
        icon="times"
        size="sm"
        className="float-right cursor-pointer hover:text-gray-900 text-gray-700"
        id={id}
        onClick={onClick}
      />
    </div>
  );
}

export default CloseModalButton;
