import React, { useEffect } from "react";
import { IUser } from "interfaces/user.interface";
import { Link, useHistory } from "react-router-dom";
import {
  NavItem,
  NavItemDivider,
  navUtils,
  interfaces,
  roleUtils,
  useStore,
  InfoPopover
} from "uafrica-ui-framework";
import AlertNavItem from "components/customNavItems/AlertNavItem";
import * as localRoleUtils from "utils/roleUtils";
import * as localNavUtils from "utils/navUtils";
import { IStore } from "interfaces/store.interface";
import { INavItem } from "interfaces/navigationItem.interfaces";
import CloseModalButton from "components/CloseModalButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface IProps {
  isNavbarCollapsed: boolean;
  closeMenu: any;
  activeUser: IUser;
  onMounted: Function;
  unreadAlertsCount?: number;
}

function AccountsNavigation(props: IProps) {
  const store: IStore = useStore();
  const history = useHistory();

  /* --------------------------------*/
  /* EFFECTS */
  /* --------------------------------*/

  useEffect(() => {
    props.onMounted();
  }, []);

  function hideOrdersPopover() {
    localNavUtils.removeDisplayOnboardingPopover(store, "showOrdersOnboarding");
    history.push("/rates-at-checkout");
  }

  function hideRatesAtCheckoutPopover() {
    localNavUtils.removeDisplayOnboardingPopover(store, "showRatesAtCheckoutOnboarding");
    history.push("/dashboard");
  }

  /* --------------------------------*/
  /* RENDER METHODS */
  /* --------------------------------*/

  const renderNavSectionItems = (section: any) => {
    return section.map((item: INavItem, itemIndex: number) => {
      if (item.displayName === "Alerts") {
        return (
          <AlertNavItem
            unreadAlertsCount={props.unreadAlertsCount}
            item={item}
            key={itemIndex}
            isNavbarCollapsed={props.isNavbarCollapsed}
          />
        );
      }
      return (
        <React.Fragment key={itemIndex}>
          <InfoPopover
            placement={"right"}
            popoverContent={item.popoverContent ?? null}
            showPopover={item.showInfoPopover ?? false}
            onPopoverDismiss={() => (item.onHideInfoPopover ? item.onHideInfoPopover() : {})}
          >
            {item.hasAccess && item.displayCondition && (
              <Link to={item.path} onClick={() => props.closeMenu()}>
                <NavItem item={item} isNavbarCollapsed={props.isNavbarCollapsed} />
              </Link>
            )}
          </InfoPopover>
        </React.Fragment>
      );
    });
  };

  const renderNavSections = (activeUser: IUser) => {
    let navSections: INavItem[][] = [
      [
        {
          displayName: "Orders",
          icon: "box",
          path: "/orders",
          hasAccess: localRoleUtils.userHasUiPermission(activeUser, "orders"),
          displayCondition: Boolean(activeUser.account_id),
          showInfoPopover: localNavUtils.shouldDisplayOnboardingInfoPopover(
            store,
            "showOrdersOnboarding"
          ),
          onHideInfoPopover: () => hideOrdersPopover(),
          popoverContent: (
            <div>
              <div className="grid grid-cols-3">
                <div></div>
                <div className="rounded-full u-center h-10 w-10 bg-primary-100 justify-self-center mb-4 mt-1 ">
                  <FontAwesomeIcon icon="box" className="text-primary " size="1x" />
                </div>
                <div>
                  <CloseModalButton id="close_popover" onClick={() => hideOrdersPopover()} />
                </div>
              </div>
              <div className={"text-center text-sm"}>
                <span className={"font-bold"}>Create orders </span> or{" "}
                <span className={"font-bold"}>import orders </span> from your sales channels.
              </div>
            </div>
          )
        },
        {
          displayName: "Shipments",
          icon: "truck",
          path: "/shipments",
          hasAccess: roleUtils.hasPermission(activeUser, "API_/shipments:GET"),
          displayCondition: Boolean(activeUser.account_id)
        },
        {
          displayName: "Address book",
          icon: "address-book",
          path: "/address-book",
          hasAccess: roleUtils.hasAllPermissions(activeUser, [
            "API_/accounts/address-books:DELETE",
            "API_/accounts/address-books:GET",
            "API_/accounts/address-books:POST"
          ]),
          displayCondition: Boolean(activeUser.account_id)
        }
      ],
      [
        {
          displayName: "Sales channels",
          icon: "network-wired",
          path: "/sales-channels",
          hasAccess: true,
          displayCondition: Boolean(activeUser.account_id)
        },
        {
          displayName: "Rates at checkout",
          icon: "store",
          path: "/rates-at-checkout",
          hasAccess: true,
          displayCondition: Boolean(
            localRoleUtils.userHasUiPermission(activeUser, "rates-at-checkout")
          ),
          showInfoPopover: localNavUtils.shouldDisplayOnboardingInfoPopover(
            store,
            "showRatesAtCheckoutOnboarding"
          ),
          onHideInfoPopover: () => hideRatesAtCheckoutPopover(),
          popoverContent: (
            <div>
              <div className="grid grid-cols-3">
                <div></div>
                <div className="rounded-full u-center h-10 w-10 bg-pink-100 justify-self-center mb-4 mt-1 ">
                  <FontAwesomeIcon icon="store" className="text-pink " size="1x" />
                </div>
                <div>
                  <CloseModalButton
                    id="close_popover"
                    onClick={() => hideRatesAtCheckoutPopover()}
                  />
                </div>
              </div>
              <div className={"text-center text-sm"}>
                Set the <span className={"font-bold"}>shipping rates </span> that are presented to
                your customers during the <span className={"font-bold"}>checkout process </span> on
                your online store.
              </div>
            </div>
          )
        },
        {
          displayName: "Reports",
          icon: "file-invoice",
          path: "/reports",
          hasAccess: roleUtils.hasPermission(activeUser, "API_/reports:GET"),
          displayCondition: Boolean(localRoleUtils.userHasUiPermission(activeUser, "reports"))
        }
      ],
      [
        {
          displayName: "Account",
          icon: "star",
          path: "/accounts",
          hasAccess: localRoleUtils.isAccountSuperUser(activeUser),
          displayCondition: Boolean(activeUser.account_id)
        },
        {
          displayName: "My profile",
          icon: "user",
          path: "/profile",
          hasAccess: true,
          displayCondition: Boolean(true)
        },
        {
          displayName: "Settings",
          icon: "cog",
          path: "/settings",
          hasAccess: localRoleUtils.isAccountSuperUser(activeUser),
          displayCondition: Boolean(activeUser.account_id)
        }
      ],
      [
        {
          displayName: "Alerts",
          icon: "bell",
          path: "/alerts",
          hasAccess: localRoleUtils.isAccountSuperUser(activeUser),
          displayCondition: Boolean(activeUser.account_id)
        }
      ]
    ];

    return navSections.map((section: any, sectionIndex: number) => {
      let displayBreak =
        navUtils.checkIfUserHasAccessToNavSection(section) &&
        navUtils.checkIfUserHasAccessToNavSection(navSections[sectionIndex + 1]); // if user has access to both current and next section, add <NavItemDivider /> element
      return (
        <React.Fragment key={sectionIndex}>
          {renderNavSectionItems(section)}
          {displayBreak ? <NavItemDivider /> : null}
        </React.Fragment>
      );
    });
  };

  // Removed render function here, as it results in multiple renders
  let { activeUser } = props;
  if (!store) return null;

  if (activeUser) {
    return renderNavSections(activeUser);
  }

  let loginItem: interfaces.INavItem = {
    path: "/login",
    displayName: "Log in",
    icon: "sign-in-alt"
  };

  // not logged in
  return (
    <>
      <Link to={"/login"} onClick={() => props.closeMenu()}>
        <NavItem item={loginItem} isNavbarCollapsed={props.isNavbarCollapsed} />
      </Link>
    </>
  );
}

export default AccountsNavigation;
