import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useHistory } from "react-router-dom";
import * as utils from "utils/utils";
import * as localRoleUtils from "utils/roleUtils";
import {
  Input,
  Button,
  NavItem,
  NavItemDivider,
  navUtils,
  roleUtils,
  interfaces,
  useStore,
  useSignedRequest
} from "uafrica-ui-framework";
import { IStore } from "interfaces/store.interface";
import { IAccount } from "interfaces/account.interface";
import { IOrder } from "interfaces/order.interface";

interface IProps {
  onMounted: any;
  closeMenu: any;
  isNavbarCollapsed: any;
  activeUser: any;
  toggleNavbarCollapsed: any;
}

function AdminNavigation(props: IProps) {
  const store: IStore = useStore();
  const history = useHistory();
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [isSearching, setIsSearching] = useState<boolean>(false);
  const [searchingError, setSearchingError] = useState<any>(null);

  const { makeRequest: searchAccountCode } = useSignedRequest({
    signedRequest: utils.signedRequest,
    method: "GET",
    url: "/accounts",
    fetchOnInit: false,
    onSuccess: (response: { count: number; accounts: IAccount[] }) => {
      if (response.accounts && response.count > 0) {
        history.push("/accounts/" + response.accounts[0].id);
        setIsSearching(false);
        setSearchTerm("");
      }
    }
  });

  const { makeRequest: searchOrderNumber } = useSignedRequest({
    signedRequest: utils.signedRequest,
    method: "GET",
    url: "/orders",
    fetchOnInit: false,
    onSuccess: (response: { count: number; orders: IOrder[] }) => {
      if (response.orders && response.count > 0) {
        history.push("/orders/" + response.orders[0].id);
        setIsSearching(false);
        setSearchTerm("");
      }
    }
  });

  /* --------------------------------*/
  /* EFFECTS */
  /* --------------------------------*/

  useEffect(() => {
    props.onMounted();
  }, []);

  /* --------------------------------*/
  /* FUNCTIONS */
  /* --------------------------------*/

  const onSearchChanged = (val: any) => {
    setSearchTerm(val);
    setSearchingError(null);
  };

  const search = async () => {
    if (!searchTerm) return;

    const upperSearchTerm = searchTerm.toUpperCase();
    let searchResponse;

    setIsSearching(true);
    setSearchingError(null);

    // search for account code

    searchResponse = await searchAccountCode({
      account_code: upperSearchTerm
    });

    if (
      searchResponse.responseData &&
      searchResponse.responseData.accounts &&
      searchResponse.responseData.count > 0
    )
      return;

    // search for shipment if account code not found

    const shipments = await utils.searchForShipments(upperSearchTerm, store, true);
    if (shipments) {
      history.push("/shipments/" + shipments[0].id);
      setIsSearching(false);
      setSearchTerm("");
      return;
    }

    // search for order if account code and shipment not found

    searchResponse = await searchOrderNumber({
      channel_order_number: upperSearchTerm
    });

    if (
      searchResponse.responseData &&
      searchResponse.responseData.orders &&
      searchResponse.responseData.count > 0
    )
      return;

    setIsSearching(false);
    setSearchingError("Reference not found");
  };

  /* --------------------------------*/
  /* RENDER METHODS */
  /* --------------------------------*/

  const renderNavSectionItems = (section: any[]) => {
    return section.map((item: any, itemIndex: number) => {
      return (
        <React.Fragment key={itemIndex}>
          {item.hasAccess && item.displayCondition && (
            <Link to={item.path} onClick={() => props.closeMenu()}>
              <NavItem item={item} isNavbarCollapsed={props.isNavbarCollapsed} />
            </Link>
          )}
        </React.Fragment>
      );
    });
  };

  const renderNavSections = () => {
    let navSections: interfaces.INavItem[][] = [
      [
        {
          displayName: "Orders",
          icon: "box",
          path: "/orders",
          hasAccess: roleUtils.hasPermission(props.activeUser, "API_/orders:GET"),
          displayCondition: localRoleUtils.userHasUiPermission(props.activeUser, "orders")
        },
        {
          displayName: "Shipments",
          icon: "truck",
          path: "/shipments",
          hasAccess: roleUtils.hasPermission(props.activeUser, "API_/shipments:GET"),
          displayCondition: true
        },
        {
          displayName: "Maps",
          icon: "map",
          path: "/maps",
          hasAccess: roleUtils.hasAllPermissions(props.activeUser, [
            "API_/geofence/zones:GET",
            "API_/geofence/zones/tags:GET"
          ]),
          displayCondition: true
        }
      ],
      [
        {
          displayName: "Accounts",
          icon: "star",
          path: "/accounts",
          hasAccess: roleUtils.hasPermission(props.activeUser, "API_/accounts:PATCH"),
          displayCondition: localRoleUtils.userHasUiPermission(props.activeUser, "accounts")
        },
        {
          displayName: "Users",
          icon: "users",
          path: "/users",
          hasAccess: roleUtils.hasPermission(props.activeUser, "API_/users:POST"),
          displayCondition: localRoleUtils.userHasUiPermission(props.activeUser, "users")
        },
        {
          displayName: "My profile",
          icon: "user",
          path: "/profile",
          hasAccess: true,
          displayCondition: true
        }
      ],

      [
        {
          displayName: "Billing",
          icon: "file-invoice-dollar",
          path: "/billing",
          hasAccess: roleUtils.hasPermission(props.activeUser, "API_/billing/transactions:GET"),
          displayCondition: localRoleUtils.userHasUiPermission(props.activeUser, "billing")
        },
        {
          displayName: "Reports",
          icon: "file-invoice",
          path: "/reports",
          // TODO reports endpoint
          hasAccess: roleUtils.hasPermission(props.activeUser, "API_/admin-queries:GET"),
          displayCondition: localRoleUtils.userHasUiPermission(props.activeUser, "reports")
        }
      ],
      [
        {
          displayName: "Providers",
          icon: "truck-loading",
          path: "/providers",
          hasAccess: roleUtils.hasPermission(props.activeUser, "API_/providers:GET"),
          displayCondition: localRoleUtils.userHasUiPermission(props.activeUser, "provider")
        },
        {
          displayName: "Logs",
          icon: "laptop-code",
          path: "/logs",
          hasAccess: roleUtils.hasPermission(props.activeUser, "API_/api-logs:GET"),
          displayCondition: localRoleUtils.userHasUiPermission(props.activeUser, "logs")
        },
        {
          displayName: "Settings",
          icon: "cog",
          path: "/system-config",
          hasAccess: roleUtils.hasPermission(props.activeUser, "API_/config:POST"),
          displayCondition: localRoleUtils.userHasUiPermission(props.activeUser, "admin-config")
        }
      ]
    ];

    return navSections.map((section, sectionIndex) => {
      let displayBreak =
        navUtils.checkIfUserHasAccessToNavSection(section) &&
        navUtils.checkIfUserHasAccessToNavSection(navSections[sectionIndex + 1]); // if user has access to both current and next section, add <NavItemDivider />

      return (
        <React.Fragment key={sectionIndex}>
          {renderNavSectionItems(section)}
          {displayBreak ? <NavItemDivider /> : null}
        </React.Fragment>
      );
    });
  };

  const renderNavSearch = (
    searchTerm: string | any[],
    isSearching: boolean | undefined,
    searchingError: {} | null | undefined
  ) => {
    return (
      <>
        {roleUtils.hasAllPermissions(props.activeUser, localRoleUtils.searchBarPermissions) && (
          <>
            <div className="nav-reference-search">
              {props.isNavbarCollapsed ? (
                <div style={{ height: "45px", paddingBottom: "10px" }}>
                  <FontAwesomeIcon
                    icon="search"
                    onClick={() => props.toggleNavbarCollapsed()}
                    className="ml-2 mt-3 text-gray-500 cursor-pointer"
                  />
                </div>
              ) : (
                <div className="flex flex-row justify-center items-center">
                  <Input
                    onChange={(event: { target: { value: any } }) =>
                      onSearchChanged(event.target.value)
                    }
                    onKeyPress={(event: { key: string }) => {
                      if (event.key === "Enter" || event.key === "NumpadEnter") {
                        search();
                      }
                    }}
                    type="text"
                    disabled={isSearching}
                    // @ts-ignore
                    className="nav-reference-search-input"
                    containerClassName="m-0"
                    appendIcon="search"
                    placeholder="Search by ref."
                    value={searchTerm}
                  />
                  <Button.Icon
                    disabled={isSearching || searchTerm.length === 0}
                    onClick={search}
                    bgColor="primary"
                    id="nav_search_button"
                    className="w-12 ml-2"
                    iconSize="sm"
                    isLoading={isSearching}
                    icon="chevron-right"
                  />
                </div>
              )}

              {searchingError && !props.isNavbarCollapsed && (
                // @ts-ignore
                <div className="text-red px-2 pt-2 text-xs font-semibold">{searchingError}</div>
              )}
              <div className="divide-x-2" style={{ marginBottom: 10, marginTop: 7 }} />
            </div>
          </>
        )}
      </>
    );
  };

  const render = () => {
    if (!store) return null;

    return (
      <>
        {renderNavSearch(searchTerm, isSearching, searchingError)}
        {renderNavSections()}
      </>
    );
  };

  return render();
}

export default AdminNavigation;
