import { Message } from "uafrica-ui-framework";

const ErrorState = () => (
  <Message.Error>
    <div className={"text-lg text-red-700 font-bBulkVaold"}>An error has occurred</div>
    <p>Our development team has been notified and will attempt to fix the issue ASAP.</p>
    <p>Please try refreshing the page to resolve the issue.</p>
  </Message.Error>
);

export default ErrorState;
