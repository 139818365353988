// Copied from google API developer guide
import { generalUtils } from "uafrica-ui-framework";

const googleComponentForm: any = {
  street_number: "short_name",
  route: "long_name",
  sublocality_level_1: "long_name",
  sublocality_level_2: "long_name",
  locality: "long_name",
  administrative_area_level_2: "short_name",
  administrative_area_level_1: "short_name",
  country: "short_name",
  postal_code: "short_name"
};

const invoiceFrequencies = [
  { label: "Monthly (last day of month)", value: "monthly" },
  { label: "Weekly", value: "weekly" },
  { label: "Daily", value: "daily" }
];

const webhookTopics: { label: string; value: string }[] = [
  {
    label: "Fulfillment created",
    value: "fulfillment/created"
  },
  {
    label: "Shipment submission status updated",
    value: "shipment_submission_status/updated"
  },
  { label: "Tracking updated", value: "tracking/updated" },
  {
    label: "Shipment charged amount updated",
    value: "shipment_charged_amount/updated"
  },
  {
    label: "Shipment charged weight updated",
    value: "shipment_charged_weight/updated"
  }
];

const emptyAddress = {
  company: "",
  address: "",
  local_area: "",
  city: "",
  code: "",
  zone: "",
  country: "",
  entered_address: "",
  latitude: 0,
  longitude: 0,
  lat: 0,
  lng: 0
};

function emptyAddressObj() {
  return generalUtils.clone(emptyAddress);
}

const settingKeys = {
  shipments_filters: "shipments_filters",
  orders_filters: "orders_filters",
  api_logs: "api_logs"
};

const emailNotificationSettings = [
  {
    title: "When shipment is created",
    collectionKey: "notify_collection_on_submitted_email"
  },
  {
    title: "When shipment is collected",
    collectionKey: "notify_collection_on_collected_email",
    deliveryKey: "notify_delivery_on_collected_email"
  },
  {
    title: "When shipment is at the destination hub",
    collectionKey: "notify_collection_on_at_destination_hub_email",
    deliveryKey: "notify_delivery_on_at_destination_hub_email"
  },
  {
    title: "When shipment is out for delivery",
    collectionKey: "notify_collection_on_out_for_delivery_email",
    deliveryKey: "notify_delivery_on_out_for_delivery_email"
  },
  {
    title: "After failed collection attempt",
    collectionKey: "notify_collection_on_collection_failed_attempt_email"
  },
  {
    title: "After failed delivery attempt",
    collectionKey: "notify_collection_on_delivery_failed_attempt_email",
    deliveryKey: "notify_delivery_on_delivery_failed_attempt_email"
  },
  {
    title: "When shipment is delivered",
    collectionKey: "notify_collection_on_delivered_email",
    deliveryKey: "notify_delivery_on_delivered_email"
  },
  {
    title: "On collection exception",
    collectionKey: "notify_collection_on_collection_exception_email"
  },
  {
    title: "On delivery exception",
    collectionKey: "notify_collection_on_delivery_exception_email",
    deliveryKey: "notify_delivery_on_delivery_exception_email"
  },
  {
    title: "When shipment was cancelled",
    collectionKey: "notify_collection_on_cancelled_email",
    deliveryKey: "notify_delivery_on_cancelled_email"
  }
];

const channelsCanInstall = ["woocommerce", "bidorbuy", "magento", "shopify"];

function currency() {
  return "R";
}

function currencyLong() {
  return "ZAR";
}

const tagConfig: any = {
  account: {
    title: "Account tags",
    url: "/tags",
    postBody: { type: "account" },
    getArgs: { type: "account" },
    cacheIdentifier: "account_tags"
  },
  shipment: {
    title: "Shipment tags",
    url: "/tags",
    postBody: { type: "shipment" },
    getArgs: { type: "shipment" },
    cacheIdentifier: "shipment_tags"
  },
  order: {
    title: "Order tags",
    url: "/tags",
    postBody: { type: "order" },
    getArgs: { type: "order" },
    cacheIdentifier: "order_tags"
  },
  rates: {
    title: "Rate tags",
    url: "/geofence/zones/tags",
    postBody: { type: "rates" },
    getArgs: { types: ["rates"] },
    cacheIdentifier: "rate_tags"
  },
  surcharges: {
    title: "Surcharge tags",
    url: "/geofence/zones/tags",
    postBody: { type: "surcharges" },
    getArgs: { types: ["surcharges"] },
    cacheIdentifier: "surcharge_tags"
  }
};

let userStatuses = new Map();
userStatuses.set("UNCONFIRMED", "User login has not been confirmed");
userStatuses.set("CONFIRMED", "User is active");
userStatuses.set("ARCHIVED", "User is no longer active");
userStatuses.set("COMPROMISED", "User is disabled due to a potential security threat");
userStatuses.set("UNKNOWN", "User status is not known");
userStatuses.set("RESET_REQUIRED", "User must set their password before they can sign in");
userStatuses.set("FORCE_CHANGE_PASSWORD", "User requires a new invitation");
userStatuses.set("open", "User is active");

const defaultTablePageSize = 20;
const defaultTablePageSizeSmall = 10;
const defaultTablePageSizeLarge = 100;

const billingTransactionTypes = [
  "shipping-charge",
  "shipping-charge-reversal",
  "payment",
  "payment-reversal",
  "refund",
  "refund-reversal",
  "admin-debit",
  "admin-credit",
  "promotional-credit",
  "promotional-debit",
  "balance-adjustment-credit",
  "balance-adjustment-debit",
  "bad-debt-write-off",
  "bad-debt-write-off-reversal",
  "subscription-charge",
  "subscription-charge-reversal"
];

const billingTransactionTypesRequiringWaybill: string | any[] = [];

const accountTypes = [
  {
    label: "Prepaid",
    value: "prepaid"
  },
  {
    label: "Postpaid",
    value: "postpaid"
  }
];

const invoiceFrequency = ["monthly", "weekly", "daily"];

const daysOfWeek = [
  { label: "Monday", value: 1 },
  { label: "Tuesday", value: 2 },
  { label: "Wednesday", value: 3 },
  { label: "Thursday", value: 4 },
  { label: "Friday", value: 5 },
  { label: "Saturday", value: 6 },
  { label: "Sunday", value: 0 }
];

const orderStatuses = ["new", "cancelled", "completed", "processing"];

const orderPaymentStatuses = [
  "unpaid",
  "paid",
  "partially-paid",
  "refunded",
  "partially-refunded",
  "pending",
  "voided",
  "authorized"
];

const invoicePaymentStatuses = ["paid", "partially-paid", "unpaid"];

const orderFulfillmentStatus = ["fulfilled", "partially-fulfilled", "unfulfilled"];

const orderTrackingStatus = [
  "pending-collection",
  "collected",
  "out-for-delivery",
  "in-transit",
  "delivered",
  "cancelled",
  "exception",
  "failed-delivery"
];

const orderOrigin = ["manual", "csv-import"];

const emptyPackageType = {
  name: "Standard sleeve",
  length_cm: 42,
  width_cm: 38,
  height_cm: 5,
  is_default: false
};

const emptyParcelType = {
  description: "standard",
  submitted_length_cm: 10,
  submitted_width_cm: 10,
  submitted_height_cm: 5,
  submitted_weight_kg: 1,
  declared_value: 0
};

const provinces = [
  { label: "Eastern Cape", value: "EC" },
  { label: "Free State", value: "FS" },
  { label: "Gauteng", value: "GP" },
  { label: "KwaZulu-Natal", value: "KZN" },
  { label: "Limpopo", value: "LP" },
  { label: "Mpumalanga", value: "MP" },
  { label: "Northern Cape", value: "NC" },
  { label: "North West", value: "NW" },
  { label: "Western Cape", value: "WC" }
];

const accountStatuses = [
  {
    label: "Closed",
    value: "closed"
  },
  {
    label: "Open",
    value: "open"
  }
];

const s3Buckets = {
  accounts: "accounts",
  billing: "billing",
  images: "images"
};

const s3Folders = {
  addressBook: "address-books",
  accountLogos: "account-logos",
  announcementImages: "announcements-images"
};

const serviceStatuses = ["active", "frozen", "cancelled"];

const inactiveServiceStatuses = serviceStatuses.filter((status: any) => status !== "active");

const waybillSizes = ["A4", "A5", "A6"];

const mailGunApiRegions = ["US", "EU"];

const googleAutocompleteOptions = {
  componentRestrictions: {
    country: []
  },
  fields: ["address_component", "geometry.location", "name", "type"]
};

const announcementTypes = [
  {
    label: "Standard",
    value: "standard"
  },
  {
    label: "Urgent",
    value: "urgent"
  }
];

const announcementPlacements = [
  {
    label: "Header",
    value: "header"
  },
  {
    label: "Dashboard",
    value: "dashboard"
  }
];

const dynamicPreferredTypeOptions = [
  {
    label: "Economy",
    value: "economy"
  },
  {
    label: "Express",
    value: "express"
  }
];

const dynamicRoundRateOptions = [
  {
    label: "No rounding",
    value: 0
  },
  {
    label: "Round up to nearest 1",
    value: 1
  },
  {
    label: "Round up to nearest 5",
    value: 5
  },
  {
    label: "Round up to nearest 10",
    value: 10
  }
];

const absoluteQueryOptions = [
  { label: "Current month", value: "last month" },
  { label: "Last day", value: "last day" },
  { label: "Last 2 days", value: "last 2 days" },
  { label: "Last 5 days", value: "last 5 days" },
  { label: "Last week", value: "last 7 days" },
  { label: "Last 2 weeks", value: "last 14 days" },
  { label: "Last 30 days", value: "last 30 days" },
  { label: "Last 2 months", value: "last 60 days" },
  { label: "Last 3 months", value: "last 90 days" },
  { label: "Last 6 months", value: "last 180 days" },
  { label: "Specify date range", value: "date_range" },
  { label: "Specify month", value: "month" }
];

const statementQueryOptions = [
  { label: "Specify date range", value: "date_range" },
  { label: "Specify month", value: "month" }
];

const reportQueryOptions = [
  { label: "Specify date range", value: "date_range" },
  { label: "Specify month", value: "month" }
];

const countryBoundingBoxes = {
  southAfrica: {
    topLeft: {
      lng: 14.868771475513665,
      lat: -22.7
    },
    bottomRight: {
      lng: 32.942553580239796,
      lat: -34.84571992178711
    }
  }
};

const providerCreditNoteStatuses = [
  "credit-applied",
  "different-account",
  "new",
  "processing",
  "resolved",
  "unmatched"
];

const magentoOrderImportFrequency = [5, 10, 15, 30, 60];

export {
  accountStatuses,
  emptyParcelType,
  accountTypes,
  billingTransactionTypes,
  defaultTablePageSize,
  defaultTablePageSizeSmall,
  defaultTablePageSizeLarge,
  userStatuses,
  tagConfig,
  currency,
  currencyLong,
  googleComponentForm,
  emptyAddressObj,
  settingKeys,
  emailNotificationSettings,
  billingTransactionTypesRequiringWaybill,
  s3Buckets,
  s3Folders,
  orderStatuses,
  orderPaymentStatuses,
  orderFulfillmentStatus,
  orderTrackingStatus,
  orderOrigin,
  provinces,
  channelsCanInstall,
  waybillSizes,
  mailGunApiRegions,
  serviceStatuses,
  inactiveServiceStatuses,
  invoicePaymentStatuses,
  emptyPackageType,
  invoiceFrequency,
  daysOfWeek,
  googleAutocompleteOptions,
  announcementTypes,
  announcementPlacements,
  dynamicPreferredTypeOptions,
  dynamicRoundRateOptions,
  absoluteQueryOptions,
  statementQueryOptions,
  reportQueryOptions,
  invoiceFrequencies,
  countryBoundingBoxes,
  webhookTopics,
  providerCreditNoteStatuses,
  magentoOrderImportFrequency
};
