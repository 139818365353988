import { IStore } from "interfaces/store.interface";
import { useStore } from "uafrica-ui-framework";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function AccountCancelled() {
  const store: IStore = useStore();

  if (!store || !store.account) {
    return null;
  }

  if (store && store.account?.plan_status !== "cancelled") {
    return null;
  }

  return (
    <>
      <div className={"banner-red"}>
        <div>
          <FontAwesomeIcon icon="exclamation-circle" className="mr-4" />
          Your subscription is cancelled. Please contact uAfrica support for assistance at +27 12
          940 1060 or <a href={"mailto:" + "support@uafrica.com"}>support@uafrica.com</a>
        </div>
      </div>
    </>
  );
}

export default AccountCancelled;
